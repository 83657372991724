import { RoadMapData } from './RoadMapData'

export const TypePair = { NONE: 0, RED: 1, BLUE: 2, BOTH: 3 }
export const bigColor = { RED: 0, BLUE: 1 }
export const TypeBead = {
    RED: 0,
    GREEN: 2,
    BLUE: 1,
    TIGER: 5,
    DRAGON: 3,
    DT_TIE: 4
}

export const RoadType = { BEAD: 0, BIG: 1, BIGEYE: 2, SMALL: 3, COCKROACH: 4 }

export const GAMETYPE = { baccarat: 1, dragonTiger: 2 }

/**
 * Flags for RoadMap Display
 */
export const DISPLAY = {
    default_horizontal: 0,
    default_vertical: 1,
    big: 2,
    no_bead_vertical: 3,
    no_bead_horizontal: 4,
    bead_only: 5,
    default_horizontal_v2: 6
}

export interface RoadMapSummaryData {
    gameType?: number
    data?: RoadMapData
}
