<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="17"
        viewBox="0 0 23 17"
    >
        <g id="icon_public_more" transform="translate(1.5 1.5)">
            <path
                id="Path_307"
                data-name="Path 307"
                d="M0,0H20"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-width="3"
            />
            <path
                id="Path_306"
                data-name="Path 306"
                d="M0,0H20"
                transform="translate(0 14)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-width="3"
            />
            <path
                id="Path_305"
                data-name="Path 305"
                d="M0,0H7"
                transform="translate(0 7)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-width="3"
            />
            <path
                id="Path_304"
                data-name="Path 304"
                d="M0,0H7"
                transform="translate(13 7)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-width="3"
            />
        </g>
    </svg>
</template>