<template>
    <svg
        id="Component_37_1"
        data-name="Component 37 – 1"
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
    >
        <g
            id="Ellipse_95"
            data-name="Ellipse 95"
            fill="none"
            stroke="#3a3a3a"
            stroke-width="2"
        >
            <circle cx="12.5" cy="12.5" r="12.5" stroke="none" />
            <circle cx="12.5" cy="12.5" r="11.5" fill="none" />
        </g>
        <g
            id="Ellipse_96"
            data-name="Ellipse 96"
            transform="translate(4.167)"
            fill="none"
            stroke="#3a3a3a"
            stroke-width="2"
        >
            <ellipse cx="7.917" cy="12.5" rx="7.917" ry="12.5" stroke="none" />
            <ellipse cx="7.917" cy="12.5" rx="6.917" ry="11.5" fill="none" />
        </g>
        <line
            id="Line_107"
            data-name="Line 107"
            x2="22"
            transform="translate(1.5 12.917)"
            fill="none"
            stroke="#3a3a3a"
            stroke-linecap="round"
            stroke-width="2"
        />
        <line
            id="Line_108"
            data-name="Line 108"
            y2="22"
            transform="translate(12.083 1.5)"
            fill="none"
            stroke="#3a3a3a"
            stroke-linecap="round"
            stroke-width="2"
        />
        <path
            id="Path_190"
            data-name="Path 190"
            d="M20.143,24.409a11.467,11.467,0,0,1-7.752,2.948,11.777,11.777,0,0,1-8.046-3.218"
            transform="translate(0.109 -20.69)"
            fill="none"
            stroke="#3a3a3a"
            stroke-width="2"
        />
        <path
            id="Path_192"
            data-name="Path 192"
            d="M20.143,27.086a11.467,11.467,0,0,0-7.752-2.948,11.777,11.777,0,0,0-8.046,3.218"
            transform="translate(0.109 -5.69)"
            fill="none"
            stroke="#3a3a3a"
            stroke-width="2"
        />
    </svg>
</template>