
import { fecthUserInfo } from '@/api'
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss } from '@/types/global'
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    LimitOption,
    MemberInfo,
    onMounted,
    ref
} from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    emits: ['close'],
    setup(props, { emit }) {
        const { t } = useI18n()
        const store = useStore()
        const emitter = inject('emitter') as Emitter
        const userInfo = ref<any>()

        onMounted(() => {
            loadUserInfo()
        })
        const loadUserInfo = () => {
            const _params = {
                memberId: memberid.value,
                token: token.value
            }

            store.commit(AppMutation.SHOW_LOADING)
            fecthUserInfo(_params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    if (response.data.status === 200) {
                        userInfo.value = response.data.data
                    } else {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            response.data.description
                        )
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        '当前网络不佳，请重新尝试'
                    )
                })
        }

        const close = () => {
            emit('close')
        }

        const user = computed(() => store.getters['user'])
        const token = computed(() => store.getters['token'])
        const memberid = computed(() => store.getters['memberid'])

        const limitOptionArray = computed(
            (): LimitOption[] => store.getters['limits']
        )

        const limit = computed(() => {
            if (userLimit.value > 0 && limitOptionArray.value.length > 0) {
                const limit = limitOptionArray.value.find(
                    (s) => s.id === userLimit.value
                )
                if (limit) {
                    return `${limit.downLimit}-${limit.upLimit}`
                }
            }

            return '0-0'
        })

        const userLimit = computed((): number => store.getters.userLimit)

        const todayValidAmount = computed(() => {
            if (userInfo.value) return userInfo.value.todayValidAmount
            return 0
        })
        const todayWinAmount = computed(() => {
            if (userInfo.value) return userInfo.value.todayWinAmount
            return 0
        })

        const totalValidAmount = computed(() => {
            if (userInfo.value) return userInfo.value.totalValidAmount

            return 0
        })

        const weekBetAmount = computed(() => {
            if (userInfo.value) return userInfo.value.weekBetAmount
            return 0
        })
        const weekValidAmount = computed(() => {
            if (userInfo.value) return userInfo.value.weekValidAmount
            return 0
        })
        const weekCommissionAmount = computed(() => {
            if (userInfo.value) return userInfo.value.weekCommissionAmount
            return 0
        })

        const todayBetAmount = computed(() => {
            if (userInfo.value) return userInfo.value.todayBetAmount

            return 0
        })

        const lastWeekBetAmount = computed(() => {
            if (userInfo.value) return userInfo.value.lastWeekBetAmount
            return 0
        })

        const lastWeekValidAmount = computed(() => {
            if (userInfo.value) return userInfo.value.lastWeekValidAmount
            return 0
        })

        const lastWeekCommissionAmount = computed(() => {
            if (userInfo.value) return userInfo.value.lastWeekCommissionAmount
            return 0
        })

        return {
            lastWeekBetAmount,
            lastWeekValidAmount,
            lastWeekCommissionAmount,
            todayBetAmount,
            weekCommissionAmount,
            weekValidAmount,
            weekBetAmount,
            todayValidAmount,
            todayWinAmount,
            totalValidAmount,
            limit,
            user,
            close,
            t
        }
    }
})
