import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "icon_public_all-road",
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  viewBox: "0 0 20 20"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      id: "Path_38",
      "data-name": "Path 38",
      d: "M30,27H19.994V21H32v4A2,2,0,0,1,30,27ZM19.994,14H32v5H19.994Zm0-7H30a2,2,0,0,1,2,2v3H19.994ZM12,25V21h6v6H14A2,2,0,0,1,12,25Zm0-11h6v5H12Zm0-5a2,2,0,0,1,2-2h4v5H12Z",
      transform: "translate(-12 -7)",
      fill: _ctx.fill,
      "fill-rule": "evenodd"
    }, null, 8, _hoisted_2)
  ]))
}