import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-beff13ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-loby-item-root" }
const _hoisted_2 = { class: "m-loby-item-left" }
const _hoisted_3 = { class: "m-loby-item-left-title" }
const _hoisted_4 = { class: "m-loby-item-left-avatar" }
const _hoisted_5 = ["src", "onerror"]
const _hoisted_6 = { class: "m-loby-item-content" }
const _hoisted_7 = { class: "m-loby-item-content-all-road-display" }
const _hoisted_8 = { class: "m-loby-item-content-all-road-display-top" }
const _hoisted_9 = { class: "m-road-map-sumary" }
const _hoisted_10 = ["data-label"]
const _hoisted_11 = ["data-label"]
const _hoisted_12 = ["data-label"]
const _hoisted_13 = ["data-label"]
const _hoisted_14 = ["data-label"]
const _hoisted_15 = { class: "m-roadmap-all" }
const _hoisted_16 = {
  key: 0,
  class: "m-new-shoe"
}
const _hoisted_17 = { class: "m-shuffle" }
const _hoisted_18 = {
  key: 1,
  class: "m-loby-item-content-all-road-display-statistics"
}
const _hoisted_19 = { class: "m-round" }
const _hoisted_20 = { class: "m-red-pair" }
const _hoisted_21 = { class: "m-blue-pair" }
const _hoisted_22 = { class: "red" }
const _hoisted_23 = { class: "m-label" }
const _hoisted_24 = ["data-progress"]
const _hoisted_25 = { class: "blue" }
const _hoisted_26 = { class: "m-label" }
const _hoisted_27 = ["data-progress"]
const _hoisted_28 = { class: "green" }
const _hoisted_29 = { class: "m-label" }
const _hoisted_30 = ["data-progress"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_road_map = _resolveComponent("road-map")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.tableName), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("img", {
          src: _ctx.mData.expand.avatar,
          onerror: _ctx.onLoadAvatarError
        }, null, 8, _hoisted_5)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", {
              class: "road-map-summary-item",
              "data-color": "red",
              "data-label": _ctx.redLabel
            }, _toDisplayString(_ctx.redCount), 9, _hoisted_10),
            _createElementVNode("div", {
              class: "road-map-summary-item",
              "data-color": "blue",
              "data-label": _ctx.blueLabel
            }, _toDisplayString(_ctx.blueCount), 9, _hoisted_11),
            _createElementVNode("div", {
              class: "road-map-summary-item",
              "data-color": "green",
              "data-label": _ctx.t('roadmap.tie')
            }, _toDisplayString(_ctx.greenCount), 9, _hoisted_12),
            (_ctx.gameType === 1)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "road-map-summary-item",
                  "data-color": "red",
                  "data-label": _ctx.t('roadmap.pair')
                }, _toDisplayString(_ctx.redPairCount), 9, _hoisted_13))
              : _createCommentVNode("", true),
            (_ctx.gameType === 1)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "road-map-summary-item",
                  "data-color": "blue",
                  "data-label": _ctx.t('roadmap.pair')
                }, _toDisplayString(_ctx.bluePairCount), 9, _hoisted_14))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["m-long-single-hop", { blink: typeof _ctx.blinkTimeOut === 'number' }])
          }, _toDisplayString(_ctx.goodWayLabel), 3),
          _createElementVNode("div", {
            class: _normalizeClass(["m-timer", _ctx.timerColor])
          }, _toDisplayString(_ctx.currentCount >= 0 ? _ctx.currentCount : _ctx.tableStatus), 3)
        ]),
        _createElementVNode("div", _hoisted_15, [
          (_openBlock(), _createBlock(_component_road_map, {
            key: _ctx.map1Key,
            class: "m-map1",
            mapData: _ctx.mData.expand.resultList,
            beadRowCount: 8,
            display: _ctx.display === 1 ? 2 : 5,
            gameType: _ctx.gameType,
            "line-width": 1,
            "border-color": 'rgba(0,0,0,0.2)',
            onRoadMapData: _ctx.onRoadMapDataParsed
          }, null, 8, ["mapData", "display", "gameType", "border-color", "onRoadMapData"])),
          (_ctx.display !== 1)
            ? (_openBlock(), _createBlock(_component_road_map, {
                key: _ctx.map2Key,
                class: "m-map2",
                mapData: _ctx.mData.expand.resultList,
                beadRowCount: 8,
                display: 3,
                gameType: _ctx.gameType,
                "line-width": 1,
                "border-color": 'rgba(0,0,0,0.2)'
              }, null, 8, ["mapData", "gameType", "border-color"]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.mData.status === 4)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.t('desklist.shuffling')), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.display === 2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.t('desklist.rounds')) + ":" + _toDisplayString(_ctx.roundCount), 1),
                _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.redLabel) + "." + _toDisplayString(_ctx.t('roadmap.pair')) + ":" + _toDisplayString(_ctx.redPairCount), 1),
                _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.blueLabel) + "." + _toDisplayString(_ctx.t('roadmap.pair')) + " :" + _toDisplayString(_ctx.bluePairCount), 1)
              ]),
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.redLabel) + ":" + _toDisplayString(_ctx.redCount), 1),
                _createElementVNode("div", {
                  class: "m-progress",
                  "data-progress": _ctx.redStat
                }, null, 8, _hoisted_24)
              ]),
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.blueLabel) + ":" + _toDisplayString(_ctx.blueCount), 1),
                _createElementVNode("div", {
                  class: "m-progress",
                  "data-progress": _ctx.blueStat
                }, null, 8, _hoisted_27)
              ]),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.t('roadmap.tie')) + ":" + _toDisplayString(_ctx.greenCount), 1),
                _createElementVNode("div", {
                  class: "m-progress",
                  "data-progress": _ctx.greenStat
                }, null, 8, _hoisted_30)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}