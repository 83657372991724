import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "icon_login_visible",
  xmlns: "http://www.w3.org/2000/svg",
  width: "25",
  height: "13.888",
  viewBox: "0 0 25 13.888"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = {
  key: 1,
  xmlns: "http://www.w3.org/2000/svg",
  width: "25",
  height: "21.141",
  viewBox: "0 0 25 21.141"
}
const _hoisted_5 = {
  id: "icon_login_Invisible",
  transform: "translate(0 2.121)"
}
const _hoisted_6 = ["fill"]
const _hoisted_7 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isOn)
    ? (_openBlock(), _createElementBlock("svg", _hoisted_1, [
        _createElementVNode("path", {
          id: "Subtraction_24",
          "data-name": "Subtraction 24",
          d: "M12.5,13.888c-3.567,0-6.776-1.888-8.839-3.472A23.881,23.881,0,0,1,0,6.943H0l.005-.006A24.193,24.193,0,0,1,3.662,3.471C5.726,1.888,8.935,0,12.5,0s6.774,1.888,8.837,3.471A23.522,23.522,0,0,1,25,6.943h0l-.005.006a24.11,24.11,0,0,1-3.656,3.466C19.275,12,16.066,13.888,12.5,13.888Zm0-11.111a4.166,4.166,0,1,0,4.166,4.166A4.171,4.171,0,0,0,12.5,2.777Z",
          fill: _ctx.onColor
        }, null, 8, _hoisted_2),
        _createElementVNode("circle", {
          id: "Ellipse_9",
          "data-name": "Ellipse 9",
          cx: "1",
          cy: "1",
          r: "1",
          transform: "translate(11.501 5.943)",
          fill: _ctx.onColor
        }, null, 8, _hoisted_3)
      ]))
    : (_openBlock(), _createElementBlock("svg", _hoisted_4, [
        _createElementVNode("g", _hoisted_5, [
          _createElementVNode("path", {
            id: "Subtraction_9",
            "data-name": "Subtraction 9",
            d: "M12.5,14.279a12.64,12.64,0,0,1-6.122-1.745L8.9,10.006a4.163,4.163,0,0,0,3.6,2.13,4.231,4.231,0,0,0,4.167-4.283A4.287,4.287,0,0,0,14.683,4.2l2.954-2.963A23.318,23.318,0,0,1,25,7.14l0,.007a24.505,24.505,0,0,1-3.657,3.563,19.029,19.029,0,0,1-3.973,2.454A11.92,11.92,0,0,1,12.5,14.279ZM4.393,11.263h0A24.331,24.331,0,0,1,0,7.14l0-.007A24.505,24.505,0,0,1,3.661,3.57,19.029,19.029,0,0,1,7.634,1.116,11.92,11.92,0,0,1,12.5,0a10.922,10.922,0,0,1,2.756.366L12.037,3.6A4.211,4.211,0,0,0,8.371,7.273l-3.977,3.99Z",
            transform: "translate(0 1.517)",
            fill: _ctx.offColor
          }, null, 8, _hoisted_6),
          _createElementVNode("line", {
            id: "Line_8",
            "data-name": "Line 8",
            x1: "17",
            y2: "16.898",
            transform: "translate(4.5)",
            fill: "none",
            stroke: _ctx.offColor,
            "stroke-linecap": "round",
            "stroke-width": "3"
          }, null, 8, _hoisted_7)
        ])
      ]))
}