import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "icon_public_money",
  xmlns: "http://www.w3.org/2000/svg",
  width: "12",
  height: "12.001",
  viewBox: "0 0 12 12.001"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      id: "Path_600",
      "data-name": "Path 600",
      d: "M1580-1516.387a6.007,6.007,0,0,1-6-6,6.007,6.007,0,0,1,6-6,6.007,6.007,0,0,1,6,6A6.007,6.007,0,0,1,1580-1516.387Zm-2.819-4.3.6,3.093h.943l.579-3.092h1.354l.58,3.093h.949l.58-3.093h1.576l-.005-.821h-1.412l.146-.8h1.272v-.826h-1.113l.539-2.866h-.826l-.509,2.866h-1.506l-.522-2.866h-.773l-.551,2.866h-1.576l-.527-2.866h-.838l.556,2.866h-1.048v.826h1.212l.153.8h-1.365v.821Zm1.1,1.98h-.023a5.878,5.878,0,0,0-.094-.843l-.212-1.136h.662l-.217,1.137a7.05,7.05,0,0,0-.117.842Zm3.416-.012h-.012a6.377,6.377,0,0,0-.095-.785l-.217-1.183h.634l-.205,1.16a7.559,7.559,0,0,0-.105.807Zm.451-2.789h-.926l-.147-.8h1.213l-.14.8Zm-1.641,0h-1.043l.147-.8h.75l.146.8Zm-1.734,0h-.967l-.147-.8h1.265l-.152.8Zm1.429-1.623h-.433l.117-.616a6.559,6.559,0,0,0,.1-.843h.018a5.186,5.186,0,0,0,.088.856l.11.6Z",
      transform: "translate(-1574 1528.387)",
      fill: _ctx.color
    }, null, 8, _hoisted_2)
  ]))
}