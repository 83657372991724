
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import { computed, defineComponent, Emitter, inject, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    setup() {
        const store = useStore()
        const { t } = useI18n()
        const emitter = inject('emitter') as Emitter

        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'nui-bonus-rules'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        return {
            t,
            close
        }
    }
})
