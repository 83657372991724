import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-622ae8a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chips-msg-box-wrap" }
const _hoisted_2 = { class: "chips-msg-box-content" }
const _hoisted_3 = { class: "chips-msgbox-title-wrap" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "msgbox-body-wrap" }
const _hoisted_6 = { class: "msgbox-body-content" }
const _hoisted_7 = { class: "msgbox-body-content-chips-list" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["textContent"]
const _hoisted_11 = { class: "msg-box-button-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_d_custom_chip = _resolveComponent("d-custom-chip")!

  return (_openBlock(), _createElementBlock("div", {
    class: "chips-msg-box-root",
    onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('setting.chipsetting')), 1),
          _createElementVNode("div", {
            class: "close-btn btn",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"]))
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chipsArray, (chip) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["chip-select-item", [
                                    `chip-${chip.id}`,
                                    { active: _ctx.isSelected(chip.id) }
                                ]]),
                  key: chip.id,
                  onClick: ($event: any) => (_ctx.onSelect(chip))
                }, [
                  (chip.id < 19)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("span", {
                          textContent: _toDisplayString(_ctx.chipLabel(chip.value))
                        }, null, 8, _hoisted_10)
                      ]))
                    : (_openBlock(), _createBlock(_component_d_custom_chip, {
                        key: 1,
                        onUpdateCustomValue: _ctx.updateCustumValue,
                        onClicked: _ctx.onSelect,
                        chipItem: chip
                      }, null, 8, ["onUpdateCustomValue", "onClicked", "chipItem"]))
                ], 10, _hoisted_8))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", {
              class: "btn-cancel btn",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            }, _toDisplayString(_ctx.t('common.cancel')), 1),
            _createElementVNode("div", {
              class: "btn-confirm btn",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args)))
            }, _toDisplayString(_ctx.t('common.confirm')), 1)
          ])
        ])
      ])
    ])
  ]))
}