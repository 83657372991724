
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import {
    BetRecord,
    BetRecordData,
    computed,
    defineComponent,
    Emitter,
    inject,
    LimitOption,
    onMounted,
    ref,
    ResponseData,
    watch
} from 'vue'
import { IconUserInfo, IconDolar } from '@/components/icons'
import { fecthUserInfo, getBetRecordList, switchLimit } from '@/api'
import router, { ROUTES } from '@/router'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { formatDate } from '@/utils'
import Pagination from '@/components/pagination.vue'

import Dropdown from '@/components/drop-down.vue'

export default defineComponent({
    components: {
        'mj-pgnation': Pagination
    },
    setup() {
        const emitter = inject('emitter') as Emitter
        const store = useStore()
        const router = useRouter()
        const { t } = useI18n()

        const activeTab = ref<number>(1)
        const loadingUserInfo = ref(false)
        const userInfo = ref<any>()
        const dataInfo = ref<BetRecordData>()
        const masks = ref({
            input: 'YYYY-MM-DD'
        })

        const modelConfig = ref({
            start: {
                timeAdjust: '00:00:00'
            },
            end: {
                timeAdjust: '23:59:59'
            }
        })

        const dateValue = ref({ start: new Date(), end: new Date() })
        const gameNo = ref('')

        const limitOption = ref(-1)

        //#region Vue Methods
        onMounted(() => {
            // load user info
            // loadUserInfo()
            // const limit = limitOptionArray.value.find(
            //     (s) => s.id === userLimit.value
            // )
            // if (limit) {
            //     limitOption.value = limitOptionArray.value.indexOf(limit)
            // }

            const _parms = { memberId: memberid.value, token: token.value }
            fetchRecod(_parms)
        })
        //#endregion

        //#region Api call
        const loadUserInfo = () => {
            const _params = {
                memberId: memberid.value,
                token: token.value
            }

            store.commit(AppMutation.SHOW_LOADING)
            fecthUserInfo(_params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    if (response.data.status === 200) {
                        userInfo.value = response.data.data
                    } else {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            response.data.description
                        )
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        '当前网络不佳，请重新尝试'
                    )
                })
        }
        const fetchRecod = (_params: any) => {
            store.commit(AppMutation.SHOW_LOADING)
            getBetRecordList(_params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)

                    const recordData =
                        response.data as ResponseData<BetRecordData>

                    if (recordData && recordData.status === 200) {
                        dataInfo.value = recordData.data
                    } else if (recordData && recordData.status === 301) {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            recordData.description
                        )

                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    } else {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            recordData.description
                        )
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(EventBuss.TOAST_MESSAGE, t('networkerror'))
                })
        }
        //#endregion
        const close = () => {
            emitter.emit(EventBuss.DIALOG, {
                type: MessageBoxActionType.close,
                name: 'mobile-record'
            })
        }

        const valueColor = (_v: number) => {
            if (_v > 0) return 'green'
            else if (_v < 0) return 'red'

            return ''
        }

        const toStringValue = (_v: number) => {
            if (_v > 0) return `+${_v}`

            return _v
        }

        //#region Api Call Helpers

        const doSearch = () => {
            if (dateValue.value) {
                const { start, end } = dateValue.value

                const _params: any = {
                    memberId: memberid.value,
                    token: token.value,
                    timestart: start.getTime(),
                    timeend: end.getTime()
                }

                if (gameNo.value !== '') {
                    _params.gameNo = gameNo.value
                }

                fetchRecod(_params)
            }
        }

        const switchUserLimit = (limit: LimitOption) => {
            const params = {
                memberId: memberid.value,
                limitId: limit.id,
                token: token.value
            }

            store.commit(AppMutation.SHOW_LOADING)

            switchLimit(params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)

                    const responseData = response.data as ResponseData<any>

                    if (responseData.status === 200) {
                        store.commit(AppMutation.SET_LIMIT, limit.id)
                    } else if (
                        responseData.status === 100 ||
                        responseData.status === 301
                    ) {
                        // token is expred
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            responseData.description!
                        )

                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        '当前网络不佳，请重新尝试'
                    )
                })
        }

        const toPage = (_page: number) => {
            if (dateValue.value) {
                const { start, end } = dateValue.value

                const _params = {
                    memberId: memberid.value,
                    token: token.value,
                    timestart: start.getTime(),
                    timeend: end.getTime(),
                    page: _page
                }
                fetchRecod(_params)
            } else {
                const _params = {
                    memberId: memberid.value,
                    token: token.value,
                    page: _page
                }
                fetchRecod(_params)
            }
        }

        //#endregion

        const minDate = () => {
            const days = 45
            const date = new Date()
            const last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000)
            const day = last.getDate()
            const month = last.getMonth() + 1
            const year = last.getFullYear()
            return new Date(`${month}/${day}/${year}`)
        }
        const showChangeAvatar = () => {
            emitter.emit(EventBuss.DIALOG, {
                type: MessageBoxActionType.open,
                name: 'avatar'
            })
            //游戏编号 game id header
            //  请输入游戏编号 hint
        }

        const avatar = computed(() => store.getters['avatar'])
        const user = computed(() => store.getters['user'])
        const coin = computed(() => store.getters['coin'])
        const token = computed(() => store.getters['token'])
        const memberid = computed(() => store.getters['memberid'])
        // const userLimit = computed((): number => store.getters.userLimit)

        const limitOptionArray = computed(
            (): LimitOption[] => store.getters['limits']
        )

        const abrevateDatetime = (s: any, e: any) => {
            return `${s} ${t('common.to')} ${e} `
        }

        const todayValidAmount = computed(() => {
            if (userInfo.value) return userInfo.value.todayValidAmount
            return 0
        })
        const todayWinAmount = computed(() => {
            if (userInfo.value) return userInfo.value.todayWinAmount
            return 0
        })

        const totalValidAmount = computed(() => {
            if (userInfo.value) return userInfo.value.totalValidAmount

            return 0
        })

        const dataInfoList = computed((): BetRecord[] => {
            if (dataInfo.value) return dataInfo.value.betRecordList.items || []
            return []
        })

        const pageNo = computed(() => {
            if (dataInfo.value) return dataInfo.value.betRecordList.pageNo
            return 0
        })

        const pageSize = computed(() => {
            if (dataInfo.value) return dataInfo.value.betRecordList.pageSize
            return 0
        })
        const totalSize = computed(() => {
            if (dataInfo.value) return dataInfo.value.betRecordList.totalSize
            return 0
        })
        const totalPage = computed(() => {
            if (dataInfo.value) return dataInfo.value.betRecordList.totalPage
            return 0
        })

        const lang = computed(() => store.getters['lang'])

        //#region Watchers

        watch(
            () => activeTab.value,
            (v) => {
                if (v === 0) {
                    dataInfo.value = undefined
                    loadUserInfo()
                } else if (v === 1) {
                    const _parms = {
                        memberId: memberid.value,
                        token: token.value
                    }
                    fetchRecod(_parms)
                }
            }
        )

        watch(
            () => limitOption.value,
            (newValue, oldValue) => {
                if (oldValue !== -1)
                    switchUserLimit(limitOptionArray.value[newValue])
            }
        )
        //#endregion
        return {
            lang,
            // pagination variable
            pageNo,
            pageSize,
            totalPage,
            totalSize,
            toPage,

            avatar,
            activeTab,
            user,
            coin,
            modelConfig,

            loadingUserInfo,
            todayValidAmount,
            todayWinAmount,
            totalValidAmount,
            dateValue,
            masks,
            dataInfoList,
            gameNo,
            limitOption,
            limitOptionArray,
            minDate,
            doSearch,
            abrevateDatetime,
            showChangeAvatar,
            close,
            formatDate,
            valueColor,
            toStringValue,
            t
        }
    }
})
