<template>
    <svg
        id="icon_public_return"
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="20"
        viewBox="0 0 25 20"
    >
        <path
            id="Path_214"
            data-name="Path 214"
            d="M88.24,179.6a11.812,11.812,0,0,0-11.052-8.191c-.644-.011-1.252,0-1.829.043v4.665a.224.224,0,0,1,.006.043.207.207,0,0,1-.2.207.2.2,0,0,1-.173-.1v.02l-11.244-8H63.8a.207.207,0,0,1-.2-.207.211.211,0,0,1,.114-.187l11.272-8.021v.02a.2.2,0,0,1,.173-.1.207.207,0,0,1,.2.207.223.223,0,0,1-.006.043v4.526q.774-.038,1.625-.023A12.138,12.138,0,0,1,88.24,179.6Zm-12.881-20v.006h-.011Zm0,16.948-.008-.006h.008Z"
            transform="translate(-63.6 -159.6)"
            fill="#fff"
        />
    </svg>
</template>