
import { useStore } from '@/store'
import { Currencies } from '@/types/global'
import { PropType, computed, defineComponent } from 'vue'

export default defineComponent({
    props: {
        color: {
            type: String as PropType<string>,
            default: () => '#fff'
        }
    },
    setup() {
        const store = useStore()

        const currency = computed((): Currencies => store.getters['currency'])

        return {
            currency
        }
    }
})
