<template>
    <svg
        id="_5"
        data-name="5"
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
    >
        <g id="Group_58" data-name="Group 58">
            <g
                id="Path_64"
                data-name="Path 64"
                transform="translate(-4.281 -6.019)"
                fill="#fff"
                fill-rule="evenodd"
            >
                <path
                    d="M 16.81207847595215 30.51900100708008 L 16.75019836425781 30.51900100708008 C 13.55330848693848 30.51900100708008 10.5477180480957 29.27091026306152 8.287088394165039 27.00463104248047 C 6.026148319244385 24.73805046081543 4.780998229980469 21.72441101074219 4.780998229980469 18.51886177062988 C 4.780998229980469 15.31333065032959 6.026148319244385 12.2997407913208 8.287077903747559 10.03324127197266 C 10.54768848419189 7.767051219940186 13.55328845977783 6.519001007080078 16.75019836425781 6.519001007080078 L 16.81207847595215 6.519001007080078 C 20.00886917114258 6.519001007080078 23.01436805725098 7.767051219940186 25.27494812011719 10.03323078155518 C 27.53585815429688 12.29975128173828 28.78099822998047 15.31334114074707 28.78099822998047 18.51886177062988 C 28.78099822998047 21.72440147399902 27.53585815429688 24.73804092407227 25.27494812011719 27.00463104248047 C 23.01434898376465 29.27091026306152 20.00884819030762 30.51900100708008 16.81207847595215 30.51900100708008 Z"
                    stroke="none"
                />
                <path
                    d="M 16.75019836425781 7.019001007080078 C 13.68707847595215 7.019001007080078 10.80719757080078 8.214881896972656 8.641067504882812 10.38635063171387 C 6.474298477172852 12.5584716796875 5.280998229980469 15.44665145874023 5.280998229980469 18.51886177062988 C 5.280998229980469 21.59109115600586 6.474298477172852 24.47933197021484 8.641078948974609 26.65151977539062 C 10.80721855163574 28.82307052612305 13.68709754943848 30.01900100708008 16.75019836425781 30.01900100708008 L 16.81207847595215 30.01900100708008 C 19.87505722045898 30.01900100708008 22.75483703613281 28.82307052612305 24.92094802856445 26.65151977539062 C 27.08770751953125 24.47932052612305 28.28099822998047 21.59107971191406 28.28099822998047 18.51886177062988 C 28.28099822998047 15.4466609954834 27.08771896362305 12.55848121643066 24.92095947265625 10.38635063171387 C 22.75486755371094 8.214881896972656 19.87507820129395 7.019001007080078 16.81207847595215 7.019001007080078 L 16.75019836425781 7.019001007080078 M 16.75019836425781 6.019001007080078 L 16.81207847595215 6.019001007080078 C 23.69848823547363 6.019001007080078 29.28099822998047 11.61536026000977 29.28099822998047 18.51886177062988 C 29.28099822998047 25.42236137390137 23.69848823547363 31.01900100708008 16.81207847595215 31.01900100708008 L 16.75019836425781 31.01900100708008 C 9.863508224487305 31.01900100708008 4.280998229980469 25.42236137390137 4.280998229980469 18.51886177062988 C 4.280998229980469 11.61536026000977 9.863508224487305 6.019001007080078 16.75019836425781 6.019001007080078 Z"
                    stroke="none"
                    fill="#3a3a3a"
                />
            </g>
            <path
                id="Path_65"
                data-name="Path 65"
                d="M30.035,15.59,30,15.612a1.373,1.373,0,0,1-1.878-.5l-1.925-3.343a1.38,1.38,0,0,1,.5-1.883l.14-.081A12.483,12.483,0,0,1,30.035,15.59ZM19.882,7.677h-3.85a1.372,1.372,0,0,1-1.361-1.234,12.456,12.456,0,0,1,3.178-.411h.069a12.433,12.433,0,0,1,3.32.452A1.372,1.372,0,0,1,19.882,7.677Zm-3.85,21.658h3.85a1.372,1.372,0,0,1,1.361,1.238,12.434,12.434,0,0,1-3.325.453h-.069a12.454,12.454,0,0,1-3.183-.413A1.372,1.372,0,0,1,16.032,29.334Zm10.161-3.812,1.925-3.343a1.373,1.373,0,0,1,1.869-.509A12.49,12.49,0,0,1,26.694,27.4,1.38,1.38,0,0,1,26.193,25.522ZM5.917,21.675a1.373,1.373,0,0,1,1.878.5l1.925,3.343a1.38,1.38,0,0,1-.5,1.883l-.088.051A12.491,12.491,0,0,1,5.8,21.742Zm3.8-10.186L7.8,14.831a1.373,1.373,0,0,1-1.878.5l-.1-.061a12.491,12.491,0,0,1,3.347-5.7l.058.034A1.38,1.38,0,0,1,9.721,11.489Z"
                transform="translate(-5.383 -6.028)"
                fill="#3a3a3a"
                fill-rule="evenodd"
            />
            <path
                id="Path_66"
                data-name="Path 66"
                d="M23.365,15.075h.046a10,10,0,0,1,0,19.991h-.046a10,10,0,0,1,0-19.991Z"
                transform="translate(-10.888 -12.578)"
                fill="#3a3a3a"
                fill-rule="evenodd"
            />
            <path
                id="Path_67"
                data-name="Path 67"
                d="M25.971,18.7h.046a9,9,0,0,1,0,18h-.046a9,9,0,0,1,0-18Z"
                transform="translate(-13.498 -15.206)"
                fill="none"
                fill-rule="evenodd"
            />
        </g>
        <path
            id="Intersection_1"
            data-name="Intersection 1"
            d="M0,9.849,2.41,7.434,1.57,4.2,5.753,0,8.016.588,4.487,4.126l.882,3.4,3.392.884,3.529-3.537.587,2.267L8.693,11.336l-3.051-.8L3.365,12.823A7.873,7.873,0,0,1,0,9.849Z"
            transform="translate(5.965 6.586)"
            fill="#fff"
        />
    </svg>
</template>