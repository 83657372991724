
import { PropType, defineComponent } from 'vue'

export default defineComponent({
    props: {
        color: {
            type: String as PropType<string>,
            default: () => '#fff'
        }
    }
})
