<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="21.233"
        viewBox="0 0 25 21.233"
    >
        <path
            id="icon"
            d="M62.624,169.493v16.461a.559.559,0,0,1-1.034.335c-1.725-2.881-3.429-4.393-9.413-4.713L54.29,188.9a.961.961,0,0,1-1.1,1.215,1.632,1.632,0,0,1-1.625-1.215l-2.233-7.4a3.8,3.8,0,0,1,.163-7.564c8.206,0,10.136-1.506,12.095-4.778A.559.559,0,0,1,62.624,169.493Zm4.86,9.028a7.394,7.394,0,0,1-1.24,4.122,1.055,1.055,0,0,1-.794.438,1.01,1.01,0,0,1-.96-1.035,1.212,1.212,0,0,1,.19-.616,5.279,5.279,0,0,0,0-5.818,1.212,1.212,0,0,1-.19-.616,1.01,1.01,0,0,1,.96-1.035,1.055,1.055,0,0,1,.794.438A7.394,7.394,0,0,1,67.485,178.521Zm-.717-5.971a1.185,1.185,0,0,1-.436-.857,1.007,1.007,0,0,1,.965-1.039,1.165,1.165,0,0,1,.635.25c1.167.935,3.033,3.332,3.033,7.617s-1.867,6.681-3.034,7.617a1.165,1.165,0,0,1-.635.25,1.007,1.007,0,0,1-.965-1.039,1.185,1.185,0,0,1,.436-.857c1.358-1.074,2.267-3.221,2.267-5.971S68.126,173.624,66.768,172.55Z"
            transform="translate(-45.966 -168.885)"
            fill="#3a3a3a"
        />
    </svg>
</template>