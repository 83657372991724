import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66241e80"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "svg-root" }
const _hoisted_2 = {
  key: 0,
  id: "icon_public_money",
  xmlns: "http://www.w3.org/2000/svg",
  width: "12",
  height: "12.001",
  viewBox: "0 0 12 12.001"
}
const _hoisted_3 = ["fill"]
const _hoisted_4 = {
  key: 1,
  id: "icon_public__",
  "data-name": "icon_public_₫",
  xmlns: "http://www.w3.org/2000/svg",
  width: "38",
  height: "38",
  viewBox: "0 0 38 38"
}
const _hoisted_5 = ["fill"]
const _hoisted_6 = {
  key: 2,
  id: "icon_account_big__",
  "data-name": "icon_account_big_￥",
  xmlns: "http://www.w3.org/2000/svg",
  width: "38",
  height: "38",
  viewBox: "0 0 38 38"
}
const _hoisted_7 = ["fill"]
const _hoisted_8 = {
  key: 3,
  id: "icon_account_big__",
  "data-name": "icon_account_big_฿",
  xmlns: "http://www.w3.org/2000/svg",
  width: "38",
  height: "38",
  viewBox: "0 0 38 38"
}
const _hoisted_9 = ["fill"]
const _hoisted_10 = {
  key: 4,
  id: "icon_public__",
  "data-name": "icon_public_$",
  xmlns: "http://www.w3.org/2000/svg",
  width: "38",
  height: "38",
  viewBox: "0 0 38 38"
}
const _hoisted_11 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.currency === 'KRW')
      ? (_openBlock(), _createElementBlock("svg", _hoisted_2, [
          _createElementVNode("path", {
            id: "Path_600",
            "data-name": "Path 600",
            d: "M1580-1516.387a6.007,6.007,0,0,1-6-6,6.007,6.007,0,0,1,6-6,6.007,6.007,0,0,1,6,6A6.007,6.007,0,0,1,1580-1516.387Zm-2.819-4.3.6,3.093h.943l.579-3.092h1.354l.58,3.093h.949l.58-3.093h1.576l-.005-.821h-1.412l.146-.8h1.272v-.826h-1.113l.539-2.866h-.826l-.509,2.866h-1.506l-.522-2.866h-.773l-.551,2.866h-1.576l-.527-2.866h-.838l.556,2.866h-1.048v.826h1.212l.153.8h-1.365v.821Zm1.1,1.98h-.023a5.878,5.878,0,0,0-.094-.843l-.212-1.136h.662l-.217,1.137a7.05,7.05,0,0,0-.117.842Zm3.416-.012h-.012a6.377,6.377,0,0,0-.095-.785l-.217-1.183h.634l-.205,1.16a7.559,7.559,0,0,0-.105.807Zm.451-2.789h-.926l-.147-.8h1.213l-.14.8Zm-1.641,0h-1.043l.147-.8h.75l.146.8Zm-1.734,0h-.967l-.147-.8h1.265l-.152.8Zm1.429-1.623h-.433l.117-.616a6.559,6.559,0,0,0,.1-.843h.018a5.186,5.186,0,0,0,.088.856l.11.6Z",
            transform: "translate(-1574 1528.387)",
            fill: _ctx.color
          }, null, 8, _hoisted_3)
        ]))
      : (_ctx.currency === 'VND')
        ? (_openBlock(), _createElementBlock("svg", _hoisted_4, [
            _createElementVNode("path", {
              id: "减去_50",
              "data-name": "减去 50",
              d: "M2256.615,710a19,19,0,1,1,13.435-5.565A18.876,18.876,0,0,1,2256.615,710Zm-6.082-6.526v2.112h10.2v-2.112Zm3.894-20.559a6.933,6.933,0,0,0-5.774,2.673,9.733,9.733,0,0,0-1.948,6.237,9.975,9.975,0,0,0,1.98,6.4,7.189,7.189,0,0,0,5.907,2.673,6.135,6.135,0,0,0,5.28-2.508v2.047h3.267v-19.4h2.706v-2.047h-2.706v-2.573h-3.5v2.573h-5.511v2.047h5.511v4.883A6.189,6.189,0,0,0,2254.427,682.916Zm.693,15.147a4.2,4.2,0,0,1-3.8-1.849,7.983,7.983,0,0,1-1.022-4.388,7.276,7.276,0,0,1,1.089-4.323,4.36,4.36,0,0,1,3.828-1.75,3.954,3.954,0,0,1,3.2,1.552,7.133,7.133,0,0,1,1.319,4.454v.3a7.3,7.3,0,0,1-1.089,4.191A4.166,4.166,0,0,1,2255.12,698.063Z",
              transform: "translate(-2237.615 -672)",
              fill: _ctx.color
            }, null, 8, _hoisted_5)
          ]))
        : (_ctx.currency === 'JPY')
          ? (_openBlock(), _createElementBlock("svg", _hoisted_6, [
              _createElementVNode("path", {
                id: "减去_49",
                "data-name": "减去 49",
                d: "M2256.615,710a19,19,0,1,1,13.435-5.565A18.876,18.876,0,0,1,2256.615,710Zm-1.856-11.864V703h3.712v-4.864h5.408v-1.984h-5.408V693.4l.032-.032h5.376v-1.984h-4.256l6.241-11.232h-4.225l-5.024,9.823-5.024-9.823h-4.224l6.24,11.232h-4.224v1.984h5.343l.032.032v2.752h-5.376v1.984Z",
                transform: "translate(-2237.615 -672)",
                fill: _ctx.color
              }, null, 8, _hoisted_7)
            ]))
          : (_ctx.currency === 'THB')
            ? (_openBlock(), _createElementBlock("svg", _hoisted_8, [
                _createElementVNode("path", {
                  id: "减去_49",
                  "data-name": "减去 49",
                  d: "M2256.615,710a19,19,0,1,1,13.435-5.565A18.876,18.876,0,0,1,2256.615,710Zm-.985-8v2.469h1.938V702a7.573,7.573,0,0,0,4.719-1.781,5.881,5.881,0,0,0,2.063-4.656,5.227,5.227,0,0,0-1.359-3.75,5.657,5.657,0,0,0-3.625-1.719v-.062a5.583,5.583,0,0,0,2.843-2,5.475,5.475,0,0,0,1.031-3.313,4.616,4.616,0,0,0-1.7-3.7,6.683,6.683,0,0,0-3.969-1.406v-2.594h-1.938v2.579h-5.078V702Zm0-2.25H2253.1V691.5h2.531v8.248Zm1.938-.031h0v-8.139a5.2,5.2,0,0,1,3.082,1.222,3.626,3.626,0,0,1,1.027,2.73,4.014,4.014,0,0,1-1.07,2.883,4.676,4.676,0,0,1-3.038,1.3Zm-1.938-10.453H2253.1v-7.421h2.531v7.42Zm1.938-.187h0v-7.139a3.753,3.753,0,0,1,2.25,1.085,3.2,3.2,0,0,1,.751,2.211,3.891,3.891,0,0,1-.789,2.5,4,4,0,0,1-2.21,1.343Z",
                  transform: "translate(-2237.615 -672)",
                  fill: _ctx.color
                }, null, 8, _hoisted_9)
              ]))
            : (_openBlock(), _createElementBlock("svg", _hoisted_10, [
                _createElementVNode("path", {
                  id: "减去_49",
                  "data-name": "减去 49",
                  d: "M2256.615,710a19,19,0,1,1,13.435-5.565A18.876,18.876,0,0,1,2256.615,710Zm-8.58-14.523h0c.2,2.693.985,4.714,2.343,6.006a7.864,7.864,0,0,0,5.148,1.947v2.9h2.047v-2.9a10.35,10.35,0,0,0,5.016-1.485,6.337,6.337,0,0,0-.825-10.758c-.952-.39-2.362-.934-4.19-1.617v-7.556a4.051,4.051,0,0,1,2.178.792,4.245,4.245,0,0,1,1.551,3.036h3.5c-.329-2.408-1.172-4.152-2.508-5.181a8.3,8.3,0,0,0-4.719-1.65V676.3h-2.047v2.706a8.357,8.357,0,0,0-4.719,1.517,5.679,5.679,0,0,0-2.475,4.95,5.918,5.918,0,0,0,3.465,5.478l3.729,1.386v8.052a4.237,4.237,0,0,1-2.574-1.089,5.758,5.758,0,0,1-1.353-3.828Zm9.537,4.917h0v-7.325l.594.23c2.3.789,3.465,1.966,3.465,3.5a3.2,3.2,0,0,1-1.419,2.706,6.394,6.394,0,0,1-2.638.891Zm-2.047-11.583h0l-.13-.033c-2.3-.854-3.466-1.964-3.466-3.3a3.046,3.046,0,0,1,1.354-2.771,4.393,4.393,0,0,1,2.244-.726v6.83Z",
                  transform: "translate(-2237.615 -672)",
                  fill: _ctx.color
                }, null, 8, _hoisted_11)
              ]))
  ]))
}