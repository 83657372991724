<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="27"
        height="25"
        viewBox="0 0 27 25"
    >
        <g
            id="Component_42_1"
            data-name="Component 42 – 1"
            transform="translate(1 1)"
        >
            <path
                id="Path_209"
                data-name="Path 209"
                d="M3.853,17.222A9.157,9.157,0,0,1,0,10C0,4.477,5.6,0,12.5,0S25,4.477,25,10,19.4,20,12.5,20a15.371,15.371,0,0,1-3.673-.439C7.824,19.315,5,23,5,23l1-4"
                fill="none"
                stroke="#3a3a3a"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                fill-rule="evenodd"
            />
            <line
                id="Line_111"
                data-name="Line 111"
                x2="16"
                transform="translate(4.5 7.5)"
                fill="none"
                stroke="#3a3a3a"
                stroke-linecap="round"
                stroke-width="2"
            />
            <line
                id="Line_112"
                data-name="Line 112"
                x2="12"
                transform="translate(4.5 12.5)"
                fill="none"
                stroke="#3a3a3a"
                stroke-linecap="round"
                stroke-width="2"
            />
        </g>
    </svg>
</template>