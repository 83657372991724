
import { defineComponent, PropType, ref, watch } from 'vue'

export default defineComponent({
    emits: ['update:value'],
    props: {
        value: {
            type: Boolean as PropType<boolean>
        }
    }
})
