import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "drop-down-root" }
const _hoisted_2 = { class: "drop-down-label" }
const _hoisted_3 = {
  key: 0,
  class: "drop-down-list"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["drop-down-btn", { on: _ctx.showList }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleList && _ctx.toggleList(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1),
      _renderSlot(_ctx.$slots, "icon", { class: "drop-down-icon" })
    ], 2),
    _createVNode(_Transition, { name: "fadein-out" }, {
      default: _withCtx(() => [
        (_ctx.showList)
          ? _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listData, (item, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  onClick: ($event: any) => (_ctx.handleListItemClick(index)),
                  class: _normalizeClass({ active: _ctx.isSelected(index) })
                }, _toDisplayString(_ctx.getLabel(index)), 11, _hoisted_4))
              }), 128))
            ])), [
              [_directive_click_outside, _ctx.handleClickOutside]
            ])
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}