
import { computed, defineComponent, PropType, StyleValue } from 'vue'

export default defineComponent({
    props: {
        progressData: {
            required: true,
            type: Object as PropType<{
                isLoaded: boolean
                loadCount: number
                assetCount: number
            }>
        }
    },
    setup(props) {
        const progressPercentValue = computed(() => {
            if (props.progressData && props.progressData.assetCount > 0) {
                const b =
                    (props.progressData.loadCount /
                        props.progressData.assetCount) *
                    100
                return `${Math.floor(b)}%`
            }
            return '0'
        })
        const progress = computed(() => {
            let n = {
                '--progress-value': progressPercentValue.value
            } as StyleValue

            return n
        })

        return {
            progress,
            progressPercentValue
        }
    }
})
