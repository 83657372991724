import { CookiesToken } from '@/types/CookiesToken'
import {
    getCookieValue,
    getDefaultChips,
    getDeviceOrientation,
    isMobile,
    setCookieValue
} from '@/utils'
import { AppState, ChipItem, ChipOption, State, TableData } from 'vue'
import { GetterTree, Module, MutationTree } from 'vuex'
import { AppMutation } from '../types'
import { Currencies } from '@/types/global'

const state: AppState = {
    // getDeviceOrientation()
    device: { display: isMobile(), orientation: getDeviceOrientation() },
    showLoading: false,
    canPlaySound: parseInt(getCookieValue(CookiesToken.SOUND) || '1'),
    isUserInteracted: -1,
    lang: getCookieValue(CookiesToken.LANG) ?? 'kor',
    isfullScreen: false,
    video: parseInt(getCookieValue(CookiesToken.VIDEO) || '1'),
    currentChip: parseInt(getCookieValue(CookiesToken.CURRENT_CHIP) || '0'),
    customchip: parseInt(getCookieValue(CookiesToken.CUSTOM_CHIP) || '0'),
    chips: getCookieValue(CookiesToken.CHIPS)?.toObject<ChipOption>() || {
        id: 1,
        value: [5, 10, 20, 50, 100, 500, 1000, 10000]
    },
    currentDesk: parseInt(getCookieValue(CookiesToken.DESK) || '-1'),
    chipDefault:
        getCookieValue(CookiesToken.CHIPS_DEFAULT)?.toObject<ChipItem[]>() ||
        getDefaultChips(),
    chipsOptions: getCookieValue(CookiesToken.CHIPS_OPTION)?.toObject<
        ChipItem[]
    >() || [
        { id: 1, value: 5 },
        { id: 2, value: 10 },
        { id: 3, value: 20 },
        { id: 4, value: 50 },
        { id: 5, value: 100 },
        { id: 6, value: 200 },
        { id: 7, value: 500 },
        { id: 8, value: 1000 },
        { id: 9, value: 2000 },
        { id: 10, value: 5000 },
        { id: 11, value: 10000 },
        { id: 12, value: 25000 },
        { id: 13, value: 50000 },
        { id: 14, value: 100000 },
        { id: 15, value: 150000 },
        { id: 16, value: 200000 },
        { id: 17, value: 500000 },
        { id: 18, value: 1000000 },
        { id: 19, value: 0 },
        { id: 20, value: 0 },
        { id: 21, value: 0 },
        { id: 22, value: 0 },
        { id: 23, value: 0 },
        { id: 24, value: 0 }
    ],
    musicVolume: parseInt(getCookieValue(CookiesToken.MUSIC_VOLUME) || '1'),
    playMusicBg: parseInt(getCookieValue(CookiesToken.MUSIC_CAN_PLAY) || '0'),
    soundVolume: parseInt(getCookieValue(CookiesToken.SOUND_VOLUME) || '1'),
    betLayout: parseInt(getCookieValue(CookiesToken.BET_LAYOUT) || '0'),
    goodWay: getCookieValue(CookiesToken.GOOD_WAY)?.toObject<number[]>() || [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
    ],
    playerIsReady: 0,
    multiTableVideo: parseInt(
        getCookieValue(CookiesToken.MULTI_TABLE_VIDEO) || '-1'
    ),
    hasAnchorTables: parseInt(
        getCookieValue(CookiesToken.HAS_ANCHOR_TABLES) || '0'
    ),
    hasClassicTables: parseInt(
        getCookieValue(CookiesToken.HAS_CLASSIC_TABLES) || '0'
    ),
    popNotice: [],
    popNoticeSeen: false,
    currency: getCookieValue(CookiesToken.CURRENCY) || 'USD'
}

const mutations: MutationTree<AppState> = {
    [AppMutation.SET_ORIENTATION](state, payload: 'landscape' | 'portrait') {
        state.device.orientation = payload
    },
    [AppMutation.HIDE_LOADING](state) {
        state.showLoading = false
    },
    [AppMutation.SHOW_LOADING](state) {
        state.showLoading = true
    },
    [AppMutation.SET_SOUND](state, payload: boolean) {
        if (payload) state.canPlaySound = 1
        else state.canPlaySound = 0
        setCookieValue(CookiesToken.SOUND, String(payload))
    },
    [AppMutation.SET_USER_INTERACTION](state) {
        state.isUserInteracted = 1
    },
    [AppMutation.SET_LANG](state, payload: string) {
        if (state.lang !== payload) {
            state.lang = payload
            setCookieValue(CookiesToken.LANG, payload)

            // if (state.lang !== 'kor') {
            //     state.chipDefault = [
            //         { id: 1, value: 5 },
            //         { id: 2, value: 10 },
            //         { id: 3, value: 20 },
            //         { id: 4, value: 50 },
            //         { id: 5, value: 100 },
            //         { id: 6, value: 500 },
            //         { id: 7, value: 1000 },
            //         { id: 8, value: 10000 }
            //     ]
            // } else {
            //     state.chipDefault = [
            //         { id: 11, value: 10000 },
            //         { id: 12, value: 25000 },
            //         { id: 13, value: 50000 },
            //         { id: 14, value: 100000 },
            //         { id: 17, value: 500000 },
            //         { id: 18, value: 1000000 }
            //     ]
            // }
        }
    },
    [AppMutation.SET_FULL_SCREEN](state, payload: boolean) {
        state.isfullScreen = payload
    },
    [AppMutation.SET_VIDEO](state, payload: boolean) {
        if (payload) state.video = 1
        else state.video = 0

        setCookieValue(CookiesToken.VIDEO, String(state.video))
    },
    [AppMutation.SET_CHIPS](state, payload) {
        state.chips = payload
        setCookieValue(CookiesToken.CHIPS, JSON.stringify(payload))
    },
    [AppMutation.SET_CURRENT_CHIPS](state, payload: number) {
        state.currentChip = payload
        setCookieValue(CookiesToken.CURRENT_CHIP, String(payload))
    },
    [AppMutation.SET_CUSTOM_CHIP](state, payload: ChipItem[]) {
        const defaultChips = payload.sort((a, b) => a.value - b.value)
        state.chipDefault = defaultChips
        setCookieValue(CookiesToken.CHIPS_DEFAULT, JSON.stringify(defaultChips))
    },
    [AppMutation.SET_DESK](state, payload: number) {
        state.currentDesk = payload
        setCookieValue(CookiesToken.DESK, String(payload))
    },
    [AppMutation.SET_CHIPS_OPTIONS](state, payload: ChipItem[]) {
        state.chipsOptions = payload
        setCookieValue(CookiesToken.CHIPS_OPTION, JSON.stringify(payload))
    },
    [AppMutation.SET_SOUND_VOLUME](state, payload: number) {
        state.soundVolume = payload
        setCookieValue(CookiesToken.SOUND_VOLUME, String(payload))
    },
    [AppMutation.SET_MUSIC_VOLUME](state, payload: number) {
        state.musicVolume = payload
        setCookieValue(CookiesToken.MUSIC_VOLUME, String(payload))
    },
    [AppMutation.SET_MUSIC_PLAY](state, payload: number) {
        state.playMusicBg = payload
        setCookieValue(CookiesToken.MUSIC_CAN_PLAY, String(payload))
    },
    [AppMutation.SET_BET_LAYOUT](state, payload: number) {
        state.betLayout = payload
        setCookieValue(CookiesToken.BET_LAYOUT, String(payload))
    },
    [AppMutation.SET_GOOD_WAY](state, payload: number[]) {
        state.goodWay = payload
        setCookieValue(CookiesToken.GOOD_WAY, JSON.stringify(payload))
    },
    [AppMutation.SET_PLAYER_IS_READY](state) {
        state.playerIsReady = 1
    },
    [AppMutation.SET_MULTI_TABLE_VIDEO](state, payload: number) {
        state.multiTableVideo = payload
        setCookieValue(CookiesToken.MULTI_TABLE_VIDEO, String(payload))
    },
    [AppMutation.SET_HAS_ANCHOR_TABLES](state, payload: boolean) {
        const value = payload ? 1 : 0
        state.hasAnchorTables = value
        setCookieValue(CookiesToken.HAS_ANCHOR_TABLES, String(value))
    },
    [AppMutation.SET_HAS_CLASSIC_TABLES](state, payload: boolean) {
        const value = payload ? 1 : 0
        state.hasClassicTables = value
        setCookieValue(CookiesToken.HAS_CLASSIC_TABLES, String(value))
    },
    [AppMutation.SET_POP_NOTICE](state, payload: string[]) {
        state.popNotice = payload
    },
    [AppMutation.SET_POP_NOTICE_SEEN](state, payload: boolean) {
        state.popNoticeSeen = payload
    },
    [AppMutation.SET_CURRENCY](state, payload: Currencies) {
        if (state.currency !== payload) {
            state.currency = payload
            setCookieValue(CookiesToken.CURRENCY, payload)

            if (payload !== 'KRW') {
                state.chipDefault = [
                    { id: 1, value: 5 },
                    { id: 2, value: 10 },
                    { id: 3, value: 20 },
                    { id: 4, value: 50 },
                    { id: 5, value: 100 },
                    { id: 6, value: 500 },
                    { id: 7, value: 1000 },
                    { id: 8, value: 10000 }
                ]
            } else {
                state.chipDefault = [
                    { id: 11, value: 10000 },
                    { id: 12, value: 25000 },
                    { id: 13, value: 50000 },
                    { id: 14, value: 100000 },
                    { id: 17, value: 500000 },
                    { id: 18, value: 1000000 }
                ]
            }
        }
    }
}

const getters: GetterTree<AppState, State> = {
    isMobile(state) {
        return state.device.display
    },
    deviceOrientation(state) {
        return state.device.orientation
    },
    showLoading(state) {
        return state.showLoading
    },
    canPlaySound(state) {
        return state.canPlaySound === 1
    },
    isUserInteracted(state) {
        return state.isUserInteracted !== -1
    },
    lang(state) {
        return state.lang
    },
    isfullScreen(state) {
        return state.isfullScreen
    },
    soundEffect(state) {
        return state.canPlaySound
    },
    videoIsOn(state) {
        return state.video === 1
    },
    chips(state) {
        return state.chips
    },
    customChip(state) {
        return state.customchip
    },
    currentChip(state) {
        return state.currentChip
    },
    currentDesk(state) {
        return state.currentDesk
    },
    chipDefault(state) {
        return state.chipDefault
    },
    chipsOptions(state) {
        return state.chipsOptions
    },
    musicVolume(state) {
        return state.musicVolume
    },
    soundVolume(state) {
        return state.soundVolume
    },
    musicCanPlay(state) {
        return state.playMusicBg
    },
    betLayout(state) {
        return state.betLayout
    },
    goodWay(state) {
        return state.goodWay
    },
    playerIsReady(state) {
        return state.playerIsReady
    },
    multiTableVideo(state) {
        return state.multiTableVideo
    },
    hasAnchorTables(state) {
        return state.hasAnchorTables === 1
    },
    hasClassicTables(state) {
        return state.hasClassicTables === 1
    },
    popNotice: (state) => state.popNotice,
    popNoticeSeen: (state) => state.popNoticeSeen,
    currency: (state) => state.currency
}

export const app: Module<AppState, State> = {
    state,
    mutations,
    getters
}
