export const Lang = ['en', 'kor', 'zh-cn', 'zh-hk']

export enum EventBuss {
    WINDOW_RESIZED = 'WINDOW_RESIZED',
    TOAST_MESSAGE = 'TOAST_MESSAGE',
    SESSION_EXPIRED = 'SESSION_EXPIRED',
    SHOW_GAME_RESULT = 'SHOW_GAME_RESULT',
    DIALOG = 'DIALOG',
    AGREE_TO_AGREEMENT = 'AGREE_TO_AGREEMENT',
    REQUEST_FULL_SCREEN = 'REQUEST_FULL_SCREEN',
    SOCKET_MESSAGE = 'SOCKET_MESSAGE',
    SOCKET_CONNECT = 'SOCKET_CONNECT',
    SOCKET_CLOSE = 'SOCKET_CLOSE',
    HINT = 'HINT',
    SMALL_TOAST = 'SMALL_TOAST',
    PLACE_CHIP = 'PLACE_CHIP',
    TIP_CONCENT_DATA = 'TIP_CONCENT_DATA',
    GAMESTATUS = 'GAMESTATUS',
    PREVIEW_RESULT = 'PREVIEW_RESULT',
    START_CLIENT_TIME_OUT = 'START_CLIENT_TIME_OUT',
    MULTI_CANCEL_GAME = 'MULTI_CANCEL_GAME',
    MULTI_END_GAME = 'MULTI_END_GAME',
    CONFIRM_CANCEL_CONTENT = 'CONFIRM_CANCEL_CONTENT',
    PAGE_ON_VISIBLE = 'PAGE_ON_VISIBLE'
}

export type Currencies = 'KRW' | 'USD' | 'USDT' | 'VND' | 'THB' | 'JPY'
export enum GameCurrencies {
    KRW = 'KRW',
    USD = 'USD',
    USDT = 'USDT',
    VND = 'VND',
    THB = 'THB',
    JPY = 'JPY'
}

export enum NiuBonusType {
    ROYAL_FLUSH = '1',
    STRAIGHT_FLUSH = '2',
    FOUR_KIND = '3',
    FULL_HOUSE = '4',
    FLUSH = '5',
    STRAIGHT = '6',
    THREE_KIND = '7'
}

export const OtherApiParams = {
    jm: 0,
    skey: process.env.API_SKEY
}

/**
 * API ACT params
 */
export const ACT = {
    LOGIN: 1,
    TRIAL: 11,
    LOG_OUT: 17,
    MULTIPLE_DESK_INFO: 2,
    GET_NOTICE_AND_LIMIT: 9,
    SINGLE_DESK_INFO: 3,
    CARD_INFO: 4,
    DO_DT_BET: 7,
    DO_BAC_BET: 6,
    CHANGE_PASS: 12,
    GET_LOG: 13,
    GET_LOG_DETAILS: 15,
    MULTIPLE_BET_DESK_INFO: 18
}

/**
 * GameState Of a Desk
 */
export const GameRoundState = {
    CLOSED: 1,
    END_OF_ROUND: 4
}

/**
 * Game Result
 */
export const RESULT = {
    RED: 1,
    BLUE: 3,
    GREEN: 2
}

export enum SOCKET_MESSAGE {
    NEW_GAME = 'NEWGAME',
    USER_OUT = 'USER-OUT',
    USER_OUT_BY = 'USER-OUT-BY',
    END_GAME = 'ENDGAME',
    STOP_GAME = 'STOPGAME',
    GUNNER_VERIFY = 'GUNNER-VERIFY',
    SERVER_PONG = 'SERVER-PONG',
    CHANGE_LIMIT = 'CHANGE-LIMIT',
    BET = 'BET',
    GUNNER_BET = 'GUNNERBET',
    GUNNER_GIVEFREE = 'GUNNER-GIVEFEE',
    NEW_SHOE = 'NEWSHOE',
    CANCEL_GAME = 'CANCELGAME',
    MEMBER_PLAY = 'MEMBER-PLAY',
    MEMBER_AMOUNT = 'MEMBERAMOUNT',
    WS_EXPIRED = 'TOKEN-EXPIRED',
    CHANGE_AVATAR = 'CHANGEAVATAR',
    READ_POKER = 'READPOKE'
}

export enum BetType {
    BANKER = 0,
    PLAYER = 1,
    BC_TIE = 2,
    DT_TIE = 3,
    DRAGON = 4,
    TIGER = 5,
    PLAYER_PAIR = 6,
    BANKER_PAIR = 7
}

export const ROADMAP_RECOMADATION = {
    types: [
        { id: 1, type: 0, color: 0, map: [[0, 0, 0, 0]], exemptions: [] }, // 4 consecutive red   longRed:
        { id: 2, type: 0, color: 1, map: [[1, 1, 1, 1]], exemptions: [] }, // 4 consecutive blue   longBlue:

        {
            id: 3,
            type: 1,
            color: 0,
            map: [
                [0, 1, 1, 0, 1, 1],
                [0, 1, 1, 0, 1, 1, 0],
                [0, 1, 1, 0, 1, 1, 0, 1]
            ],
            exemptions: [[0, 1, 1, 0, 1, 1]]
        }, // 2 consecutive one red two blue   oneTwoRed:
        {
            id: 4,
            type: 1,
            color: 1,
            map: [
                [1, 0, 0, 1, 0, 0],
                [1, 0, 0, 1, 0, 0, 1],
                [1, 0, 0, 1, 0, 0, 1, 0]
            ],
            exemptions: [[1, 1, 0, 0, 1, 0, 0]]
        }, // 2 consecutive one blue two red oneTwoBlue:

        {
            id: 5,
            type: 3,
            color: 0,
            map: [[0, 1, 0, 1]],
            exemptions: [[0, 0, 1, 0, 1]]
        }, // 2 consecutive one red and one blue longJumpRed:
        {
            id: 6,
            type: 3,
            color: 1,
            map: [[1, 0, 1, 0]],
            exemptions: [[1, 1, 0, 1, 0]]
        }, // 2 consecutive one blue and one red longJumpBlue:

        {
            id: 7,
            type: 4,
            color: 0,
            map: [[0, 0, 0, 0, 1, 0, 1, 0]],
            exemptions: []
        }, // long  longToSingleRed:
        {
            id: 8,
            type: 4,
            color: 1,
            map: [[1, 1, 1, 1, 0, 1, 0, 1]],
            exemptions: []
        }, // long  longToSingleBlue:

        {
            id: 9,
            type: 5,
            color: 0,
            map: [[0, 1, 1, 1, 0, 1, 1, 0, 1, 1]],
            exemptions: [[0, 0, 1, 1, 1, 0, 1, 1, 0, 1, 1]]
        }, // long sigle and twoRed
        {
            id: 10,
            type: 5,
            color: 1,
            map: [[1, 0, 0, 0, 1, 0, 0, 1, 0, 0]],
            exemptions: [[1, 1, 0, 0, 0, 1, 0, 0, 1, 0, 0]]
        }, // long sigle and twoBlue

        {
            id: 11,
            type: 6,
            color: 0,
            map: [[0, 0, 1, 0, 0, 0, 1, 0, 0, 1]],
            exemptions: [[0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 1]]
        }, // long double red
        {
            id: 12,
            type: 6,
            color: 1,
            map: [[1, 1, 0, 1, 1, 1, 0, 1, 1, 0]],
            exemptions: [[1, 1, 1, 0, 1, 1, 1, 0, 1, 1, 0]]
        } // long double blue
    ],
    toLabels(id: number, gameType: number) {
        switch (id) {
            case 1:
                return gameType === 1 ? 'g1' : 'g2'
            case 2:
                return gameType === 1 ? 'g3' : 'g4'
            case 3:
                return gameType === 1 ? 'g5' : 'g6'
            case 4:
                return gameType === 1 ? 'g7' : 'g8'
            case 5:
            case 6:
                return gameType === 1 ? 'g9' : 'g10'
            case 7:
            case 8:
                return gameType === 1 ? 'g11' : 'g12'

            case 9:
                return gameType === 1 ? 'g13' : 'g14'
            case 10:
                return gameType === 1 ? 'g15' : 'g16'
            case 11:
                return gameType === 1 ? 'g17' : 'g18'
            case 12:
                return gameType === 1 ? 'g19' : 'g20'
        }
        return ''
    }
}

/**
 * NUI BET_TYPE
 */
export type NUI_BET_TYPE =
    | 'P1_S_DOUBLE'
    | 'P1_DOUBLE'
    | 'P1_FLAT'
    | 'P2_S_DOUBLE'
    | 'P2_DOUBLE'
    | 'P2_FLAT'
    | 'P3_S_DOUBLE'
    | 'P3_DOUBLE'
    | 'P3_FLAT'

export const LANG_CODE = {
    KOREAN: 'kor',
    CHINESE_SIMPLE: 'zh-CN',
    CHINESE_TRADITIONAL: 'zh-HK',
    ENGLISH: 'en',
    THAI: 'th',
    JAPANESE: 'ja',
    VIETNAMESE: 'vn'
}
