<template>
    <svg
        id="icon_lobby_more-information"
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
    >
        <path
            id="Path_452"
            data-name="Path 452"
            d="M-6588-434.521a14.015,14.015,0,0,1-14-14,14.016,14.016,0,0,1,14-14,13.908,13.908,0,0,1,9.9,4.1,13.908,13.908,0,0,1,4.1,9.9A14.015,14.015,0,0,1-6588-434.521Zm.482-17.638h0l-4.5,1.545v1.343l1.646,1.211v6.249c0,.87-.543,1.311-1.614,1.311v2.05h7.226v-2.05a2.35,2.35,0,0,1-1.177-.2,1.258,1.258,0,0,1-.4-1.109v-10.348Zm-.672-7.091a2.677,2.677,0,0,0-1.914.772,2.676,2.676,0,0,0-.774,1.916,2.56,2.56,0,0,0,.774,1.881,2.677,2.677,0,0,0,1.914.772,2.567,2.567,0,0,0,1.88-.772,2.562,2.562,0,0,0,.772-1.881,2.678,2.678,0,0,0-.772-1.916A2.567,2.567,0,0,0-6588.19-459.25Z"
            transform="translate(6602 462.521)"
            fill="#a8a8a8"
        />
    </svg>
</template>