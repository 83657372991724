enum MessageBoxActionType {
    close = 'close',
    open = 'open'
}

type MessageBoxEvent = {
    type: MessageBoxActionType
    message?: string | unknown
    name: DialogBoxName
}

type DialogBoxName =
    | 'rule'
    | 'setting'
    | 'security'
    | 'chips'
    | 'record'
    | 'exit'
    | 'limit'
    | 'lang'
    | 'media-setting'
    | 'agree'
    | 'message'
    | 'tipbox'
    | 'tipbox-concent'
    | 'confirm-booking'
    | 'good-way-setting'
    | 'avatar'
    | 'switch-play'
    | 'confirm-cancel-message'
    | 'record-and-user-info'
    | 'mobile-record'
    | 'nui-bonus-rules'
    | 'top-up'
    | 'withdraw'
    | 'withdraw-history'
    | 'notice'

export { MessageBoxActionType, MessageBoxEvent, DialogBoxName }
