import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9876d3a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notice-msg-box-root" }
const _hoisted_2 = { class: "notice-msg-box-wrap" }
const _hoisted_3 = { class: "notice-msg-box-title-wrap" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "body-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('common.hint')), 1),
        _createElementVNode("div", {
          class: "close-btn",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.callBackFunc && _ctx.callBackFunc(...args)), ["prevent"]))
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", null, [
          _createElementVNode("ol", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.popNotice, (i, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: `pop-notice-${index}`
              }, _toDisplayString(i), 1))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}