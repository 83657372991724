
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import { computed, defineComponent, Emitter, inject, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    setup() {
        const emitter = inject('emitter') as Emitter
        const store = useStore()
        const { t } = useI18n()

        const avatar = ref(-1)
        const avatars = ref([
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21
        ])

        //#region Vue Helpers
        onMounted(() => {
            avatar.value = currentAvatar.value
        })
        //#endregion

        const setAvatar = (a: number) => {
            if (avatar.value !== a) avatar.value = a
        }

        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'avatar'
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const confirm = () => {
            store.commit(AppMutation.SET_AVATAR, avatar.value)
            close()
        }

        const currentAvatar = computed((): number => store.getters['avatar'])

        return {
            avatar,
            avatars,
            setAvatar,
            confirm,
            close,
            t
        }
    }
})
