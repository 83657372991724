import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2478f6c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "withdraw-msg-box-root" }
const _hoisted_2 = { class: "withdraw-msg-box-wrap" }
const _hoisted_3 = { class: "withdraw-msgbox-title-wrap" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "message-body-wrap" }
const _hoisted_6 = { class: "available-balance-field" }
const _hoisted_7 = { class: "label" }
const _hoisted_8 = { class: "balance-field" }
const _hoisted_9 = { class: "balance-value" }
const _hoisted_10 = { class: "input-field" }
const _hoisted_11 = { class: "label" }
const _hoisted_12 = ["readonly", "placeholder"]
const _hoisted_13 = { class: "f-error" }
const _hoisted_14 = { class: "input-field" }
const _hoisted_15 = { class: "label" }
const _hoisted_16 = ["readonly", "placeholder"]
const _hoisted_17 = { class: "f-error" }
const _hoisted_18 = { class: "input-field" }
const _hoisted_19 = { class: "label" }
const _hoisted_20 = ["readonly", "placeholder"]
const _hoisted_21 = { class: "f-error" }
const _hoisted_22 = { class: "input-field" }
const _hoisted_23 = { class: "label" }
const _hoisted_24 = ["placeholder"]
const _hoisted_25 = { class: "f-error" }
const _hoisted_26 = { class: "input-field" }
const _hoisted_27 = { class: "label" }
const _hoisted_28 = ["readonly", "placeholder"]
const _hoisted_29 = { class: "f-error" }
const _hoisted_30 = { class: "message-body-button-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_dolar = _resolveComponent("icon-dolar")!
  const _component_icon_message = _resolveComponent("icon-message")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('common.withdraw')), 1),
        _createElementVNode("div", {
          class: "close-btn",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"]))
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", null, _toDisplayString(_ctx.t('withdraw.hint')), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t('withdraw.currentbalance')), 1),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_icon_dolar, {
              color: '#a8a8a8',
              class: "svg"
            }),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.formatNumberCurency(_ctx.coin)), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.t('withdraw.membername')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            readonly: _ctx.hasCashoutdetailsInfo,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fMemberName) = $event)),
            placeholder: _ctx.t('withdraw.membernamehint')
          }, null, 8, _hoisted_12), [
            [_vModelText, _ctx.fMemberName]
          ]),
          _createVNode(_Transition, { name: "fadein-out" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.memberNameError), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.t('withdraw.bankname')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            readonly: _ctx.hasCashoutdetailsInfo,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.fBankName) = $event)),
            placeholder: _ctx.t('withdraw.banknamehint')
          }, null, 8, _hoisted_16), [
            [_vModelText, _ctx.fBankName]
          ]),
          _createVNode(_Transition, { name: "fadein-out" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.banknameError), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.t('withdraw.accountnumber')), 1),
          _withDirectives(_createElementVNode("input", {
            readonly: _ctx.hasCashoutdetailsInfo,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.fCardNumber) = $event)),
            type: "text",
            placeholder: _ctx.t('withdraw.accountnumberhint')
          }, null, 8, _hoisted_20), [
            [_vModelText, _ctx.fCardNumber]
          ]),
          _createVNode(_Transition, { name: "fadein-out" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.cardnumberError), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.t('withdraw.amount')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "number",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.fAmount) = $event)),
            placeholder: _ctx.t('withdraw.amounthint')
          }, null, 8, _hoisted_24), [
            [_vModelText, _ctx.fAmount]
          ]),
          _createVNode(_Transition, { name: "fadein-out" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.amountError), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.t('withdraw.phonenumber')), 1),
          _withDirectives(_createElementVNode("input", {
            readonly: _ctx.hasCashoutdetailsInfo,
            type: "text",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.fPhoneNumber) = $event)),
            placeholder: _ctx.t('withdraw.phonenumberhint')
          }, null, 8, _hoisted_28), [
            [_vModelText, _ctx.fPhoneNumber]
          ]),
          _createVNode(_Transition, { name: "fadein-out" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.phonenumberError), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", {
          class: "btn-cs",
          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.contactCs && _ctx.contactCs(...args)))
        }, [
          _createVNode(_component_icon_message, { class: "icon" }),
          _createElementVNode("span", null, _toDisplayString(_ctx.t('setting.customerservice')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_30, [
        _createElementVNode("div", {
          class: "btn-cancel btn",
          onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
        }, _toDisplayString(_ctx.t('common.cancel')), 1),
        _createElementVNode("div", {
          class: "btn-confirm btn",
          onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.callBackFunc && _ctx.callBackFunc(...args)))
        }, _toDisplayString(_ctx.t('common.confirm')), 1)
      ])
    ])
  ]))
}