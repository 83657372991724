<template>
    <svg
        id="icon_public_username"
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
    >
        <path
            id="Path_3"
            data-name="Path 3"
            d="M6,12a6,6,0,1,1,6-6A6,6,0,0,1,6,12ZM6,1.412A2.471,2.471,0,1,0,8.471,3.882,2.47,2.47,0,0,0,6,1.412ZM9.529,8.471A1.764,1.764,0,0,0,7.765,6.706H4.235A1.765,1.765,0,0,0,2.471,8.471V9.938a5.271,5.271,0,0,0,7.058,0Z"
            fill="#a8a8a8"
            fill-rule="evenodd"
        />
    </svg>
</template>