
import {
    computed,
    defineComponent,
    Emitter,
    GameOption,
    HallData,
    inject,
    LimitOption,
    Notice,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    PropType,
    ref,
    ResponseData,
    SocketMessage,
    TableData,
    UserInfo,
    watch
} from 'vue'
import DListItem from '@/components/loby-item.vue'
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss, GameCurrencies, SOCKET_MESSAGE } from '@/types/global'
import { getHallData, getOneCommission, switchLimit } from '@/api'
import { useRoute, useRouter } from 'vue-router'
import { ROUTES } from '@/router'
import { useI18n } from 'vue-i18n'
import { VTextMarquee } from 'vue-text-marquee'
import {
    DialogBoxName,
    MessageBoxActionType,
    MessageBoxEvent
} from '@/types/MessageBox'
import {
    IconNotice,
    IconThumbsUp1,
    IconMore,
    IconStatistics,
    IconAllRoad,
    IconBigRoad,
    // IconDolar,
    IconUserInfo,
    IconReload2,
    SvgExclamation,
    SvgDolar,
    SvgFullScreenToggle,
    SvgSetting,
    SvgRecord,
    SvgArrowDown2,
    iconWithdraw,
    iconWithdrawRecord,
    iconTopUp
} from '@/components/icons'
// import CtrlDropdown from '@/components/drop-down.vue'
import screenfull from 'screenfull'
import BottomNavigation from '@/components/mobile-navigation-item.vue'
import MLobyListItem from '@/components/m-loby-item.vue'
import DNavigation from '@/components/d-navigation.vue'
import Dropdown from '@/components/drop-down.vue'
import DUserInfo from '@/components/d-user-info.vue'
import { parseRoadMap } from '@/components/roadmap/utils'
import PCNuiItem from '@/components/loby/pc-nui-item.vue'
import MBNuiItem from '@/components/loby/mobile-nui-item.vue'
import { formatWithCommas, langToNoticeLanguageValue } from '@/utils'

export default defineComponent({
    props: {
        hall: {
            type: Number as PropType<number>,
            deafault: 1
        }
    },
    components: {
        'd-list-item': DListItem,
        'marquee-text': VTextMarquee,
        'pc-nui-item': PCNuiItem,
        'mb-nui-item': MBNuiItem,
        MLobyListItem,
        // CtrlDropdown,
        // BottomNavigation,
        IconNotice,
        // IconThumbsUp1,
        IconMore,
        iconTopUp,
        iconWithdraw,
        iconWithdrawRecord,
        // IconStatistics,
        // IconAllRoad,
        // IconBigRoad,
        // IconDolar,
        IconUserInfo,
        // IconReload2,
        'svg-exclamation': SvgExclamation,
        'svg-dolar': SvgDolar,
        'svg-full-screen-toggle': SvgFullScreenToggle,
        'svg-setting': SvgSetting,
        'd-navigation': DNavigation,
        'svg-record': SvgRecord,
        'svg-arrow-down-2': SvgArrowDown2,

        'dropdown': Dropdown,
        'd-user-info': DUserInfo
    },
    setup(props) {
        const emitter = inject('emitter') as Emitter
        const router = useRouter()
        // const route = useRoute()
        const store = useStore()
        const { t } = useI18n()
        const tabId = ref<number>(1)
        const desks = ref<Array<TableData>>([])
        const listKey = ref(`loby-list-item-key-${new Date().getTime()}`)
        const noticeKey = ref(`loby-notice-key-${new Date().getTime()}`)
        const lobyNotice = ref<string>('')
        const lobyNoticeList = ref<Notice[]>([])
        const currentDeskNo = ref<number>(-1)

        const limitOption = ref<number>(-1)
        const gameOption = ref<number>(0)
        const listDisplayType = ref<number>(0)

        const displayOption = ref(0)
        const showSwitchRoom = ref(false)
        const showMoreInfo = ref(false)

        const goodwayOption = ref(3)
        const showAllMenu = ref(process.env.SHOW_ALL_MENU === '0')

        let loadDataFailedCounter = 0

        let failureCounter = 0

        //#region  Vue Helpers
        onMounted(() => {
            // loadData()
            // desks.value = Desk.ParseList(fkData)
            // if (props.hall !== 0 && tabId.value === 1) tabId.value = 2
        })

        onBeforeMount(() => {
            // register events
            emitter.on(EventBuss.PAGE_ON_VISIBLE, handleOnPageActive)
            emitter.on(EventBuss.WINDOW_RESIZED, handleResizeWindow)
            emitter.on(EventBuss.SESSION_EXPIRED, handleOnExpiredSession)
            // listen to websocket events
            emitter.on(EventBuss.SOCKET_MESSAGE, handleSocketMessage)
        })

        onBeforeUnmount(() => {
            // unregister events
            emitter.off(EventBuss.PAGE_ON_VISIBLE, handleOnPageActive)
            emitter.off(EventBuss.WINDOW_RESIZED, handleResizeWindow)
            emitter.off(EventBuss.SESSION_EXPIRED, handleOnExpiredSession)
            emitter.off(EventBuss.SOCKET_MESSAGE, handleSocketMessage)
        })
        //#endregion

        const formatNumberCurency = (n: number): string => {
            return formatWithCommas(n)
        }

        const handleOnPageActive = () => {
            loadData(true)
        }

        const setHall = (num: number) => {
            showSwitchRoom.value = false

            if (num === 5) router.push({ name: ROUTES.MULTI })
            else {
                // if (num !== 0 && tabId.value === 1) return
                // else {
                router.push({ name: ROUTES.LOBY, query: { hall: num } })
                // }
            }
        }
        const loadData = (_forceReload = false) => {
            if (!_forceReload) store.commit(AppMutation.SHOW_LOADING)

            const _params: any = {
                token: token.value,
                gameHallType: props.hall
            }

            if (tabId.value === 0) {
                _params.goodWayRecommend = 1
                if (goodwayOption.value === 0) {
                    _params.gameTypeNo = 'G002'
                } else if (goodwayOption.value === 1) {
                    _params.gameTypeNo = 'G003'
                }
            } else {
                // if (tabId.value === 1) _params.gameTypeNo = ''
                if (tabId.value === 2) _params.gameTypeNo = 'G002'
                else if (tabId.value === 3) _params.gameTypeNo = 'G003'
                else if (tabId.value === 5) _params.gameTypeNo = 'G001'
                _params.goodWayRecommend = -1
            }

            getHallData(_params)
                .then((response) => {
                    loadDataFailedCounter = 0
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)
                    const responseData = response.data as ResponseData<HallData>
                    if (responseData.status === 200) {
                        desks.value = responseData.data!.gameTableList

                        if (props.hall === 0 || props.hall === undefined) {
                            const _hasAnchor = desks.value.some(
                                (item) => item.gameHallType === 1
                            )
                            const _hasClassic = desks.value.some(
                                (item) => item.gameHallType === 2
                            )

                            store.commit(
                                AppMutation.SET_HAS_ANCHOR_TABLES,
                                _hasAnchor
                            )
                            store.commit(
                                AppMutation.SET_HAS_CLASSIC_TABLES,
                                _hasClassic
                            )
                        }

                        listKey.value = `loby-list-item-key-${new Date().getTime()}`
                        store.commit(
                            AppMutation.SET_USER_GUNNER_BIND,
                            responseData.data?.bindGunner || false
                        )

                        if (
                            isFreeUser.value &&
                            visitorTable.value.length === 0
                        ) {
                            let _tempDesk = [...desks.value]
                            const _visitorDeskNum: number[] = []

                            for (
                                let _deskIndex = 0;
                                _deskIndex < desks.value.length;
                                _deskIndex++
                            ) {
                                if (_visitorDeskNum.length === 3) {
                                    break
                                } else {
                                    const _desk =
                                        _tempDesk[
                                            Math.floor(
                                                Math.random() * _tempDesk.length
                                            )
                                        ]
                                    if (_desk) {
                                        _tempDesk.splice(
                                            _tempDesk.indexOf(_desk),
                                            1
                                        )
                                        _visitorDeskNum.push(_desk.id)
                                    }
                                }
                            }
                            store.commit(
                                AppMutation.SET_VISITOR_DESK,
                                _visitorDeskNum
                            )
                        }

                        const p = responseData.data?.limitList || []

                        p.forEach((i) => {
                            i.stringValue = `${formatNumberCurency(
                                i.downLimit
                            )}-${formatNumberCurency(i.upLimit)}`
                        })

                        store.commit(AppMutation.SET_LIMITS, p)

                        let _lobyNoticeList = responseData.data!.noticeList

                        lobyNoticeList.value = _lobyNoticeList

                        sortNotice()

                        const gameTypes = responseData.data!.gameTypeList

                        store.commit(AppMutation.SET_GAME_TYPES, gameTypes)

                        // set multipliers
                        const { boomPingBonus, boomFanBonus, boomDuoBonus } =
                            responseData.data!

                        const multiplier = {
                            evenMultiplier: 0,
                            doubleMultiplier: 0,
                            superMultiplier: 0
                        }

                        if (boomDuoBonus)
                            multiplier.superMultiplier = boomDuoBonus
                        if (boomFanBonus)
                            multiplier.doubleMultiplier = boomFanBonus
                        if (boomPingBonus)
                            multiplier.evenMultiplier = boomPingBonus

                        store.commit(AppMutation.SET_MULTIPLIERS, multiplier)
                        store.commit(
                            AppMutation.SET_COMMISSION,
                            responseData.data?.commissionAmount
                        )

                        const memberInfo = responseData.data?.member
                        if (memberInfo) {
                            store.commit(
                                AppMutation.SET_MEMBER_INFO,
                                memberInfo
                            )
                            store.commit(
                                AppMutation.SET_COIN,
                                memberInfo.amount
                            )

                            store.commit(
                                AppMutation.SET_CURRENCY,

                                memberInfo.currencyNo.toUpperCase()
                            )

                            const limit = limitOptionArray.value.find(
                                (x) => x.id === memberInfo.limitId
                            )

                            if (limit) {
                                limitOption.value =
                                    limitOptionArray.value.indexOf(limit)
                            }
                        }
                    } else if (
                        responseData.status === 100 ||
                        responseData.status === 301
                    ) {
                        // token is expred
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            responseData.description!
                        )

                        emitter.emit(EventBuss.SOCKET_CLOSE)

                        store.commit(AppMutation.CLEAR_ALL)

                        router.push({ name: ROUTES.LOGIN })
                    }
                })
                .catch((e) => {
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)
                    loadDataFailedCounter++
                    if (loadDataFailedCounter > 3) {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            t('prompt.networkerror')
                        )
                        emitter.emit(EventBuss.SOCKET_CLOSE)
                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    } else {
                        // reload after 1 second
                        setTimeout(() => {
                            loadData(true)
                        }, 1500)
                    }
                })
        }

        const handleSocketMessage = (_message: SocketMessage | any) => {
            // console.log(_message)
            const { type } = _message
            const _type = type.toUpperCase()
            if (
                _type === SOCKET_MESSAGE.NEW_GAME ||
                _type === SOCKET_MESSAGE.END_GAME ||
                _type === SOCKET_MESSAGE.STOP_GAME ||
                _type === SOCKET_MESSAGE.NEW_SHOE
            ) {
                const _desk = desks.value.find(
                    (_d) => _d.id === _message.gameTable!.id
                )

                if (_desk) {
                    const _index = desks.value.indexOf(_desk)
                    if (_index !== -1) {
                        const _copyTable = {
                            ..._message.gameTable
                        } as TableData
                        _copyTable.expand.avatar = _desk.expand.avatar
                        // console.log(_message.gameTable)
                        // desks.value[_index] = _message.gameTable

                        if (
                            _type === SOCKET_MESSAGE.NEW_GAME ||
                            _type === SOCKET_MESSAGE.STOP_GAME
                        ) {
                            _copyTable.expand.resultList =
                                desks.value[_index].expand.resultList
                        }
                        desks.value[_index] = _copyTable
                    }
                }

                // status=4 means a new show
            } else if (_type === SOCKET_MESSAGE.GUNNER_VERIFY) {
                if (
                    _message.data.status === 5 &&
                    _message.data.verifyStatus === 1
                ) {
                    store.commit(AppMutation.SET_DESK, _message.data.tableId)
                } else if (
                    _message.data.status === 9 &&
                    _message.data.verifyStatus === 1
                ) {
                    store.commit(AppMutation.SET_USER_GUNNER_BIND, false)
                    emitter.emit(EventBuss.TOAST_MESSAGE, _message.data.content)
                } else if (
                    _message.data.status === 10 &&
                    _message.data.verifyStatus === 1
                ) {
                    store.commit(AppMutation.SET_USER_GUNNER_BIND, true)
                    emitter.emit(EventBuss.TOAST_MESSAGE, _message.data.content)
                }
            } else if (_type === SOCKET_MESSAGE.CHANGE_AVATAR) {
                // console.log(_message)
                const _deskAvt = desks.value.find(
                    (_d) => _d.tableNo === _message.tableNo
                )
                if (_deskAvt) {
                    const _indexAvt = desks.value.indexOf(_deskAvt)

                    if (_indexAvt !== -1) {
                        desks.value[_indexAvt].expand.avatar = _message.avatar
                    }
                }
            }
        }

        const requestFullScreen = () => {
            emitter.emit(EventBuss.REQUEST_FULL_SCREEN)
        }

        const handleOnExpiredSession = (_hasMessage = true) => {
            if (_hasMessage) {
                emitter.emit(EventBuss.TOAST_MESSAGE, t('sessionexpired'))
            }
            store.commit(AppMutation.CLEAR_ALL)
            router.push({ name: ROUTES.LOGIN })
        }

        const handleResizeWindow = () => {
            // listKey.value = `loby-list-item-key-${new Date().getTime()}`
            noticeKey.value = `loby-notice-key-${new Date().getTime()}`
        }
        const changeDeskFilter = (_tabId: number) => {
            if (tabId.value === _tabId) return

            tabId.value = _tabId
        }

        // const handleSelectDesk = (_tableNo: number, desk: TableData) => {
        const handleSelectDesk = (event: Event, _table: TableData) => {
            // if (userInfo.value.memberType !== 1) {
            //     if (
            //         desk.commandType !== 0 &&
            //         commandType.value !== desk.commandType
            //     ) {
            //         let _toastMessage = `${desk.tableNo}`

            //         if (desk.commandType === 1) {
            //             _toastMessage += '号台桌为电投台,请选择其他台桌游戏！'
            //         } else {
            //             _toastMessage += '号台桌为指令台,请选择其他台桌游戏！'
            //         }
            //         emitter.emit(EventBuss.TOAST_MESSAGE, _toastMessage)

            //         return
            //     }

            //     if (userInfo.value.commandType === 1 && !isBindToGunner.value) {
            //         emitter.emit(
            //             EventBuss.TOAST_MESSAGE,
            //             '您未绑定枪手，请先绑定枪手再进入台桌'
            //         )
            //         return
            //     }
            // }

            // router.push({
            //     name: ROUTES.GAME,
            //     query: { desk: _tableNo }
            // })

            if (_table.gameTypeNo !== 'G001') {
                if (_table.gameHallType === 1) {
                    router.push({
                        name: ROUTES.DEFAULT_GAME,
                        query: { desk: _table.id, hall: 0 }
                    })
                } else {
                    router.push({
                        name: ROUTES.CLASSIC_GAME,
                        query: { desk: _table.id, hall: 0 }
                    })
                }
            } else {
                if (_table.gameHallType === 1) {
                    router.push({
                        name: ROUTES.NIU_NIU,
                        query: { desk: _table.id, hall: props.hall }
                    })
                } else {
                    router.push({
                        name: ROUTES.NIU_NIU_CLASSIC,
                        query: { desk: _table.id, hall: props.hall }
                    })
                }
            }
        }

        const showSettingDialog = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'setting'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const toggleDisplayOptionView = () => {
            if (displayOption.value === 2) {
                displayOption.value = 0
            } else {
                displayOption.value++
            }
        }

        const sortNotice = () => {
            let _lobyNotice: string[] = []
            const _popNotice: string[] = []
            lobyNoticeList.value.forEach((_notice, index) => {
                if (
                    index === 0 &&
                    _notice.isPop === 0 &&
                    _notice.langs === langToNoticeLanguageValue(lang.value)
                ) {
                    _lobyNotice.push(_notice.content)
                } else if (
                    index !== 0 &&
                    _notice.isPop === 0 &&
                    _notice.langs === langToNoticeLanguageValue(lang.value)
                ) {
                    _lobyNotice.push(_notice.content)
                }
                if (
                    _notice.isPop === 1 &&
                    langToNoticeLanguageValue(lang.value) === _notice.langs
                ) {
                    _popNotice.push(_notice.content)
                }
            })
            lobyNotice.value = _lobyNotice.join(',')
            if (_popNotice.length > 0) {
                // notice to store
                store.commit(AppMutation.SET_POP_NOTICE, _popNotice)
                if (!popNoticeSeen.value) {
                    // show dialog
                    const _noticeEvent: MessageBoxEvent = {
                        type: MessageBoxActionType.open,
                        name: 'notice'
                    }
                    emitter.emit(EventBuss.DIALOG, _noticeEvent)
                }
            }

            lobyNotice.value = _lobyNotice.join(',')
            noticeKey.value = `loby-notice-key-${new Date().getTime()}`
        }
        //#region Click Helper Methods
        const onChangeListDisplayType = (_display: number) => {
            if (_display === listDisplayType.value) return

            listDisplayType.value = _display
        }

        /**
         * Emit EventBuss That open A dilaog
         *
         */
        const openDialog = (name: DialogBoxName) => {
            const evt: MessageBoxEvent = {
                name,
                type: MessageBoxActionType.open
            }
            emitter.emit(EventBuss.DIALOG, evt)
        }

        const gotoCustumerService = () => {
            window.open(process.env.CUSTUMER_SERVICE, '_blank')
        }

        const onGoodWayChange = (num: number) => {
            goodwayOption.value = num
            loadData()
        }
        //#endregion

        //#region Api Call Helpers

        const switchUserLimit = (limit: LimitOption) => {
            const params = {
                memberId: memberid.value,
                limitId: limit.id,
                token: token.value
            }

            store.commit(AppMutation.SHOW_LOADING)

            switchLimit(params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)

                    const responseData = response.data as ResponseData<any>

                    if (responseData.status === 200) {
                        const limitId =
                            limitOptionArray.value[limitOption.value]
                        store.commit(AppMutation.SET_LIMIT, limitId.id)
                    } else if (
                        responseData.status === 100 ||
                        responseData.status === 301
                    ) {
                        // token is expred
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            responseData.description!
                        )

                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        '当前网络不佳，请重新尝试'
                    )
                })
        }

        const getCommissionInfo = () => {
            const params = {
                memberId: memberid.value,
                token: token.value
            }

            store.commit(AppMutation.SHOW_LOADING)

            getOneCommission(params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    if (response.data.status === 100) {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            response.data.description
                        )
                    } else if (response.data.status === 200) {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            t('hints.commissionconvertionsuccessful')
                        )

                        store.commit(
                            AppMutation.SET_COIN,
                            response.data.data.amount
                        )
                        store.commit(AppMutation.SET_COMMISSION, '0')
                    }
                })
                .catch((e) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        t('prompt.networkerror')
                    )
                })
        }

        //#endregion

        const user = computed(() => store.getters['user'])
        const token = computed(() => store.getters['token'])
        const coin = computed(() => store.getters['coin'])
        const notice = computed((): string => store.getters['lobyNotice'])
        const commandType = computed(() => store.getters['commandType'])
        const userInfo = computed((): UserInfo => store.getters['userInfo'])

        const isfullScreen = computed(() => store.getters['isfullScreen'])

        const fullScreenAvailable = computed(() => screenfull.isEnabled)
        const visitorTable = computed(
            (): number[] => store.getters['visitorTable']
        )
        const isFreeUser = computed(() => store.getters['isFreeUser'])
        const memberid = computed(() => store.getters['memberid'])
        const filteredDesk = computed(() => {
            if (isFreeUser.value) {
                return desks.value.filter(
                    (_desk) => visitorTable.value.indexOf(_desk.id) !== -1
                )
            } else {
                return desks.value
            }
        })

        const isBindToGunner = computed(
            (): boolean => store.getters['isBindToGunner']
        )
        const limitOptionArray = computed(
            (): LimitOption[] => store.getters['limits']
        )
        const displayOptionLabel = computed(() => {
            const displayValue = displayOption.value
            if (displayValue === 0) return t('common.allroad')
            else if (displayValue === 1) return t('common.mainroad')
            else return t('common.statistics')
        })

        const goodWayTables = computed((): TableData[] => {
            if (goodWay.value.length === 0) return []

            const result: TableData[] = []

            desks.value.forEach((_t) => {
                const mapData = parseRoadMap(_t.expand.resultList)

                if (mapData.goodway.length > 0) {
                    const _ids = mapData.goodway.map((_goodWay) => _goodWay.id)
                    for (let index = 0; index < goodWay.value.length; index++) {
                        const i = goodWay.value[index]
                        if (_ids.indexOf(i) !== -1) {
                            result.push(_t)
                            break
                        }
                    }
                }
            })

            return result
        })

        const lang = computed((): string => store.getters['lang'])
        const avatar = computed(() => store.getters['avatar'])
        const goodWay = computed((): number[] => store.getters['goodWay'])
        const hasAnchorTables = computed(
            (): boolean => store.getters['hasAnchorTables']
        )
        const popNoticeSeen = computed(
            (): boolean => store.getters['popNoticeSeen']
        )

        const commission = computed(() => store.getters['commission'])

        const hasClassicTables = computed(
            (): boolean => store.getters['hasClassicTables']
        )

        watch(
            () => props.hall,
            () => {
                loadData(false)
            }
        )

        watch(
            () => tabId.value,
            (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    // do something

                    if (newValue === 4) {
                        // redirect to multi betting
                        router.push({ name: ROUTES.MULTI })
                    } else {
                        setHall(0)
                    }
                }
            }
        )

        watch(
            () => limitOption.value,
            (newValue, oldValue) => {
                if (oldValue !== -1)
                    switchUserLimit(limitOptionArray.value[newValue])
            }
        )
        watch(
            () => tabId.value,
            (c) => {
                desks.value = []
                loadData()
            },
            {
                immediate: true
            }
        )

        watch(
            () => lang.value,
            () => {
                sortNotice()
            }
        )

        return {
            commission,
            hasAnchorTables,
            hasClassicTables,
            isFreeUser,
            coin,
            tabId,
            desks,
            listKey,
            limitOptionArray,
            limitOption,
            gameOption,
            filteredDesk,
            user,
            currentDeskNo,
            notice,
            lobyNotice,
            noticeKey,
            isfullScreen,
            listDisplayType,
            fullScreenAvailable,
            avatar,
            goodWayTables,
            changeDeskFilter,
            handleSelectDesk,
            showSettingDialog,
            requestFullScreen,
            onChangeListDisplayType,
            displayOptionLabel,
            displayOption,
            showSwitchRoom,
            showMoreInfo,
            toggleDisplayOptionView,
            openDialog,
            setHall,
            onGoodWayChange,
            t,
            getCommissionInfo,
            formatNumberCurency,
            gotoCustumerService,
            showAllMenu
        }
    }
})
