
import { rechargeInfo, rechargeOrWithdraw } from '@/api'
import { IconMessage } from '@/components/icons'
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import {
    CashOutInfo,
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    ref
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default defineComponent({
    components: {
        IconMessage
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const message = ref<string>('')
        const emitter = inject('emitter') as Emitter
        const { t } = useI18n()

        const fMemberName = ref('')
        const fBankName = ref('')
        const fAmount = ref('')
        const fCardNumber = ref('')
        const fPhoneNumber = ref('')
        const banknameError = ref('')
        const amountError = ref('')
        const cardnumberError = ref('')
        const phonenumberError = ref('')
        const memberNameError = ref('')

        onBeforeMount(() => {
            if (cashoutdetails.value) {
                const { bankName, cardNumber, memberName, phone } =
                    cashoutdetails.value
                fBankName.value = bankName
                fCardNumber.value = cardNumber
                fMemberName.value = memberName
                fPhoneNumber.value = phone
            }
            getCashOutInfo()
        })

        const getCashOutInfo = () => {
            store.commit(AppMutation.SHOW_LOADING)
            const params = {
                memberId: memberid.value,
                token: token.value
            }

            rechargeInfo(params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    const { bankCard, bankName, memberName, phone } =
                        response.data.data
                    if (bankCard !== '' && bankName !== '') {
                        const info: CashOutInfo = {
                            bankName,
                            cardNumber: bankCard,
                            memberName,
                            phone
                        }

                        fBankName.value = info.bankName
                        fCardNumber.value = info.cardNumber
                        fMemberName.value = info.memberName
                        fPhoneNumber.value = info.phone
                        store.commit(AppMutation.SET_CASH_OUT_INFO, info)
                    }
                })
                .catch(() => {
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        t('prompt.networkerror')
                    )
                    store.commit(AppMutation.HIDE_LOADING)
                })
        }

        const callBackFunc = () => {
            if (fMemberName.value.trim() === '') {
                memberNameError.value = t('topup.depositornameerror')
                return
            }

            memberNameError.value = ''

            if (fBankName.value.trim() === '') {
                banknameError.value = t('topup.banknameerror')
                return
            }

            banknameError.value = ''
            const amt = fAmount.value

            if (fCardNumber.value.trim() === '') {
                cardnumberError.value = t('topup.accountnumbererror')
                return
            }

            cardnumberError.value = ''

            if (
                !(typeof amt === 'number' && !isNaN(amt)) ||
                (typeof amt === 'number' && amt === 0)
            ) {
                amountError.value = t('topup.amounterror')
                return
            }

            amountError.value = ''

            if (fPhoneNumber.value.trim() === '') {
                phonenumberError.value = t('topup.phonenumbererror')
                return
            }

            phonenumberError.value = ''

            if (!cashoutdetails.value) {
                const ev: MessageBoxEvent = {
                    type: MessageBoxActionType.open,
                    name: 'confirm-cancel-message'
                }
                emitter.emit(EventBuss.DIALOG, ev)

                setTimeout(() => {
                    emitter.emit(EventBuss.CONFIRM_CANCEL_CONTENT, {
                        message: t('topup.confirminfohint', [
                            fCardNumber.value
                        ]),
                        callback: callTopUpApi
                    })
                }, 10)
            } else {
                callTopUpApi()
            }
        }

        const callTopUpApi = () => {
            const params = {
                memberId: memberid.value,
                bankType: 1,
                memberName: fMemberName.value.trim(),
                bankName: fBankName.value.trim(),
                bankCard: fCardNumber.value.trim(),
                bankAmount: fAmount.value,
                phone: fPhoneNumber.value.trim(),
                token: token.value
            }

            store.commit(AppMutation.SHOW_LOADING)

            rechargeOrWithdraw(params)
                .then((response) => {
                    const { description } = response.data
                    store.commit(AppMutation.HIDE_LOADING)
                    if (description === 'OK') {
                        close()
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            t('topup.rechargesent')
                        )
                    } else {
                        if (description === '901')
                            emitter.emit(
                                EventBuss.TOAST_MESSAGE,
                                t('common.processing')
                            )
                        else emitter.emit(EventBuss.TOAST_MESSAGE, description)
                    }
                })
                .catch(() => {
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        t('prompt.networkerror')
                    )
                    store.commit(AppMutation.HIDE_LOADING)
                })
        }

        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'top-up'
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const contactCs = () => {
            window.open(process.env.CUSTUMER_SERVICE, '_blank')
        }

        const cashoutdetails = computed(
            (): CashOutInfo | undefined => store.getters['cashoutdetails']
        )
        const token = computed(() => store.getters['token'])
        const memberid = computed(() => store.getters['memberid'])

        const hasCashoutdetailsInfo = computed(
            (): boolean => cashoutdetails.value !== undefined
        )

        return {
            hasCashoutdetailsInfo,
            amountError,
            fBankName,
            memberNameError,
            fMemberName,
            fAmount,
            fCardNumber,
            fPhoneNumber,
            banknameError,
            cardnumberError,
            phonenumberError,
            callBackFunc,
            message,
            cashoutdetails,
            contactCs,
            close,
            t
        }
    }
})
