
import { useCountdown } from '@/composables/useCountDown'
import { Nui, NuiData } from '@/types/Nui'
import {
    defineComponent,
    PropType,
    TableData,
    computed,
    ref,
    watch,
    onMounted
} from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    props: {
        activeDesk: {
            required: true,
            type: Number as PropType<number>
        },
        mData: {
            required: true,
            type: Object as PropType<TableData>
        }
    },
    setup(props) {
        const { t } = useI18n()

        // const nuiList = ref<Nui[]>([])
        const nuiData = ref(new NuiData([], 10, t))

        const { currentCount, stopCountDown, startCountDown } = useCountdown()

        const onLoadAvatarError = (e: any) => {
            e.currentTarget.src = require(`@/assets/imgs/pc/dealer1@2x.png`)
        }

        const tableName = computed(() => {
            const { tableNo } = props.mData
            // const name = props.mData.gameTypeNo.toUpperCase()

            // if (name === 'G002') return `${t('baccarattable')}${tableNo}`
            // else if (name === 'G003')
            //     return `${t('dragontigertable')}${tableNo}`
            // else return `牛牛 ${tableNo}`

            return t('desklist.niuniutable', { tablenumber: tableNo })
        })

        const timerColor = computed(() => {
            if (currentCount.value === -1) {
                return 'wait'
            } else if (currentCount.value <= 10) {
                return 'red'
            } else {
                return ''
            }
        })

        watch(
            () => props.mData,
            (d) => {
                if (d) {
                    // deal with the timer
                    if (d.expand.daoJiShiBetTime > 0) {
                        startCountDown(d.expand.daoJiShiBetTime)
                    } else {
                        stopCountDown()
                    }

                    if (d.expand.resultList.length > 0) {
                        nuiData.value = new NuiData(
                            d.expand.resultList as Array<string>,
                            10,
                            t
                        )
                    } else {
                        nuiData.value = new NuiData([], 10, t)
                    }
                }
            },
            { immediate: true, deep: true }
        )

        return {
            tableName,
            currentCount,
            timerColor,
            nuiData,

            onLoadAvatarError,
            t
        }
    }
})
