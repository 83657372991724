
import { store, useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import { abbreviateNumber } from '@/utils'
import {
    ChipItem,
    ChipOption,
    computed,
    defineComponent,
    Emitter,
    inject,
    onMounted,
    ref
} from 'vue'
import { useI18n } from 'vue-i18n'
import DCustomChip from '@/components/d-custom-chip.vue'

export default defineComponent({
    components: {
        'd-custom-chip': DCustomChip
    },
    setup() {
        const store = useStore()
        const emitter = inject('emitter') as Emitter
        const { t } = useI18n()

        const langCode = ref<string>('')

        const activeChip = ref<number>(1)

        const selectedChips = ref<ChipItem[]>([])

        onMounted(() => {
            selectedChips.value = [...chipDefault.value]
        })

        const isSelected = (id: number) => {
            let isSelected = false

            for (let index = 0; index < selectedChips.value.length; index++) {
                const element = selectedChips.value[index]
                if (element.id == id) {
                    isSelected = true
                    break
                }
            }

            return isSelected
        }

        const chipLabel = (num: number) => {
            if (num > 0) {
                return abbreviateNumber(num)
            } else {
                return t('chips.enteramount')
            }
        }

        const onSelect = (chip: ChipItem) => {
            const cSelected = selectedChips.value.find((x) => x.id === chip.id)

            if (cSelected) {
                if (selectedChips.value.length < 6)
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        t('prompt.minandmaxchipsselected')
                    )
                else {
                    const _index = selectedChips.value.indexOf(cSelected)
                    selectedChips.value.splice(_index, 1)
                }
            } else {
                if (chip.value > 0 && selectedChips.value.length < 8) {
                    selectedChips.value.push(chip)
                } else {
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        t('prompt.minandmaxchipsselected')
                    )
                }
            }
        }

        const toShortValue = (value: number) => {
            return abbreviateNumber(value)
        }

        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'chips'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const updateCustumValue = (chipValue: ChipItem) => {
            if (chipValue.id > 18) {
                const toCommit = [...chipsArray.value]
                const chipValueItem = toCommit.find(
                    (e) => e.id === chipValue.id
                )

                if (chipValueItem) {
                    chipValueItem.value = chipValue.value
                    store.commit(AppMutation.SET_CHIPS_OPTIONS, toCommit)
                }
            }
        }

        const confirm = () => {
            const _chipsDefault = [...selectedChips.value]

            _chipsDefault.sort((a, b) => a.value - b.value)

            store.commit(AppMutation.SET_CUSTOM_CHIP, _chipsDefault)
            close()
        }

        const cancel = () => {
            close()
        }

        const setChips = (_copt: ChipOption) => {
            if (_copt.id !== chips.value.id)
                store.commit(AppMutation.SET_CHIPS, _copt)
        }

        const handleSelectLanguage = (_langCode: string) => {
            if (langCode.value !== _langCode) langCode.value = _langCode
        }

        const activeLang = computed((): string => store.getters['lang'])

        const chips = computed((): ChipOption => store.getters['chips'])

        const chipDefault = computed(
            (): ChipItem[] => store.getters['chipDefault']
        )

        const isMobile = computed((): boolean => store.getters['isMobile'])
        const chipsArray = computed(
            (): ChipItem[] => store.getters['chipsOptions']
        )

        onMounted(() => {
            langCode.value = activeLang.value
        })

        return {
            isMobile,
            chipsArray,
            activeLang,
            langCode,
            activeChip,
            chips,
            toShortValue,
            handleSelectLanguage,
            confirm,
            close,
            cancel,
            setChips,
            isSelected,
            onSelect,
            chipLabel,
            updateCustumValue,
            t
        }
    }
})
