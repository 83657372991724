<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13.509"
        height="12.07"
        viewBox="0 0 13.509 12.07"
    >
        <g id="icon" transform="translate(0 -0.115)">
            <path
                id="Path_13"
                data-name="Path 13"
                d="M5.254,11.84l-2.3-2.152V2.374L5.254.652c1-.7,1.642.1,1.642,1.291V10.55C6.9,11.738,6.144,12.565,5.254,11.84ZM0,7.537V4.525A1.949,1.949,0,0,1,1.642,2.374h.985V9.689H1.642A1.949,1.949,0,0,1,0,7.537Z"
                fill="#a8a8a8"
                fill-rule="evenodd"
            />
            <path
                id="Path_14"
                data-name="Path 14"
                d="M23.261,8.055a6.109,6.109,0,0,1-.056,7.3"
                transform="translate(-14.874 -5.577)"
                fill="none"
                stroke="#a8a8a8"
                stroke-width="2"
                fill-rule="evenodd"
            />
            <path
                id="Path_15"
                data-name="Path 15"
                d="M28.128.941c2.546,2.937,2.023,8.385,0,10.767"
                transform="translate(-17.339 -0.171)"
                fill="none"
                stroke="#a8a8a8"
                stroke-width="2"
                fill-rule="evenodd"
            />
        </g>
    </svg>
</template>