
import { ChipItem, computed, defineComponent, PropType, ref } from 'vue'
import { abbreviateNumber } from '@/utils'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    emits: ['clicked', 'update-custom-value'],
    props: {
        chipItem: {
            type: Object as PropType<ChipItem>,
            required: true,
            default: undefined
        }
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const isEditMode = ref(false)
        const customChipValue = ref<string>('')
        let clickAndDbClickCounter = 0
        let clickAndDbClickTimer: number | null | any = null
        const clicked = () => {
            clickAndDbClickCounter++

            if (clickAndDbClickCounter === 1) {
                clickAndDbClickTimer = setTimeout(() => {
                    clickAndDbClickCounter = 0
                    // do something on clicked

                    if (props.chipItem && props.chipItem.value > 0) {
                        emit('clicked', props.chipItem)
                    }
                }, 300)

                return
            }

            if (typeof clickAndDbClickTimer === 'number') {
                clearTimeout(clickAndDbClickTimer)
                clickAndDbClickTimer = null
            }

            clickAndDbClickCounter = 0

            // do something on double clicked
            if (props.chipItem && props.chipItem.value > 0) {
                // customChipValue.value = String(customChip.value)
            } else {
                // customChipValue.value = '0'
            }

            // do something on double clicked
            if (props.chipItem && props.chipItem.value > 0) {
                // props.chipItem.value = parseInt(customChipValue.value)
            } else {
                customChipValue.value = '0'
            }

            isEditMode.value = true

            setTimeout(() => {
                const el = document.getElementById(
                    `el${props.chipItem && props.chipItem.id}}`
                )
                if (el) {
                    setFocusOnElement(el)
                }
            }, 100)
        }

        const clickOutside = () => {
            isEditMode.value = false
        }

        const setFocusOnElement = (_el: HTMLElement) => {
            _el.focus()
            document.execCommand('selectAll', false, undefined)
        }

        const releaseFocusOnElement = (_el: HTMLElement) => {
            _el.blur()
        }

        const tryUpdateChipCustom = () => {
            const el = document.getElementById(
                `el${props.chipItem && props.chipItem.id}}`
            )

            if (!el) return
            const newChipCustomValue = parseInt(el.innerText)
            if (props.chipItem) {
                const { id, value } = props.chipItem
                if (!isNaN(newChipCustomValue)) {
                    emit('update-custom-value', {
                        id,
                        value: newChipCustomValue
                    })
                } else {
                    emit('update-custom-value', {
                        id,
                        value: newChipCustomValue
                    })
                    customChipValue.value = ''
                }
            }
        }

        const onLostFocus = () => {
            isEditMode.value = false
            tryUpdateChipCustom()
        }

        const handleInputEvt = (_event: KeyboardEvent) => {
            const { key } = _event
            const regex = new RegExp(/[^0-9]/, 'g')
            if (
                key.toLowerCase() !== 'delete' &&
                key.toLowerCase() !== 'backspace'
            ) {
                if (key.match(regex)) {
                    _event.preventDefault()
                }
            }

            const el = document.getElementById(
                `el${props.chipItem && props.chipItem.id}}`
            )

            if (key.toLowerCase() === 'enter' && el) {
                releaseFocusOnElement(el)
                isEditMode.value = false
                // tryUpdateChipCustom()
            }
        }

        const chipLabel = computed(() => {
            if (props.chipItem && props.chipItem.value > 0) {
                return abbreviateNumber(props.chipItem.value)
            } else {
                return t('chips.enteramount')
            }
        })

        return {
            clicked,
            chipLabel,
            isEditMode,
            clickOutside,
            handleInputEvt,
            onLostFocus
        }
    }
})
