import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "35.779",
  height: "35.779",
  viewBox: "0 0 35.779 35.779"
}
const _hoisted_2 = {
  id: "icon_multiple_celect-table",
  transform: "translate(1.212 1.212)"
}
const _hoisted_3 = ["stroke"]
const _hoisted_4 = ["fill"]
const _hoisted_5 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("g", _hoisted_2, [
      _createElementVNode("path", {
        id: "Path_213",
        "data-name": "Path 213",
        d: "M13.758,0A13.753,13.753,0,1,0,25.441,6.49",
        transform: "translate(6.657 0) rotate(14)",
        fill: "none",
        stroke: _ctx.fill,
        "stroke-linecap": "round",
        "stroke-width": "2"
      }, null, 8, _hoisted_3),
      _createElementVNode("path", {
        id: "Path_180",
        "data-name": "Path 180",
        d: "M16.9,9.763a1.314,1.314,0,0,1,0,2.627h-.236a1.314,1.314,0,0,1,0,2.627H15.95a1.314,1.314,0,0,1,0,2.627H8.98a4.276,4.276,0,0,1-4.253-4.3v-4.1a1.351,1.351,0,0,1,.2-.789.805.805,0,0,1,.648-.4c.925,0,1.806-1.917,2.378-2.84A33.164,33.164,0,0,0,9.819,1.685a1.475,1.475,0,0,1,2.012-.6,1.507,1.507,0,0,1,.592,2.033A38.492,38.492,0,0,1,11.106,6.9c-.035.083-.073.161-.111.239h5.9a1.314,1.314,0,0,1,0,2.627ZM2.009,17.4A2.019,2.019,0,0,1,0,15.375V9.882a2.019,2.019,0,0,1,2.009-2.03,2.019,2.019,0,0,1,2.009,2.03v5.493A2.019,2.019,0,0,1,2.009,17.4Z",
        transform: "translate(6.885 6.88)",
        fill: _ctx.fill,
        "fill-rule": "evenodd"
      }, null, 8, _hoisted_4),
      _createElementVNode("circle", {
        id: "Ellipse_112",
        "data-name": "Ellipse 112",
        cx: "4",
        cy: "4",
        r: "4",
        transform: "translate(21.355 3.085)",
        fill: _ctx.fill
      }, null, 8, _hoisted_5)
    ])
  ]))
}