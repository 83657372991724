import { CookiesToken } from '@/types/CookiesToken'
import { Dictionary } from '@/types/dictionary'
import { getBrowserLanguage } from '@/utils'
import Cookies from 'js-cookie'
import { AppState, InjectionKey, State, UserState } from 'vue'
import { createStore, Store, useStore as baseUseStore } from 'vuex'

import { app } from './modules/app'
import { user } from './modules/user'
import { AppMutation } from './types'
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
    state: {
        version: '1.0.1',
        root: true
    },
    mutations: {
        [AppMutation.CLEAR_ALL](state) {
            const _useState = user.state as UserState
            const _appState = app.state as AppState

            _useState.token = undefined
            _useState.username = undefined
            _useState.userPoint = 0
            _useState.lobyNotice = ''
            _useState.memberid = -1
            _useState.isFreeUser = true
            _useState.commandType = 2
            _useState.userInfo = undefined
            _useState.visitorTable = []
            _useState.clientTimeOut = 5
            _useState.gameTypes = []
            _useState.multiTables = []
            _useState.avatar = 1
            _useState.cashoutdetails = undefined

            _appState.canPlaySound = 1
            _appState.currentDesk = -1
            _appState.chips = {
                id: 1,
                value: [5, 10, 20, 50, 100, 500, 1000, 10000]
            }

            _appState.chipDefault = [
                { id: 11, value: 10000 },
                { id: 12, value: 25000 },
                { id: 13, value: 50000 },
                { id: 14, value: 100000 },
                { id: 17, value: 500000 },
                { id: 18, value: 1000000 }
            ]

            _appState.chipsOptions = [
                { id: 1, value: 5 },
                { id: 2, value: 10 },
                { id: 3, value: 20 },
                { id: 4, value: 50 },
                { id: 5, value: 100 },
                { id: 6, value: 200 },
                { id: 7, value: 500 },
                { id: 8, value: 1000 },
                { id: 9, value: 2000 },
                { id: 10, value: 5000 },
                { id: 11, value: 10000 },
                { id: 12, value: 20000 },
                { id: 13, value: 50000 },
                { id: 14, value: 100000 },
                { id: 15, value: 150000 },
                { id: 16, value: 200000 },
                { id: 17, value: 250000 },
                { id: 18, value: 300000 },
                { id: 19, value: 0 },
                { id: 20, value: 0 },
                { id: 21, value: 0 },
                { id: 22, value: 0 },
                { id: 23, value: 0 },
                { id: 24, value: 0 }
            ]

            _appState.betLayout = 0
            _appState.goodWay = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

            window.sessionStorage.clear()
        }
    },
    actions: {},
    modules: {
        app,
        user
    }
})

export function useStore(): Store<State> {
    return baseUseStore(key)
}
