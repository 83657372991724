
import { defineComponent, PropType, ref } from 'vue'
import {
    IconThumbsUp,
    IconAllGames,
    IconBaccarat,
    IconDragonTiger,
    SvgNuinui
    // IconMultiTable
} from '@/components/icons'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    emits: ['update:modelValue', 'goodway-option', 'force-reload'],
    props: {
        modelValue: {
            type: Number as PropType<number>
        }
    },
    components: {
        // IconThumbsUp,
        IconAllGames
        // IconBaccarat,
        // IconDragonTiger,
        // 'svg-nui-nui': SvgNuinui
        // IconMultiTable
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const showOtherOptions = ref(false)
        const otherOption = ref([0, 1])
        const clickNavItem = (index: number) => {
            if (index != props.modelValue) {
                emit('update:modelValue', index)
            } else {
                emit('force-reload')
            }
        }

        const setOtherOptions = (num: number) => {
            const index = otherOption.value.indexOf(num)
            if (index === -1) {
                otherOption.value.push(num)
            } else {
                otherOption.value.splice(index, 1)
            }

            if (
                otherOption.value.length === 2 ||
                otherOption.value.length === 0
            ) {
                emit('goodway-option', 3)
            } else {
                emit('goodway-option', otherOption.value[0])
            }
        }

        return {
            t,
            clickNavItem,
            setOtherOptions,
            showOtherOptions,
            otherOption
        }
    }
})
