import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "icon_public_only-big",
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  viewBox: "0 0 20 20"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      id: "Path_37",
      "data-name": "Path 37",
      d: "M27,27H23V18h9v4A5,5,0,0,1,27,27ZM23,7h4a5,5,0,0,1,5,5v4H23Zm-2,9H12V12a5,5,0,0,1,5-5h4Zm0,11H17a5,5,0,0,1-5-5V18h9Z",
      transform: "translate(-12 -7)",
      fill: _ctx.fill,
      "fill-rule": "evenodd"
    }, null, 8, _hoisted_2)
  ]))
}