<template>
    <svg
        id="icon_public_setting"
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="42"
        viewBox="0 0 42 42"
    >
        <g id="Group_263" data-name="Group 263">
            <path
                id="Path_7"
                data-name="Path 7"
                d="M7,0H35a7,7,0,0,1,7,7V35a7,7,0,0,1-7,7H7a7,7,0,0,1-7-7V7A7,7,0,0,1,7,0Z"
                fill="#242425"
                fill-rule="evenodd"
            />
            <path
                id="Path_8"
                data-name="Path 8"
                d="M32.912,32.236l-4.2-1.036a12.968,12.968,0,0,1-2.721,1.456L24.6,36.687a16.664,16.664,0,0,1-7.514-.194l-1.2-4.1a13.148,13.148,0,0,1-1.809-1c-.292-.192-.567-.394-.838-.6l-4.252.817a16.128,16.128,0,0,1-3.6-6.512l3-3.061a12.443,12.443,0,0,1,.069-3.055L5.6,15.767A16,16,0,0,1,7.53,11.843,16.2,16.2,0,0,1,9.5,9.455l4.2,1.036a12.877,12.877,0,0,1,2.719-1.454l1.392-4.031A16.671,16.671,0,0,1,25.33,5.2l1.2,4.1a13.119,13.119,0,0,1,1.807.993c.29.191.565.394.836.6l4.252-.819a16.147,16.147,0,0,1,3.6,6.514l-3,3.061a12.511,12.511,0,0,1-.069,3.053l2.853,3.214a16.08,16.08,0,0,1-3.906,6.314ZM26.144,13.545a8.988,8.988,0,0,0-12.366,2.411,8.723,8.723,0,0,0,2.493,12.192,8.986,8.986,0,0,0,12.366-2.41A8.726,8.726,0,0,0,26.144,13.545ZM17.673,26.074a6.248,6.248,0,0,1-1.785-8.73,6.435,6.435,0,0,1,8.854-1.725,6.247,6.247,0,0,1,1.785,8.728A6.434,6.434,0,0,1,17.673,26.074Z"
                fill="#fff"
                fill-rule="evenodd"
            />
        </g>
    </svg>
</template>