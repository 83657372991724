
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref,
    watch
} from 'vue'
import { useViewPort } from './composables/useViewPort'
import { useStore } from './store'
import AppLoader from '@/components/app-loader.vue'
import AssetsLoader from '@/components/assets-loader.vue'
import { EventBuss, SOCKET_MESSAGE } from './types/global'
import { AppMutation } from './store/types'
import screenfull from 'screenfull'
import {
    getDeviceOrientation,
    isMobile as isAMobile,
    isIosMobile
} from './utils'
import {
    MsgBoxSetting,
    MsgBoxSecurity,
    MsgBoxAgree,
    MsgBoxChip,
    MsgGameRule,
    MsgRecord,
    MsgBoxMessage,
    MsgBoxTip,
    MsgBoxTipConcent,
    MsgBoxConfirmBooking,
    MsgBoxMediaSetting,
    MsgBoxGoodwaySetting,
    MsgBoxAvatar,
    MsgBoxSwitcPlay,
    MsgBoxConfirmCancel,
    MsgBoxUserAndRecordInfo,
    MxgBoxRecordM,
    MxgBoxNuiBonus,
    MsgBoxTopUp,
    MsgBoxWithdraw,
    MsgBoxWithdrawHistory,
    MsgNotice
} from '@/components/dialog'
import {
    DialogBoxName,
    MessageBoxActionType,
    MessageBoxEvent
} from './types/MessageBox'
import { useRoute, useRouter } from 'vue-router'
import { ROUTES } from './router'
import { MJWebsocket } from './utils/websocket'
import audioPlayer from './utils/sounds'
import { useAssetPreload } from '@/composables/useAssetPreload'
import { useI18n } from 'vue-i18n'
import jwt from 'jsonwebtoken'

export default defineComponent({
    components: {
        'app-loader': AppLoader,
        'msg-box-setting': MsgBoxSetting,
        'msg-box-security': MsgBoxSecurity,
        'msg-box-agree': MsgBoxAgree,
        'msg-box-chips': MsgBoxChip,
        'msg-box-rule': MsgGameRule,
        'msg-box-record': MsgRecord,
        'msg-box-message': MsgBoxMessage,
        'msg-box-tip': MsgBoxTip,
        'msg-box-tip-concent': MsgBoxTipConcent,
        'msg-box-confirm-booking': MsgBoxConfirmBooking,
        'msg-box-media-setting': MsgBoxMediaSetting,
        'msg-box-good-way-setting': MsgBoxGoodwaySetting,
        'msg-box-avatar': MsgBoxAvatar,
        'assets-loader': AssetsLoader,
        'msg-box-switch-play': MsgBoxSwitcPlay,
        'msg-box-confirm-cancel': MsgBoxConfirmCancel,
        'msg-box-user-and-record-info': MsgBoxUserAndRecordInfo,
        'msg-box-record-m': MxgBoxRecordM,
        'msg-box-nui-bonus': MxgBoxNuiBonus,
        'msg-box-top-up': MsgBoxTopUp,
        'msg-box-withdraw': MsgBoxWithdraw,
        'msg-box-withdraw-history': MsgBoxWithdrawHistory,
        'msg-notice': MsgNotice
    },
    setup() {
        const appRoot = ref<HTMLElement | undefined>(undefined)
        const store = useStore()
        const emitter = inject('emitter') as Emitter
        const route = useRoute()
        const router = useRouter()
        const showMainView = ref(true)
        const { t } = useI18n()

        let isPageBlured = false

        const { refVar, PreloadAssets } = useAssetPreload()

        let _ws: MJWebsocket | undefined

        const toastMessage = ref<string>('This is Toast')
        const toastMessageCounter = ref<number>(0)

        const dialogCollection = ref<string[]>([])

        const showLanguageDialogBox = ref<boolean>(false)
        const showSettingDialogBox = ref<boolean>(false)
        const showSecurityDialogBox = ref<boolean>(false)
        const showAgreeDialogBox = ref<boolean>(false)
        const showChipsDialogBox = ref<boolean>(false)
        const showRuleDialogBox = ref<boolean>(false)
        const showRecordDialogBox = ref<boolean>(false)
        const showMessageDialogBox = ref<boolean>(false)
        const showMessageDialogTipBox = ref<boolean>(false)
        const showMessageDialogTipBoxConcent = ref<boolean>(false)
        const showMessageDialogVipBox = ref<boolean>(false)
        const showMessageDialogMediaSettingBox = ref<boolean>(false)
        const showGoodWaySetting = ref(false)
        const showAvatarDialog = ref(false)
        const showWitchPlayMsgBox = ref(false)
        const showCancelConfirmMessage = ref(false)
        const showuserInfoAndBetRecord = ref(false)
        const showMobileRecord = ref(false)
        const showNuiBonus = ref(false)
        const showTopUp = ref(false)
        const showWithdraw = ref(false)
        const showWithdrawHistory = ref(false)
        const showNoticePrompt = ref(false)

        let clientTimeOutStarted = false
        let clientTimeOutSession: number | boolean | any = false

        let toastMessageInterval: boolean | number | any = false

        //#region Computed Helpers
        const isMobile = computed((): boolean => store.getters['isMobile'])
        const deviceOrientation = computed(
            (): 'landscape' | 'portrait' => store.getters['deviceOrientation']
        )
        //#endregion

        const { windowResize } = useViewPort(appRoot.value)

        //#region Vue Helpers
        onBeforeMount(() => {
            if (!isIosMobile()) {
                window.addEventListener('resize', handleResizeWindow)
            }

            // listen to page in active and active state
            window.addEventListener(
                'visibilitychange',
                handlePageVisibilityChange
            )

            window.removeEventListener('blur', handlePageBlur)
            window.removeEventListener('focus', handlePageFocus)

            emitter.on(EventBuss.TOAST_MESSAGE, handleToastMessage)
            emitter.on(EventBuss.DIALOG, handleDialogEvent)
            emitter.on(EventBuss.REQUEST_FULL_SCREEN, requestFullScreen)
            emitter.on(EventBuss.SOCKET_CONNECT, connectToWebsocket)
            emitter.on(EventBuss.SOCKET_CLOSE, handleCloseSocket)
            // emitter.on(EventBuss.START_CLIENT_TIME_OUT, startClientTimeOut)
            if (isMobile.value) {
                window.addEventListener(
                    'orientationchange',
                    handleOrientationChange
                )
            }

            // if (token.value && !_ws.isConnected) connectToWebsocket()
        })

        onMounted(() => {
            handleResizeWindow()

            if (!audioPlayer.isLoaded) {
                audioPlayer.isOn = canPlaySound.value ? 1 : 0
                audioPlayer.LoadSounds()
            }

            PreloadAssets()

            const queryString = window.location.search
            const urlParams = new URLSearchParams(queryString)

            const tokenValue = urlParams.get('token')
            if (tokenValue) {
                const isValid = verifyToken(tokenValue)

                if (isValid) console.log('Token Is Valid')
                else console.log('Token Is Not Valid')
            }

            if (token.value && !_ws?.isConnected) {
                connectToWebsocket()
            }
        })

        onBeforeUnmount(() => {
            if (!isIosMobile()) {
                window.removeEventListener('resize', handleResizeWindow)
            }

            document.removeEventListener(
                'visibilitychange',
                handlePageVisibilityChange
            )

            emitter.off(EventBuss.TOAST_MESSAGE, handleToastMessage)
            emitter.off(EventBuss.DIALOG, handleDialogEvent)
            emitter.off(EventBuss.REQUEST_FULL_SCREEN, requestFullScreen)
            emitter.off(EventBuss.SOCKET_CONNECT, connectToWebsocket)
            emitter.off(EventBuss.SOCKET_CLOSE, handleCloseSocket)
            // emitter.off(EventBuss.START_CLIENT_TIME_OUT, startClientTimeOut)
            if (isMobile.value) {
                window.removeEventListener(
                    'orientationchange',
                    handleOrientationChange
                )
            }
        })
        //#endregion

        //#region Helpers

        const handlePageBlur = () => {
            isPageBlured = true
        }

        const handlePageFocus = () => {
            if (isPageBlured === true) {
                handlePageVisibilityChange()
            }
        }

        const verifyToken = (_token: string): boolean => {
            try {
                const decoded = jwt.decode(_token, { complete: true })

                // Check expiration
                if (decoded) {
                    const currentTime = Math.floor(Date.now() / 1000)
                    const payload: any = decoded.payload
                    if (payload.exp && payload.exp < currentTime) {
                        return false
                    }

                    if (payload.exp === undefined) return false

                    // set user infos

                    store.commit(AppMutation.INIT_USER_SESSION, {
                        token: _token,
                        username: payload.code,
                        point: 0,
                        memberId: payload.adminId,
                        isFree: true
                    })

                    return true
                } else {
                    return false
                }
            } catch (e: any) {
                console.log(e.message)
                return false
            }
        }

        const handlePageVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                isPageBlured = false
                emitter.emit(EventBuss.PAGE_ON_VISIBLE)
            }
        }

        const stopClientTimeOut = () => {
            if (typeof clientTimeOutSession === 'number') {
                clearTimeout(clientTimeOutSession)
                clientTimeOutSession = false
                clientTimeOutStarted = false
            }
        }
        const handleResizeWindow = () => {
            emitter.emit(EventBuss.WINDOW_RESIZED)
            windowResize(appRoot.value)
        }

        const handleToastMessage = (_message: string) => {
            toastMessageCounter.value = 9
            toastMessage.value = _message
            if (typeof toastMessageInterval !== 'number') {
                toastMessageInterval = setInterval(() => {
                    toastMessageCounter.value -= 1
                    if (
                        toastMessageCounter.value <= 0 &&
                        typeof toastMessageInterval === 'number'
                    ) {
                        clearInterval(toastMessageInterval)
                        toastMessageInterval = false
                        toastMessage.value = ''
                    }
                }, 150)
            }
        }

        const initSound = (e: Event) => {
            // alert(window.innerHeight)

            if (!isUserInteracted.value) {
                store.commit(AppMutation.SET_USER_INTERACTION)
                audioPlayer.Start()
                audioPlayer.isOn = canPlaySound.value ? 1 : 0

                audioPlayer.soundEffect = musicCanPlay.value

                audioPlayer.SetMusicVolume(musicVolume.value)
                audioPlayer.soundsVolume = soundVoulume.value

                if (route.name !== ROUTES.LOGIN && musicCanPlay.value !== 0)
                    audioPlayer.PlayMusic()
                // console.log('init sound')
            }
        }

        const handleOrientationChange = () => {
            if (screenfull.isEnabled && !screenfull.isFullscreen) {
                const docEl = document.getElementById('app') as HTMLElement
                if (getDeviceOrientation() === 'landscape') {
                    screenfull
                        .request(docEl, { navigationUI: 'hide' })
                        .then(() => {
                            store.commit(
                                AppMutation.SET_FULL_SCREEN,
                                screenfull.isFullscreen
                            )
                        })
                }
            }

            if (isAMobile()) {
                showMainView.value = false
                setTimeout(() => {
                    handleResizeWindow()
                    showMainView.value = true
                }, 500)
            }
        }

        const requestFullScreen = () => {
            if (screenfull.isEnabled) {
                const docEl = document.getElementById('app') as HTMLElement
                if (!screenfull.isFullscreen) {
                    screenfull
                        .request(docEl, { navigationUI: 'hide' })
                        .then(() => {
                            store.commit(
                                AppMutation.SET_FULL_SCREEN,
                                screenfull.isFullscreen
                            )
                        })
                } else {
                    screenfull.exit().then(() => {
                        store.commit(
                            AppMutation.SET_FULL_SCREEN,
                            screenfull.isFullscreen
                        )
                    })
                }
            }
        }

        const handleDialogEvent = (e: MessageBoxEvent) => {
            if (e.type === MessageBoxActionType.close) {
                if (dialogCollection.value.includes(e.name)) {
                    const _i = dialogCollection.value.indexOf(e.name)
                    dialogCollection.value.splice(_i, 1)
                }

                if (e.name === 'lang') showLanguageDialogBox.value = false
                else if (e.name === 'setting')
                    showSettingDialogBox.value = false
                else if (e.name === 'security')
                    showSecurityDialogBox.value = false
                else if (e.name === 'agree') showAgreeDialogBox.value = false
                else if (e.name === 'chips') showChipsDialogBox.value = false
                else if (e.name === 'rule') showRuleDialogBox.value = false
                else if (e.name === 'record') showRecordDialogBox.value = false
                else if (e.name === 'message')
                    showMessageDialogBox.value = false
                else if (e.name === 'tipbox')
                    showMessageDialogTipBox.value = false
                else if (e.name === 'tipbox-concent')
                    showMessageDialogTipBoxConcent.value = false
                else if (e.name === 'confirm-booking')
                    showMessageDialogVipBox.value = false
                else if (e.name === 'media-setting')
                    showMessageDialogMediaSettingBox.value = false
                else if (e.name === 'good-way-setting')
                    showGoodWaySetting.value = false
                else if (e.name === 'avatar') showAvatarDialog.value = false
                else if (e.name === 'switch-play')
                    showWitchPlayMsgBox.value = false
                else if (e.name === 'confirm-cancel-message')
                    showCancelConfirmMessage.value = false
                else if (e.name === 'record-and-user-info')
                    showuserInfoAndBetRecord.value = false
                else if (e.name === 'mobile-record') {
                    showMobileRecord.value = false
                } else if (e.name === 'nui-bonus-rules')
                    showNuiBonus.value = false
                else if (e.name === 'top-up') showTopUp.value = false
                else if (e.name === 'withdraw') showWithdraw.value = false
                else if (e.name === 'withdraw-history')
                    showWithdrawHistory.value = false
                else if (e.name === 'notice') showNoticePrompt.value = false
            } else if (e.type === MessageBoxActionType.open) {
                if (!dialogCollection.value.includes(e.name))
                    dialogCollection.value.push(e.name)
                if (e.name === 'lang') showLanguageDialogBox.value = true
                else if (e.name === 'setting') showSettingDialogBox.value = true
                else if (e.name === 'security')
                    showSecurityDialogBox.value = true
                else if (e.name === 'agree') showAgreeDialogBox.value = true
                else if (e.name === 'chips') showChipsDialogBox.value = true
                else if (e.name === 'rule') showRuleDialogBox.value = true
                else if (e.name === 'record') showRecordDialogBox.value = true
                else if (e.name === 'message') {
                    showMessageDialogBox.value = true
                } else if (e.name === 'tipbox')
                    showMessageDialogTipBox.value = true
                else if (e.name === 'tipbox-concent')
                    showMessageDialogTipBoxConcent.value = true
                else if (e.name === 'confirm-booking')
                    showMessageDialogVipBox.value = true
                else if (e.name === 'media-setting')
                    showMessageDialogMediaSettingBox.value = true
                else if (e.name === 'good-way-setting')
                    showGoodWaySetting.value = true
                else if (e.name === 'avatar') showAvatarDialog.value = true
                else if (e.name === 'switch-play')
                    showWitchPlayMsgBox.value = true
                else if (e.name === 'confirm-cancel-message')
                    showCancelConfirmMessage.value = true
                else if (e.name === 'record-and-user-info')
                    showuserInfoAndBetRecord.value = true
                else if (e.name === 'mobile-record') {
                    showMobileRecord.value = true
                } else if (e.name === 'nui-bonus-rules')
                    showNuiBonus.value = true
                else if (e.name === 'top-up') showTopUp.value = true
                else if (e.name === 'withdraw') showWithdraw.value = true
                else if (e.name === 'withdraw-history')
                    showWithdrawHistory.value = true
                else if (e.name === 'notice') showNoticePrompt.value = true
            }
        }

        const closeAllDialogs = () => {
            const _arr = [...dialogCollection.value]
            _arr.forEach((_m) => {
                const _ev: MessageBoxEvent = {
                    type: MessageBoxActionType.close,
                    name: _m as DialogBoxName
                }

                handleDialogEvent(_ev)
            })
        }
        //#endregion

        //#region MJSocket Helpers
        const connectToWebsocket = () => {
            if (token.value) {
                // close the socket if there is a current socket
                _ws?.close()
                initWebSocket()
                _ws?.connect(`${process.env.WS_URLS}${token.value}`)
            }
        }

        const initWebSocket = () => {
            _ws = new MJWebsocket(emitter)
            _ws.OnMessage = (message: MessageEvent) => {
                if (typeof message.data === 'string') {
                    const objMessage = JSON.parse(message.data)

                    // const mgType = (objMessage.type as string).toUpperCase()
                    emitter.emit(EventBuss.SOCKET_MESSAGE, objMessage)
                    // console.log(objMessage)

                    const _type = objMessage.type.toUpperCase()

                    if (_type === SOCKET_MESSAGE.USER_OUT) {
                        const _evt: MessageBoxEvent = {
                            type: MessageBoxActionType.open,
                            name: 'message'
                        }

                        emitter.emit(EventBuss.DIALOG, _evt)

                        setTimeout(() => {
                            emitter.emit(EventBuss.HINT, objMessage.content)
                        }, 250)
                    } else if (_type === SOCKET_MESSAGE.USER_OUT_BY) {
                        // console.log(_type)
                        emitter.emit(EventBuss.SOCKET_CLOSE)
                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })

                        const _evt: MessageBoxEvent = {
                            type: MessageBoxActionType.open,
                            name: 'message'
                        }

                        setTimeout(() => {
                            emitter.emit(EventBuss.DIALOG, _evt)

                            setTimeout(() => {
                                emitter.emit(EventBuss.HINT, objMessage.content)
                            }, 250)
                        }, 100)
                    } else if (_type === SOCKET_MESSAGE.MEMBER_AMOUNT) {
                        store.commit(
                            AppMutation.SET_COIN,
                            objMessage.data.amount
                        )
                    } else if (_type === SOCKET_MESSAGE.WS_EXPIRED) {
                        window.sessionStorage.clear()
                        const evt: MessageBoxEvent = {
                            type: MessageBoxActionType.open,
                            name: 'message'
                        }
                        emitter.emit(EventBuss.DIALOG, evt)
                        setTimeout(() => {
                            emitter.emit(
                                EventBuss.HINT,
                                '网络错误，请您稍后重新登录系统，谢谢！'
                            )
                            emitter.emit(EventBuss.SOCKET_CLOSE)
                        }, 250)
                    } else if (
                        _type === SOCKET_MESSAGE.GUNNER_BET ||
                        _type === SOCKET_MESSAGE.BET
                    ) {
                        const _sDesk = objMessage.gameTable
                        if (_sDesk.expand.memberId === memberid.value) {
                            store.commit(AppMutation.SET_CLIENT_TIME_OUT, 15)
                        }
                    }
                }
            }
        }

        const handleCloseSocket = () => {
            _ws?.close()
            _ws = undefined
        }

        //#endregion

        const showLoading = computed(
            (): boolean => store.getters['showLoading']
        )

        const canPlaySound = computed(
            (): boolean => store.getters['canPlaySound']
        )
        const isUserInteracted = computed(
            (): boolean => store.getters['isUserInteracted']
        )
        const playerTimeOut = computed(
            (): number => store.getters['playerTimeOut']
        )

        const hasVisibleDialog = computed(
            () => dialogCollection.value.length > 0
        )

        const memberid = computed(() => store.getters['memberid'])

        const token = computed(() => store.getters['token'])
        const user = computed((): string => store.getters['user'])

        const musicVolume = computed((): number => store.getters['musicVolume'])
        const soundVoulume = computed(
            (): number => store.getters['soundVolume']
        )

        const musicCanPlay = computed(
            (): number => store.getters['musicCanPlay']
        )

        watch(
            () => route.name,
            (_n) => {
                if (typeof _n === 'string' && _n === ROUTES.LOGIN) {
                    closeAllDialogs()
                    stopClientTimeOut()
                    audioPlayer.StopMusic()
                } else {
                    if (musicCanPlay.value === 1) audioPlayer.PlayMusic()

                    // if (typeof _n === 'string' && route.name !== ROUTES.LOGIN) {
                    //     if (!clientTimeOutStarted) {
                    //         startClientTimeOut()
                    //     }
                    // }
                }
            }
        )

        watch(
            () => deviceOrientation.value,
            () => {
                closeAllDialogs()
            }
        )

        return {
            showNoticePrompt,
            showWithdrawHistory,
            showWithdraw,
            showTopUp,
            showMainView,
            isMobile,
            appRoot,
            showLoading,
            toastMessage,
            toastMessageCounter,
            hasVisibleDialog,
            showLanguageDialogBox,
            showSettingDialogBox,
            showSecurityDialogBox,
            showAgreeDialogBox,
            showChipsDialogBox,
            showRuleDialogBox,
            showRecordDialogBox,
            showMessageDialogBox,
            showMessageDialogTipBox,
            showMessageDialogTipBoxConcent,
            showMessageDialogVipBox,
            showMessageDialogMediaSettingBox,
            showGoodWaySetting,
            showAvatarDialog,
            showWitchPlayMsgBox,
            showCancelConfirmMessage,
            showuserInfoAndBetRecord,
            showNuiBonus,
            refVar,
            showMobileRecord,
            requestFullScreen,
            initSound,
            t
        }
    }
})
