
import { defineComponent, PropType } from 'vue'

export default defineComponent({
    props: {
        isOn: {
            required: false,
            default: true,
            type: Boolean as PropType<boolean>
        }
    },
    setup() {
        //
    }
})
