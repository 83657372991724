import { useStore } from '@/store'
import { LANG_CODE } from '@/types/global'
import audioPlayer, { SOUNDKEY } from '@/utils/sounds'
import { CallbackFunction, computed, ref } from 'vue'

export function useCountdown(
    hasSound = false,
    callback?: CallbackFunction,
    callback2?: CallbackFunction
) {
    const store = useStore()
    const currentCount = ref<number>(-1)
    let countDownInterval: boolean | number | NodeJS.Timeout = false

    const startCountDown = (time: number) => {
        if (typeof countDownInterval !== 'number') {
            currentCount.value = time

            if (currentCount.value > 20) {
                if (lang.value === LANG_CODE.ENGLISH)
                    playSound(SOUNDKEY.START_BET_EN)
                else if (lang.value === LANG_CODE.KOREAN)
                    playSound(SOUNDKEY.START_BET_KOR)
                else if (lang.value === LANG_CODE.JAPANESE)
                    playSound(SOUNDKEY.START_BET_JA)
                else if (lang.value === LANG_CODE.VIETNAMESE)
                    playSound(SOUNDKEY.START_BET_VN)
                else if (lang.value === LANG_CODE.THAI)
                    playSound(SOUNDKEY.START_BET_TH)
                else playSound(SOUNDKEY.START_BET_CN)
            }

            countDownInterval = setInterval(() => {
                if (currentCount.value <= 0) {
                    currentCount.value = -1
                    if (lang.value === LANG_CODE.ENGLISH)
                        playSound(SOUNDKEY.STOP_BET_EN)
                    else if (lang.value === LANG_CODE.KOREAN)
                        playSound(SOUNDKEY.STOP_BET_KOR)
                    else if (lang.value === LANG_CODE.JAPANESE)
                        playSound(SOUNDKEY.STOP_BET_JA)
                    else if (lang.value === LANG_CODE.THAI)
                        playSound(SOUNDKEY.STOP_BET_TH)
                    else if (lang.value === LANG_CODE.VIETNAMESE)
                        playSound(SOUNDKEY.STOP_BET_VN)
                    else playSound(SOUNDKEY.STOP_BET_CN)
                    stopCountDown()
                    if (callback2) callback2()
                } else {
                    if (currentCount.value <= 11 && currentCount.value > 0)
                        playSound(SOUNDKEY.TICK)
                    currentCount.value -= 1
                }
            }, 1000)
        }
    }

    const stopCountDown = (): void => {
        if (typeof countDownInterval === 'number') {
            clearInterval(countDownInterval)
            countDownInterval = false
            currentCount.value = -1
        }

        if (callback) {
            callback()
        }
    }

    const playSound = (soundKey: string) => {
        if (hasSound) {
            audioPlayer.Play(soundKey)
        }
    }

    const lang = computed((): string => store.getters['lang'])

    return {
        currentCount,
        startCountDown,
        stopCountDown
    }
}
