<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36.191"
        height="36.191"
        viewBox="0 0 36.191 36.191"
    >
        <g id="icon_multiple_all-unlock" transform="translate(1.302 1.302)">
            <path
                id="Subtraction_17"
                data-name="Subtraction 17"
                d="M14,13H3a1,1,0,0,1-1-1V3A1,1,0,0,1,3,2H14a1,1,0,0,1,1,1v9A1,1,0,0,1,14,13ZM8.5,4A2.5,2.5,0,0,0,8,8.95V12H9V8.95A2.5,2.5,0,0,0,8.5,4Z"
                transform="translate(8.672 12.294)"
                fill="#dccb96"
            />
            <path
                id="Path_235"
                data-name="Path 235"
                d="M11.515,0h-.168a4,4,0,0,0-4,4V7.066"
                transform="translate(12.998 7.229)"
                fill="none"
                stroke="#dccb96"
                stroke-width="2"
            />
            <path
                id="Path_238"
                data-name="Path 238"
                d="M8,0a4,4,0,0,1,4,4"
                transform="translate(16.51 7.229)"
                fill="none"
                stroke="#dccb96"
                stroke-linecap="round"
                stroke-width="2"
            />
            <path
                id="Path_237"
                data-name="Path 237"
                d="M12.9,0A12.9,12.9,0,1,0,23.856,6.085"
                transform="translate(9.665 0) rotate(22)"
                fill="none"
                stroke="#dccb96"
                stroke-linecap="round"
                stroke-width="2"
            />
        </g>
    </svg>
</template>