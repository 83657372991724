
import { useStore } from '@/store'
import { AppActions, AppMutation } from '@/store/types'
import { ACT, EventBuss, OtherApiParams } from '@/types/global'
import {
    DialogBoxName,
    MessageBoxActionType,
    MessageBoxEvent
} from '@/types/MessageBox'
import CtrlDropdown from '@/components/drop-down.vue'
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeUnmount,
    onMounted,
    ref,
    ResponseData,
    StyleValue,
    watch
} from 'vue'
import { useI18n } from 'vue-i18n'
import {
    IService,
    IRule,
    ISetting,
    IPassword,
    IChip,
    IRecord,
    IExit,
    IconLang,
    IconArrowD,
    IconVideo,
    IconBGM,
    IconVoice,
    IconNotify,
    IconChipSetting,
    IconThumbsUp3,
    IconMessage,
    SvgArrowDown,
    SvgRecord
} from '@/components/icons'
import { logOut as doLogout } from '@/api'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router'
import audioPlayer from '@/utils/sounds'
import BtnSwitch from '@/components/custom-controls/switch-control.vue'

export default defineComponent({
    components: {
        // IService,
        'icon-rule': IRule,
        // ISetting,
        // IPassword,
        // IChip,
        // IRecord,
        // IExit,
        IconLang,
        IconArrowD,
        SvgRecord,
        // IconVideo,
        'svg-arrow-down': SvgArrowDown,
        // 'icon-bgm': IconBGM,
        // 'icon-voice': IconVoice,
        'icon-notify': IconNotify,
        'icon-chip-setting': IconChipSetting,
        // 'icon-thumbs-up-3': IconThumbsUp3,
        'icon-message': IconMessage,
        'ctrl-drop-down': CtrlDropdown,
        'btn-switch': BtnSwitch
    },
    setup() {
        const emitter = inject('emitter') as Emitter
        const { t, locale } = useI18n()
        const store = useStore()
        const router = useRouter()
        const hideIt = ref(false)
        const currentMenu = ref(-1)
        const audioValue = ref(0)
        const musicValue = ref(0)
        const isMusicOn = ref(false)
        const isSoundEffectOn = ref(false)
        let isFirstLoad = true
        let isFirstLoadSound = true

        const languages = ref<{ code: string; name: string }[]>([
            { code: 'kor', name: '한국어' },
            { code: 'zh-CN', name: '简体中文' },
            { code: 'zh-HK', name: '繁體中文' },
            { code: 'en', name: 'ENGLISH' },
            { code: 'th', name: 'แบบไทย' },
            { code: 'ja', name: '日本語' },
            { code: 'vn', name: 'Tiếng Việt' }
        ])

        const videoSettings = ref(['高清', '标清'])
        const musicOptions = ref(['恭喜你发财', '爱情买卖'])
        const soundOptions = ref(['林志玲', '周润发'])

        const selectedLanguage = ref(0)
        const selectedVideoSetting = ref(0)
        const selectedMusic = ref(0)
        const selectedSound = ref(0)

        onMounted(() => {
            const activelangIndex = languages.value.findIndex(
                (x) => x.code === lang.value
            )
            if (activelangIndex !== -1) {
                selectedLanguage.value = activelangIndex
            }

            isMusicOn.value = musicCanPlay.value === 1
            isSoundEffectOn.value = soundEffect.value === 1

            if (!isMusicOn.value) {
                store.commit(AppMutation.SET_MUSIC_VOLUME, 0)
            }

            if (!isSoundEffectOn.value) {
                store.commit(AppMutation.SET_SOUND_VOLUME, 0)
            }

            musicValue.value = musicVolume.value
            audioValue.value = soundVoulume.value
        })

        const close = () => {
            if (musicVolume.value !== musicValue.value) {
                if (musicVolume.value >= 0.1) {
                    audioPlayer.SetMusicVolume(musicVolume.value)
                } else {
                    audioPlayer.StopMusic()
                }
            }

            if (soundVoulume.value !== audioValue.value) {
                audioPlayer.SetSoundVolume(soundVoulume.value)
            }

            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'setting'
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const showDialog = (name: DialogBoxName) => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: name
            }
            emitter.emit(EventBuss.DIALOG, _evt)
            close()
        }

        const confirm = () => {
            if (musicVolume.value !== musicValue.value) {
                store.commit(AppMutation.SET_MUSIC_VOLUME, musicValue.value)
            }

            if (soundVoulume.value !== audioValue.value) {
                store.commit(AppMutation.SET_SOUND_VOLUME, audioValue.value)
            }

            if (soundVoulume.value <= 0.01) {
                store.commit(AppMutation.SET_SOUND, 0)
                audioPlayer.isOn = 0
            } else {
                store.commit(AppMutation.SET_SOUND, 1)
                audioPlayer.isOn = 1
            }

            if (musicValue.value <= 0.01) {
                store.commit(AppMutation.SET_MUSIC_VOLUME, 0)
                store.commit(AppMutation.SET_MUSIC_PLAY, 0)
                audioPlayer.soundEffect = 0
                audioPlayer.StopMusic()
            } else {
                store.commit(AppMutation.SET_MUSIC_PLAY, 1)
                audioPlayer.soundEffect = musicVolume.value
                audioPlayer.PlayMusic()
            }

            close()
        }

        const showSecurity = () => {
            close()
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'security'
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const showChipsOption = () => {
            // close()
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'chips'
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const showConfrimCancelMessage = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'confirm-cancel-message'
            }
            emitter.emit(EventBuss.DIALOG, _evt)
            setTimeout(() => {
                emitter.emit(EventBuss.CONFIRM_CANCEL_CONTENT, {
                    message: t('hints.logout'),
                    callback: logOut
                })
            }, 10)
        }

        const showMediaSetting = () => {
            close()
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'media-setting'
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const toggleMusic = (event: boolean) => {
            isMusicOn.value = event
            if (event) {
                audioPlayer.soundEffect = 1
                audioPlayer.SetMusicVolume(1)
                audioPlayer.PlayMusic()
                store.commit(AppMutation.SET_MUSIC_VOLUME, 1)
                store.commit(AppMutation.SET_MUSIC_PLAY, 1)
            } else {
                audioPlayer.soundEffect = 0
                audioPlayer.SetMusicVolume(0)
                audioPlayer.StopMusic()
                store.commit(AppMutation.SET_MUSIC_VOLUME, 0)
                store.commit(AppMutation.SET_MUSIC_PLAY, 0)
            }
        }

        const toggleSoundEffect = (event: boolean) => {
            isSoundEffectOn.value = event

            if (event) {
                audioPlayer.isOn = 1
                audioPlayer.SetSoundVolume(1)
                store.commit(AppMutation.SET_SOUND_VOLUME, 1)
                store.commit(AppMutation.SET_SOUND, 1)
            } else {
                audioPlayer.isOn = 0
                audioPlayer.SetSoundVolume(0)
                store.commit(AppMutation.SET_SOUND_VOLUME, 0)
                store.commit(AppMutation.SET_SOUND, 0)
            }
        }

        const showRule = () => {
            close()
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'rule'
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const emitDialogEvent = (name: DialogBoxName) => {
            close()
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const logOut = () => {
            const _params = { memberId: memberId.value, token: token.value }
            store.commit(AppMutation.SHOW_LOADING)

            // close the websocket before calling logout api
            emitter.emit(EventBuss.SOCKET_CLOSE)

            doLogout(_params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    const logOutData = response.data as ResponseData<any>
                    if (logOutData.status === 200) {
                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    } else {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            logOutData.description!
                        )
                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(EventBuss.TOAST_MESSAGE, t('networkerror'))
                })
        }

        const showTogglemenu = (post: number) => {
            if (post === currentMenu.value) currentMenu.value = -1
            else currentMenu.value = post
        }

        const gotoCustumerService = () => {
            window.open(process.env.CUSTUMER_SERVICE, '_blank')
        }

        const user = computed(() => store.getters['user'])
        const memberId = computed(() => store.getters['memberid'])
        const token = computed(() => store.getters['token'])
        const lang = computed(() => store.getters['lang'])

        const musicVolume = computed((): number => store.getters['musicVolume'])
        const soundVoulume = computed(
            (): number => store.getters['soundVolume']
        )
        const musicCanPlay = computed(
            (): number => store.getters['musicCanPlay']
        )
        const soundEffect = computed((): number => store.getters['soundEffect'])

        const audioProgress = computed(() => {
            const _volume = audioValue.value / 1
            const b = Math.abs(_volume * 100)

            if (!isFirstLoadSound) {
                if (_volume >= 0.1) {
                    audioPlayer.isOn = 1
                    audioPlayer.SetSoundVolume(_volume)
                } else {
                    audioPlayer.isOn = 0
                    audioPlayer.SetSoundVolume(0)
                    audioPlayer.Stop()
                }
            }

            isFirstLoadSound = false

            let n = {
                '--audio-value': b
            } as StyleValue

            return n
        })

        const musicProgress = computed(() => {
            const _volume = musicValue.value / 1
            const b = Math.abs(_volume * 100)
            audioPlayer.SetMusicVolume(musicValue.value)

            if (!isFirstLoad) {
                if (_volume >= 0.1) {
                    audioPlayer.soundEffect = 1
                    audioPlayer.SetMusicVolume(musicValue.value)
                    audioPlayer.PlayMusic()
                } else {
                    audioPlayer.soundEffect = 0
                    audioPlayer.SetMusicVolume(0)
                    audioPlayer.StopMusic()
                }
            }

            isFirstLoad = false

            let n = {
                '--music-value': b
            } as StyleValue

            return n
        })

        watch(
            () => selectedLanguage.value,
            (_language) => {
                const language = languages.value[_language]
                if (language) {
                    locale.value = language.code
                    store.commit(AppMutation.SET_LANG, language.code)
                }
            }
        )

        return {
            t,
            showChipsOption,
            showSecurity,
            logOut,
            close,
            showRule,
            gotoCustumerService,
            emitDialogEvent,
            showMediaSetting,
            currentMenu,
            showTogglemenu,
            confirm,
            showDialog,
            showConfrimCancelMessage,
            languages,
            lang,
            selectedLanguage,
            selectedVideoSetting,
            videoSettings,
            musicOptions,
            selectedMusic,
            soundOptions,
            selectedSound,
            audioValue,
            audioProgress,
            musicValue,
            musicProgress,
            isMusicOn,
            isSoundEffectOn,
            toggleMusic,
            toggleSoundEffect
        }
    }
})
