
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import {
    BetRecord,
    BetRecordData,
    computed,
    defineComponent,
    Emitter,
    GameType,
    inject,
    onMounted,
    ref,
    ResponseData,
    watch
} from 'vue'
import Pagination from '@/components/pagination.vue'
import Dropdown from '@/components/drop-down.vue'
import { SvgArrowDown1, SvgArrowDown } from '@/components/icons'
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { getBetRecordList } from '@/api'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router'
import { formatDate } from '@/utils'

export default defineComponent({
    components: {
        // 'dt-calendar-picker': RangeCalendarPicker,
        'mj-pgnation': Pagination,
        'dropdown': Dropdown,
        'svg-arrow-down-1': SvgArrowDown1
        //   'svg-arrow-down': SvgArrowDown,
    },
    setup() {
        const emitter = inject('emitter') as Emitter
        const store = useStore()
        const router = useRouter()
        const { t } = useI18n()
        const dateValue = ref({ start: new Date(), end: new Date() })
        const masks = ref({
            input: 'YYYY-MM-DD'
        })

        // set default start time and end time

        const modelConfig = ref({
            start: {
                timeAdjust: '00:00:00'
            },
            end: {
                timeAdjust: '23:59:59'
            }
        })

        const gameType = ref(0)

        const dataInfo = ref<BetRecordData>()
        const gameNo = ref('')

        onMounted(() => {
            const _parms = { memberId: memberId.value, token: token.value }
            fetchData(_parms)
        })

        const fetchData = (_params: any) => {
            store.commit(AppMutation.SHOW_LOADING)
            getBetRecordList(_params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)

                    const recordData =
                        response.data as ResponseData<BetRecordData>

                    if (recordData && recordData.status === 200) {
                        dataInfo.value = recordData.data
                    } else if (recordData && recordData.status === 301) {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            recordData.description
                        )

                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    } else {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            recordData.description
                        )
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(EventBuss.TOAST_MESSAGE, t('networkerror'))
                })
        }

        const doSearch = () => {
            if (dateValue.value) {
                const { start, end } = dateValue.value

                const _params: any = {
                    memberId: memberId.value,
                    token: token.value,
                    timestart: start.getTime(),
                    timeend: end.getTime()
                }

                if (gameNo.value !== '') {
                    _params.gameNo = gameNo.value
                }

                if (gameType.value !== 0 && filterGameTypes.value.length > 0) {
                    _params.gameTypeNo =
                        filterGameTypes.value[gameType.value].typeNo
                }

                fetchData(_params)
            }
        }

        const toPage = (_page: number) => {
            if (dateValue.value) {
                const { start, end } = dateValue.value

                const _params = {
                    memberId: memberId.value,
                    token: token.value,
                    timestart: start.getTime(),
                    timeend: end.getTime(),
                    page: _page
                }
                fetchData(_params)
            } else {
                const _params = {
                    memberId: memberId.value,
                    token: token.value,
                    page: _page
                }
                fetchData(_params)
            }
        }

        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'record'
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const valueColor = (_v: number) => {
            if (_v > 0) return 'green'
            else if (_v < 0) return 'red'

            return ''
        }

        const toStringValue = (_v: number) => {
            if (_v > 0) return `+${_v}`

            return _v
        }

        const dataInfoList = computed((): BetRecord[] => {
            if (dataInfo.value) return dataInfo.value.betRecordList.items || []
            return []
        })

        const memberId = computed(() => store.getters['memberid'])
        const token = computed(() => store.getters['token'])

        const gameTypes = computed((): GameType[] => store.getters['gameTypes'])

        const minDate = () => {
            const days = 45
            const date = new Date()
            const last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000)
            const day = last.getDate()
            const month = last.getMonth() + 1
            const year = last.getFullYear()
            return new Date(`${month}/${day}/${year}`)
        }

        const filterGameTypes = computed(() => {
            const noneValue: GameType = {
                id: -1,
                expand: null,
                addTime: '',
                expression: null,
                status: -1,
                typeName: '전부',
                typeNo: ''
            }

            return [noneValue, ...gameTypes.value]
        })

        const hasData = computed(() => {
            if (dataInfo.value)
                return dataInfo.value.betRecordList.itemsCount > 0
            return false
        })

        const pageSize = computed(() => {
            if (dataInfo.value) return dataInfo.value.betRecordList.pageSize
            return 0
        })
        const totalSize = computed(() => {
            if (dataInfo.value) return dataInfo.value.betRecordList.totalSize
            return 0
        })
        const totalPage = computed(() => {
            if (dataInfo.value) return dataInfo.value.betRecordList.totalPage
            return 0
        })

        const pageNo = computed(() => {
            if (dataInfo.value) return dataInfo.value.betRecordList.pageNo
            return 0
        })

        const lang = computed(() => store.getters['lang'])

        return {
            lang,
            dateValue,
            masks,
            gameType,
            dataInfoList,
            filterGameTypes,
            hasData,
            pageSize,
            totalSize,
            totalPage,
            pageNo,
            gameNo,
            modelConfig,
            minDate,
            toPage,
            doSearch,
            formatDate,
            close,
            valueColor,
            toStringValue,
            t
        }
    }
})
