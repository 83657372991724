export enum AppMutation {
    SET_ORIENTATION = 'SET_ORIENTATION',
    SET_DEVICE = 'SET_DEVICE',
    INIT_USER_SESSION = 'INIT_USER_SESSION',
    HIDE_LOADING = 'HIDE_LOADING',
    SHOW_LOADING = 'SHOW_LOADING',
    SET_COIN = 'SET_COIN',
    CLEAR_ALL = 'CLEAR_ALL',
    SET_LOBY_NOTICE = 'SET_LOBY_NOTICE',
    SET_DESK_NOTICE = 'SET_DESK_NOTICE',
    ADD_RESULT_TO_CACHE = 'ADD_RESULT_TO_CACHE',
    SET_SOUND = 'SET_SOUND',
    SET_USER_INTERACTION = 'SET_USER_INTERACTION',
    SET_LANG = 'SET_LANG',
    SET_DESK_OPTIONS = 'SET_DESK_OPTIONS',
    SET_CHIPS = 'SET_CHIPS',
    SET_CURRENT_CHIPS = 'SET_CURRENT_CHIPS',
    SET_CUSTOM_CHIP = 'SET_CUSTOM_CHIP',
    SET_LIMIT = 'SET_LIMIT',
    SET_LIMITS = 'SET_LIMITS',
    SET_FULL_SCREEN = 'SET_FULL_SCREEN',
    SET_TOKEN = 'SET_TOKEN',
    SET_MEMBER_ID = 'SET_MEMBER_ID',
    SET_USER = 'SET_USER',
    SET_IS_FREE = 'SET_IS_FREE',
    SET_COMMAND_TYPE = 'SET_COMMAND_TYPE',
    SET_VIDEO = 'SET_VIDEO',
    SET_IS_GUNNER = 'SET_IS_GUNNER',
    SET_USER_INFO = 'SET_USER_INFO',
    SET_DESK = 'SET_DESK',
    SET_VISITOR_DESK = 'SET_VISITOR_DESK',
    SET_CLIENT_TIME_OUT = 'SET_CLIENT_TIME_OUT',
    SET_USER_GUNNER_BIND = 'SET_USER_GUNNER_BIND',
    SET_CHIPS_OPTIONS = 'SET_CHIPS_OPTIONS',
    SET_MUSIC_VOLUME = 'SET_MUSIC_VOLUME',
    SET_MUSIC_PLAY = 'SET_MUSIC_PALY',
    SET_SOUND_VOLUME = 'SET_SOUND_VOLUME',
    SET_BET_LAYOUT = 'SET_BET_LAYOUT',
    SET_GAME_TYPES = 'SET_GAME_TYPES',
    SET_MULTITABLES = 'SET_MULTITABLES',
    SET_AVATAR = 'SET_AVATAR',
    SET_GOOD_WAY = 'SET_GOOD_WAY',
    SET_MEMBER_INFO = 'SET_MEMBER_INFO',
    SET_PLAYER_IS_READY = 'SET_PLAYER_IS_READY',
    SET_MULTI_TABLE_VIDEO = 'SET_MULTI_TABLE_VIDEO',
    SET_DISPLAY_TABLE_INFO = 'SET_DISPLAY_TABLE_INFO',
    SET_HAS_ANCHOR_TABLES = 'SET_HAS_ANCHOR_TABLES',
    SET_HAS_CLASSIC_TABLES = 'SET_HAS_CLASSIC_TABLES',
    SET_MULTIPLIERS = 'SET_MULTIPLIERS',
    SET_CASH_OUT_INFO = 'SET_CASH_OUT_INFO',
    SET_COMMISSION = 'SET_COMMISSION',
    SET_JACPOT_BET_AMOUNT = 'SET_JACPOT_BET_AMOUNT',
    SET_POP_NOTICE = 'SET_POP_NOTICE',
    SET_POP_NOTICE_SEEN = 'SET_POP_NOTICE_SEEN',
    SET_CURRENCY = 'SET_CURRENCY'
}

export enum AppActions {
    FETCH_DATA = 'FETCH_DATA'
}
