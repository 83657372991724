const nuibonusrules = {
    en:
        '<b>Jackpot Description</b><br><br>' +
        'Jackpot - Regardless of whether you win or not, if you make a maid with 5 cards, the accumulated jackpot amount will be paid according to the payout rate of the type of jackpot you won.<br><br>' +
        'Odds<br>' +
        '-Royal straight flush (50%)<br>' +
        '-Straight flush (20%)<br>' +
        '-Four of a Kind (12%)<br>' +
        '-Full House (6%)<br>' +
        '-Flush (3%)<br>' +
        '-Straight (1%)<br>' +
        '-Three of a Kind (0.5%)<br><br>' +
        'All jackpot bets are fixed at 2,000 won, and only players who have placed a bet in Super Bet can participate.<br>' +
        'Jackpot accumulation starts from 10,000,000 won and is accumulated according to the jackpot bet amount of all users.<br>' +
        'If multiple users bet on the same section at the same time and win, the players who bet will be divided into 1 in n portions.<br>' +
        "The player's bet amount is accumulated indefinitely, and once the dividend is paid, the remaining amount is accumulated.",
    'zh-cn':
        '<b>头奖说明</b><br><br>' +
        '头奖 - 无论您是否中奖，如果您制作了 5 张牌的女仆，累积奖金金额将根据您赢得的头奖类型的支付率支付。<br><br>' +
        '赔率<br>' +
        '-皇家同花顺 (50%)<br>' +
        '-同花 (20%)<br>' +
        '-四样 (12%)<br>' +
        '-满堂彩 (6%)<br>' +
        '-同花 (3%)<br>' +
        '-顺子 (1%)<br>' +
        '-三样 (0.5%)<br><br>' +
        '所有头奖赌注固定为 2,000 韩元，只有在 Super Bet 中下注的玩家才能参加。<br>' +
        '累积奖金从 10,000,000 韩元开始，根据所有用户的累积奖金投注金额进行累积。<br>' +
        '如果多个用户同时在同一区域下注并获胜，则下注的玩家将被分成 n 份中的 1 份。<br>' +
        '玩家的投注金额无限期累积，一旦支付红利，剩余金额就累积。',
    'zh-hk':
        '<b>頭獎說明</b><br><br>' +
        '頭獎 - 無論您是否中獎，如果您製作了 5 張牌的女僕，累積獎金金額將根據您贏得的頭獎類型的支付率支付。<br><br>' +
        '賠率<br>' +
        '-皇家同花順 (50%)<br>' +
        '-同花 (20%)<br>' +
        '-四樣 (12%)<br>' +
        '-滿堂彩 (6%)<br>' +
        '-同花 (3%)<br>' +
        '-順子 (1%)<br>' +
        '-三樣 (0.5%)<br><br>' +
        '所有頭獎賭注固定為 2,000 韓元，只有在 Super Bet 中下注的玩家才能參加。<br>' +
        '累積獎金從 10,000,000 韓元開始，根據所有用戶的累積獎金投注金額進行累積。<br>' +
        '如果多個用戶同時在同一區域下注並獲勝，則下注的玩家將被分成 n 份中的 1 份。<br>' +
        '玩家的投注金額無限期累積，一旦支付紅利，剩餘金額就累積。',
    kr:
        '<b>잭팟 설명</b><br><br>' +
        '잭팟- 승리여부와 상관없이 5장의 카드로 메이드를 만들었을 경우 적립된 잭팟 금액에서 당첨된 잭팟 유형의 지급률에 맞게 지급이 됩니다.<br><br>' +
        '배당률<br>' +
        '-로얄 스트레이트 플러쉬(50%)<br>' +
        '-스트레이트 플러쉬(20%)<br>' +
        '-포 오브 어 카인드(12%)<br>' +
        '-풀하우스(6%)<br>' +
        '-플러시(3%)<br>' +
        '-스트레이트(1%)<br>' +
        '-쓰리 오브 어 카인드(0.5%)<br><br>' +
        '모든 잭팟 베팅은 2,000원고정이며, 슈퍼베팅에 베팅을 한 플레이어만 참여가 가능합니다.<br>' +
        '잭팟은 10,000,000원부터 적립이 시작되며 전체 접속자들의 잭팟 베팅금에 따라 적립됩니다.<br>' +
        '동일한 구간에 다중 접속자들이 동시에 베팅하여 승리할경우, 베팅한 플레이어분들은 n분의 1로 나눠서 지급됩니다.<br>' +
        '플레이어의 베팅 금액은 무기한 적립되며, 배당금이 지급되면 남은 금액에 이어서 적립이 이어집니다.',
    th:
        '<b>คำอธิบายแจ็คพอต</b><br><br>' +
        'แจ็คพอต - ไม่ว่าคุณจะชนะหรือไม่ก็ตาม หากคุณสร้างเมดด้วยไพ่ 5 ใบ จำนวนแจ็คพอตสะสมจะจ่ายตามอัตราการจ่ายของประเภทแจ็คพอตที่คุณชนะ<br><br>' +
        'อัตราต่อรอง<br>' +
        '-รอยัลสเตรทฟลัช (50%)<br>' +
        '-สเตรทฟลัช (20%)<br>' +
        '-สี่ชนิด (12%)<br>' +
        '-ฟูลเฮ้าส์ (6%)<br>' +
        '-ฟลัช (3%)<br>' +
        '-ตรง (1%)<br>' +
        '-สามชนิด (0.5%)<br><br>' +
        'การเดิมพันแจ็คพอตทั้งหมดได้รับการแก้ไขที่ 2,000 วอน และเฉพาะผู้เล่นที่วางเดิมพันใน Super Bet เท่านั้นที่สามารถเข้าร่วมได้<br>' +
        'การสะสมแจ็คพอตเริ่มต้นที่ 10,000,000 วอน และสะสมตามจำนวนเดิมพันแจ็คพอตของผู้ใช้ทุกคน<br>' +
        'หากผู้ใช้หลายคนเดิมพันในส่วนเดียวกันในเวลาเดียวกันและชนะ ผู้เล่นที่เดิมพันจะถูกแบ่งออกเป็น 1 ใน n ส่วน<br>' +
        'จำนวนเดิมพันของผู้เล่นจะสะสมไปเรื่อยๆ และเมื่อจ่ายเงินปันผลแล้ว ยอดคงเหลือก็จะถูกสะสม',
    ja:
        '<b>ジャックポットの説明</b><br><br>' +
        'ジャックポット - 5枚メイドを成立させた場合は、当否に関わらず、当選したジャックポット種類のペイアウト率に応じてジャックポット金額が払い出されます。<br><br>' +
        '倍率<br>' +
        '-ロイヤルフラッシュ (50%)<br>' +
        '-同じ色 (20%)<br>' +
        '-4種類 (12%)<br>' +
        '-マンタンカイ (6%)<br>' +
        '-同じ花(3%)<br>' +
        '-真っ直ぐ (1%)<br>' +
        '-三つのこと (0.5%)<br><br>' +
        'すべてのジャックポット ベットは 2,000 ウォンに固定されており、スーパー ベットにベットしたプレイヤーのみが参加できます。<br>' +
        'ジャックポットは10,000,000ウォンから始まり、全ユーザーのジャックポットベット額に基づいて積み立てられます。<br>' +
        '複数のユーザーが同じエリアに同時に賭けて勝った場合、賭けたプレイヤーは n 個のシェアのうち 1 つに分割されます。<br>' +
        'プレイヤーの賭け金は無期限に蓄積され、ボーナスが支払われると、残りの金額も蓄積されます。',
    vn:
        '<b>thông tin về số tiền góp</b><br><br>' +
        'Số tiền góp : bất luận bạn có trúng thưởng hay không,...số tiền thưởng tích lũy sẽ căn cứ theo tỷ lệ thanh toán của loại tiền góp mà bạn thắng được để thanh toán<br><br>' +
        'tỷ lệ bồi thường<br>' +
        'Thùng phá sảnh (50%)<br>' +
        'xả thẳng hoàng gia(20%)<br>' +
        'Tứ quý (12%)<br>' +
        'Cù lũ (6%)<br>' +
        'Đồng chất (3%)<br>' +
        'Sảnh (1%)<br>' +
        'Ba lá giống nhau (0.5%)<br><br>' +
        'Tất cả các cược jackpot được cố định ở mức 2.000 KRW và chỉ những người chơi đặt cược vào Super Bet mới có thể tham gia<br>' +
        'Giải độc đắc bắt đầu từ 10.000.000 KRW và được tích lũy dựa trên số tiền đặt cược giải độc đắc của tất cả người dùng.<br>' +
        'Nếu nhiều người dùng cùng đặt cược trên cùng một khu vực và giành chiến thắng thì người chơi đặt cược sẽ được chia thành 1 trong n phần.<br>' +
        'Tiền đặt cược của người chơi sẽ tích lũy vô thời hạn và sau khi tiền thưởng được trả, số tiền còn lại sẽ được tích lũy'
}
export default nuibonusrules
