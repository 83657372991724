import { fetch } from '@/api'
import { CookiesToken } from '@/types/CookiesToken'
import { getCookieValue, parseBool, setCookieValue } from '@/utils'
import {
    CashOutInfo,
    GameType,
    LimitOption,
    MemberInfo,
    State,
    UserInfo,
    UserState
} from 'vue'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { AppActions, AppMutation } from '../types'
import { Currencies } from '@/types/global'

const state: UserState = {
    username: getCookieValue(CookiesToken.USER_NAME)?.b64decode(),
    token: getCookieValue(CookiesToken.TOKEN)?.b64decode(),
    lobyNotice: getCookieValue(CookiesToken.LOBY_NOTICE) || '',
    deskNotice: getCookieValue(CookiesToken.DESK_NOTICE) || '',
    userPoint: parseFloat(getCookieValue(CookiesToken.POINT) || '0'),
    deskNoticeKey: new Date().getTime(),
    lobyNoticeKey: new Date().getTime(),
    memberid: parseInt(getCookieValue(CookiesToken.MEMBER) || '0'),
    isFreeUser: parseBool(getCookieValue(CookiesToken.FREE_MEMBER) || 'true'),
    commandType: parseInt(getCookieValue(CookiesToken.COMMAND_TYPE) || '2'),
    isGunner: 0,
    userInfo: getCookieValue(CookiesToken.USER_INFO)
        ?.b64decode()
        .toObject<UserInfo>(),
    clientTimeOut: parseInt(
        getCookieValue(CookiesToken.CLIENT_TIME_COUNT) || '10'
    ),
    visitorTable: (getCookieValue(CookiesToken.VISITOR_TABLE) || '[]').toObject<
        []
    >(),
    isBindToGunner: false,
    gameTypes: (getCookieValue(CookiesToken.GAME_TYPES) || '[]').toObject<
        GameType[]
    >(),
    limits: (getCookieValue(CookiesToken.LIMITS) || '[]').toObject<
        LimitOption[]
    >(),
    multiTables: (getCookieValue(CookiesToken.MULTI_TABLES) || '[]').toObject<
        number[]
    >(),
    avatar: parseInt(getCookieValue(CookiesToken.AVATAR) || '1'),
    memberInfo:
        getCookieValue(CookiesToken.MEMBER_INFO)
            ?.b64decode()
            .toObject<MemberInfo>() || undefined,
    evenMultiplier: parseInt(
        getCookieValue(CookiesToken.EVEN_MULTIPLIER) || '1'
    ),
    doubleMultiplier: parseInt(
        getCookieValue(CookiesToken.DOUBLE_MULTIPLIER) || '1'
    ),
    superMultiplier: parseInt(
        getCookieValue(CookiesToken.SUPER_MULTIPLIER) || '1'
    ),
    cashoutdetails: undefined,
    commission: '0',
    jackpotBetAmount: 0
}

const mutations: MutationTree<UserState> = {
    [AppMutation.INIT_USER_SESSION](
        state,
        payload: {
            token: string
            username: string
            point: number
            memberId: number
            isFree: boolean
        }
    ) {
        state.token = payload.token
        state.username = payload.username
        state.userPoint = payload.point
        state.isFreeUser = payload.isFree
        state.memberid = payload.memberId

        state.clientTimeOut = 10

        // if (!state.isFreeUser) {
        //     state.clientTimeOut = 10
        //     // setCookieValue(CookiesToken.CLIENT_TIME_COUNT, '15')
        // } else {
        //     state.clientTimeOut = 10
        //     // setCookieValue(CookiesToken.CLIENT_TIME_COUNT, '5')
        // }

        setCookieValue(CookiesToken.TOKEN, payload.token.b64encode())
        setCookieValue(CookiesToken.USER_NAME, payload.username.b64encode())
        setCookieValue(CookiesToken.POINT, String(payload.point))
        setCookieValue(CookiesToken.FREE_MEMBER, String(payload.isFree))
        setCookieValue(CookiesToken.MEMBER, String(payload.memberId))
    },
    [AppMutation.SET_COIN](state, payload: number) {
        state.userPoint = payload

        setCookieValue(CookiesToken.POINT, String(payload))
    },
    [AppMutation.SET_USER_INFO](state, payload: UserInfo) {
        state.userInfo = payload
        setCookieValue(
            CookiesToken.USER_INFO,
            JSON.stringify(payload).b64encode()
        )
    },
    [AppMutation.SET_LOBY_NOTICE](state, payload: string) {
        state.lobyNotice = payload
        state.lobyNoticeKey = new Date().getTime()
        setCookieValue(CookiesToken.LOBY_NOTICE, payload)
    },
    [AppMutation.SET_DESK_NOTICE](state, payload: string) {
        state.deskNotice = payload
        state.deskNoticeKey = new Date().getTime()
        setCookieValue(CookiesToken.DESK_NOTICE, payload)
    },
    [AppMutation.SET_TOKEN](state, payload) {
        state.token = payload
        setCookieValue(CookiesToken.TOKEN, payload.b64encode())
    },
    [AppMutation.SET_MEMBER_ID](state, payload: number) {
        state.memberid = payload
        setCookieValue(CookiesToken.MEMBER, payload.toString().b64encode())
    },
    [AppMutation.SET_USER](state, payload: string) {
        state.username = payload
        setCookieValue(CookiesToken.USER_NAME, payload.b64encode())
    },
    [AppMutation.SET_IS_FREE](state, payload: boolean) {
        state.isFreeUser = payload
        setCookieValue(CookiesToken.FREE_MEMBER, payload.toString().b64encode())
    },
    [AppMutation.SET_COMMAND_TYPE](state, payload: number) {
        state.commandType = payload
        setCookieValue(CookiesToken.COMMAND_TYPE, payload.toString())
    },
    [AppMutation.SET_IS_GUNNER](state, payload: number) {
        state.isGunner = payload
    },
    [AppMutation.SET_VISITOR_DESK](state, payload: number[]) {
        state.visitorTable = payload
        setCookieValue(CookiesToken.VISITOR_TABLE, JSON.stringify(payload))
    },
    [AppMutation.SET_CLIENT_TIME_OUT](state, payload: number) {
        state.clientTimeOut = payload
        setCookieValue(CookiesToken.CLIENT_TIME_COUNT, String(payload))
    },
    [AppMutation.SET_USER_GUNNER_BIND](state, payload: boolean) {
        state.isBindToGunner = payload
    },
    [AppMutation.SET_GAME_TYPES](state, payload: GameType[]) {
        state.gameTypes = payload
        setCookieValue(CookiesToken.GAME_TYPES, JSON.stringify(payload))
    },
    [AppMutation.SET_LIMITS](state, payload: LimitOption[]) {
        state.limits = payload
        setCookieValue(CookiesToken.LIMITS, JSON.stringify(state.limits))
    },
    [AppMutation.SET_MULTITABLES](state, payload: number[]) {
        state.multiTables = payload
        setCookieValue(CookiesToken.MULTI_TABLES, JSON.stringify(payload))
    },
    [AppMutation.SET_AVATAR](state, payload: number) {
        state.avatar = payload
        setCookieValue(CookiesToken.AVATAR, String(payload))
    },
    [AppMutation.SET_LIMIT](state, payload: number) {
        if (state.memberInfo) {
            state.memberInfo.limitId = payload
            setCookieValue(
                CookiesToken.MEMBER_INFO,
                JSON.stringify(state.memberInfo).b64encode()
            )
        }
    },
    [AppMutation.SET_MEMBER_INFO](state, payload: MemberInfo) {
        state.memberInfo = payload
        setCookieValue(
            CookiesToken.MEMBER_INFO,
            JSON.stringify(payload).b64encode()
        )
    },
    [AppMutation.SET_MULTIPLIERS](
        state,
        payload: {
            evenMultiplier: number
            doubleMultiplier: number
            superMultiplier: number
        }
    ) {
        state.evenMultiplier = payload.evenMultiplier
        state.doubleMultiplier = payload.doubleMultiplier
        state.superMultiplier = payload.superMultiplier

        setCookieValue(
            CookiesToken.EVEN_MULTIPLIER,
            String(state.evenMultiplier)
        )
        setCookieValue(
            CookiesToken.DOUBLE_MULTIPLIER,
            String(state.doubleMultiplier)
        )
        setCookieValue(
            CookiesToken.SUPER_MULTIPLIER,
            String(state.superMultiplier)
        )
    },
    [AppMutation.SET_CASH_OUT_INFO](state, payload: CashOutInfo) {
        state.cashoutdetails = payload
    },
    [AppMutation.SET_COMMISSION](state, payload) {
        state.commission = payload
    },
    [AppMutation.SET_JACPOT_BET_AMOUNT](state, payload: number) {
        state.jackpotBetAmount = payload
    }
}

const actions: ActionTree<UserState, State> = {
    [AppActions.FETCH_DATA]({ ...args }, params): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            fetch(params)
                .then((response) => resolve(response.data))
                .catch((e) => {
                    console.log(e)
                    reject('网络错误')
                })
        })
    }
}

const getters: GetterTree<UserState, State> = {
    token(state) {
        return state.token
    },
    user(state) {
        return state.username
    },
    lobyNotice(state) {
        return state.lobyNotice
    },
    deskNotice(state) {
        return state.deskNotice
    },
    lobyNoticeKey(state) {
        return state.lobyNoticeKey
    },
    deskNoticeKey(state) {
        return state.deskNoticeKey
    },

    coin(state) {
        return state.userPoint
    },
    commandType(state) {
        return state.commandType
    },
    memberid(state) {
        return state.memberid
    },
    isGunner(state) {
        return state.isGunner
    },
    userInfo(state) {
        return state.userInfo
    },
    isFreeUser(state) {
        return state.isFreeUser
    },
    visitorTable(state) {
        return state.visitorTable
    },
    playerTimeOut(state) {
        return state.clientTimeOut
    },
    isBindToGunner(state) {
        return state.isBindToGunner
    },
    gameTypes(state) {
        return state.gameTypes
    },
    limits(state) {
        return state.limits
    },
    userLimit(state) {
        return state.memberInfo?.limitId ?? 0
    },
    multiTables(state) {
        return state.multiTables
    },
    avatar(state) {
        return state.avatar
    },
    memeberInfo(state) {
        return state.memberInfo
    },
    evenMultiplier: (state) => state.evenMultiplier,
    superMultiplier: (state) => state.superMultiplier,
    doubleMultiplier: (state) => state.doubleMultiplier,
    cashoutdetails: (state) => state.cashoutdetails,
    commission: (state) => state.commission,
    jackpotBetAmount: (state) => state.jackpotBetAmount
}

export const user: Module<UserState, State> = {
    state,
    mutations,
    actions,
    getters
}
