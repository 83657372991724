import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "icon_public_statistics",
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  viewBox: "0 0 20 20"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      id: "Path_36",
      "data-name": "Path 36",
      d: "M22.867,27a9.128,9.128,0,0,1-9.125-9H23V8.742A9.129,9.129,0,0,1,22.867,27ZM22,7V17H12A10,10,0,0,1,22,7Z",
      transform: "translate(-12 -7)",
      fill: _ctx.fill,
      "fill-rule": "evenodd"
    }, null, 8, _hoisted_2)
  ]))
}