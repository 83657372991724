/**
 * get the device orientation
 * @returns string portrait | landscape
 */

import { CookiesToken } from '@/types/CookiesToken'
import { EventBuss, Lang, Currencies, NiuBonusType } from '@/types/global'
import {
    DialogBoxName,
    MessageBoxActionType,
    MessageBoxEvent
} from '@/types/MessageBox'
import { ChipItem } from 'vue'

export function getDeviceOrientation(): 'portrait' | 'landscape' {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window.screen as any).orientation) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (window.screen as any).orientation.type.includes('landscape')
            ? 'landscape'
            : 'portrait'
    }

    // iOS/safari
    // get device orientation in degrees
    const orientation = window.orientation

    // determine whether device is in portrait or landscape
    if (orientation === 0 || orientation === 180) {
        return 'portrait'
    } else if (orientation === 90 || orientation === -90) {
        return 'landscape'
    } else {
        // check if screen orientation api is available
        if (screen.orientation && screen.orientation.type) {
            const orientation = screen.orientation.type
            // determine whether device is in portrait or landscape
            if (
                orientation === 'portrait-primary' ||
                orientation === 'portrait-secondary'
            ) {
                // console.log('Device is in portrait orientation. f test')
                return 'portrait'
            } else if (
                orientation === 'landscape-primary' ||
                orientation === 'landscape-secondary'
            ) {
                // console.log('Device is in landscape orientation. f test')
                return 'landscape'
            }
        }

        // check device orientation using window.matchMedia()
        const isPortrait = window.matchMedia('(orientation: portrait)').matches
        // determine whether device is in portrait or landscape
        if (isPortrait) {
            // console.log('Device is in portrait orientation.')
            return 'portrait'
        } else {
            // console.log('Device is in landscape orientation.')
            return 'landscape'
        }
    }
}

/**
 * Check if It is A mobile device or not
 * @returns bolean
 */
export function isMobile(): boolean {
    if (
        navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
    ) {
        return true
    }
    return false
}

/**
 * return browser language
 * @returns browser language
 */
export function getBrowserLanguage(): string {
    const lang = (navigator.language || 'zh-CN').split('-')[0]

    if (Lang.indexOf(lang) === -1) {
        return 'zh-CN'
    }

    return lang
}

/**
 * generate random number between min and max number
 * @param min smallest posible number
 * @param max maximum pasible number
 */
export function generateRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min
}

export function parseBool(strValue: string): boolean {
    return !!JSON.parse(String(strValue).toLowerCase())
}

/**
 * Abrevate number into short digits
 * @param n
 * @returns
 */
export function abbreviateNumber(n: number): string {
    if (n < 1e3) return n.toString()
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'

    return n.toString()
}

/**
 * Get Item In LocalStorage
 * @param _key
 * @returns
 */
export function getCookieValue(_key: string) {
    return window.sessionStorage.getItem(_key)
}
/**
 * Set Item in LocalStorage
 * @param _key
 * @param _value
 */
export function setCookieValue(_key: string, _value: string) {
    window.sessionStorage.setItem(_key, _value)
}

/**
 * Convert Date to String format yyyy/mm/dd hh:mm 24 hrs
 * @param date
 * @returns
 *
 */
export function formatDate(date: Date): string {
    const year = String(date.getFullYear())
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hour = String(date.getHours()).padStart(2, '0')
    const minute = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')

    const mDate = [year, month, day]
    const mTime = [hour, minute, seconds]

    return `${mDate.join('/')} ${mTime.join(':')} `
}

export function isIEBrowser() {
    const userAgent = navigator.userAgent
    return (
        userAgent.indexOf('MSIE') !== -1 ||
        userAgent.indexOf('Trident/') !== -1 ||
        userAgent.indexOf('IE') !== -1
    )
}

export function isClicked(e: MouseEvent, targetAlpha = 0): boolean {
    const rect = (e.currentTarget as HTMLElement).getBoundingClientRect()

    const x = e.clientX - rect.left
    const y = e.clientY - rect.top

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.height = rect.height
    canvas.width = rect.width

    const ev = window.getComputedStyle(e.currentTarget as HTMLElement)
    const src = ev
        .getPropertyValue('background-image')
        .replace(/url\(['"]?(.*?)['"]?\)/i, '$1')

    const img = document.createElement('img')
    img.src = src

    // e.currentTarget as HTMLImageElement

    // Draw the div content onto the canvas

    context?.drawImage(img, 0, 0, canvas.width, canvas.height)

    // Get the pixel data at the clicked position
    const pixelData = context?.getImageData(x, y, 1, 1).data

    // Extract the alpha value (opacity) from the pixel data
    if (pixelData) {
        const alpha = pixelData[3] / 255

        // console.log(alpha)
        // if (alpha < 0.5) {
        //     return false
        // }

        // console.log(alpha)

        if (targetAlpha !== 0) {
            return alpha >= targetAlpha
        }

        if (alpha < 0.3) {
            return false
        }
    }

    return true
}

/**
 * Check if its an Ios Browser
 * @returns boolean
 */
export function isIosMobile() {
    return /iPhone|iPad/.test(navigator.userAgent)
}

export function getDefaultChips(): ChipItem[] {
    const lang = getCookieValue(CookiesToken.LANG) ?? 'kor'

    if (lang !== 'kor') {
        return [
            { id: 1, value: 5 },
            { id: 2, value: 10 },
            { id: 3, value: 20 },
            { id: 4, value: 50 },
            { id: 5, value: 100 },
            { id: 6, value: 500 },
            { id: 7, value: 1000 },
            { id: 8, value: 10000 }
        ]
    } else {
        return [
            { id: 11, value: 10000 },
            { id: 12, value: 25000 },
            { id: 13, value: 50000 },
            { id: 14, value: 100000 },
            { id: 17, value: 500000 },
            { id: 18, value: 1000000 }
        ]
    }
}

export function getPageNumbers(
    currentPage: number,
    totalPageCount: number,
    displayedPageCount: number
): number[] {
    const pageNumbers: number[] = []

    // If total number of pages is less than or equal to the displayed page count, display all pages
    if (totalPageCount <= displayedPageCount) {
        for (let i = 1; i <= totalPageCount; i++) {
            pageNumbers.push(i)
        }
        return pageNumbers
    }

    // Calculate the start and end page numbers
    const startPage = Math.max(
        1,
        currentPage - Math.floor(displayedPageCount / 2)
    )
    const endPage = Math.min(totalPageCount, startPage + displayedPageCount - 1)

    // Add first page
    pageNumbers.push(1)

    // Add ellipsis if startPage > 2
    if (startPage > 2) {
        pageNumbers.push(-1) // Use a special value to represent ellipsis
    }

    // Add page numbers between startPage and endPage
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i)
    }

    // Add ellipsis if endPage < totalPageCount - 1
    if (endPage < totalPageCount - 1) {
        pageNumbers.push(-1) // Use a special value to represent ellipsis
    }

    // Add last page
    pageNumbers.push(totalPageCount)

    return pageNumbers
}

export function formatWithCommas(value: number): string {
    // Create a NumberFormat instance with the desired locale (like 'en-US' for English (United States))
    const formatter = new Intl.NumberFormat('en-US')

    // Format the number to add thousands separators
    return formatter.format(value)
}

export function langToNoticeLanguageValue(lang: string): string {
    if (lang === 'kor') return 'KRW'
    else if (lang === 'zh-CN' || lang === 'zh-HK') return 'CN'
    else if (lang === 'en') return 'EN'
    else if (lang === 'vn') return 'VND'
    else if (lang === 'ja') return 'JP'
    else if (lang === 'th') return 'TH'

    return 'nothing'
}

export function bonusTypeToStringValue(bonusType: string): string {
    if (bonusType === NiuBonusType.FLUSH) return 'niuniu.flush'
    else if (bonusType === NiuBonusType.FOUR_KIND) return 'niuniu.fourkind'
    else if (bonusType === NiuBonusType.FULL_HOUSE) return 'niuniu.fullhouse'
    else if (bonusType === NiuBonusType.ROYAL_FLUSH) return 'niuniu.royalflush'
    else if (bonusType === NiuBonusType.STRAIGHT) return 'niuniu.straight'
    else if (bonusType === NiuBonusType.STRAIGHT_FLUSH)
        return 'niuniu.straightflush'
    else if (bonusType === NiuBonusType.THREE_KIND) return 'niuniu.threekind'

    return ''
}
