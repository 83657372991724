
import {
    computed,
    defineComponent,
    onBeforeUnmount,
    onMounted,
    PropType,
    ref,
    TableData,
    watch
} from 'vue'
import RoadMap from '@/components/roadmap/index.vue'
import { RoadMapData } from './roadmap/datamodel/RoadMapData'
import { parseRoadMap } from './roadmap/utils'
import { useI18n } from 'vue-i18n'
import { ROADMAP_RECOMADATION } from '@/types/global'
import { store } from '@/store'

export default defineComponent({
    props: {
        activeDesk: {
            required: true,
            type: Number as PropType<number>
        },
        display: {
            required: false,
            type: Number as PropType<number>,
            default: -1
        },
        mData: {
            required: true,
            type: Object as PropType<TableData>
        }
    },
    components: {
        'road-map': RoadMap
    },
    setup(props) {
        let countDownInterval: boolean | number | any = false
        const currentCount = ref(-1)
        const mapdata = ref<RoadMapData>(parseRoadMap([]))
        const { t } = useI18n()

        const goodWayLabel = ref('')
        const blinkTimeOut = ref<number | boolean | any>(false)

        const map1key = ref(`map1-key${new Date().getTime()}`)
        const map2key = ref(`map2-key${new Date().getTime()}`)

        let errorAvatarImage = ''

        onMounted(() => {
            errorAvatarImage = require(`@/assets/imgs/pc/dealer1@2x.png`)
        })

        onBeforeUnmount(() => {
            stopCountDown()
            stopGoodWayBlink()
        })

        const onRoadMapDataParsed = (a: RoadMapData) => {
            mapdata.value = a

            if (mapdata.value.goodway.length > 0) {
                // console.log(mapdata.value.goodway)
                goodWayLabel.value = t(
                    `goodroad.${ROADMAP_RECOMADATION.toLabels(
                        mapdata.value.goodway[0].id,
                        gameType.value
                    )}`
                )

                blinkTimeOut.value = setTimeout(() => {
                    if (typeof blinkTimeOut.value === 'number') {
                        stopGoodWayBlink()
                    }
                }, 3000)
            } else {
                goodWayLabel.value = ''
            }
        }

        const stopGoodWayBlink = () => {
            if (typeof blinkTimeOut.value === 'number') {
                clearTimeout(blinkTimeOut.value)
                blinkTimeOut.value = false
            }
        }
        const startCountDown = () => {
            if (typeof countDownInterval !== 'number') {
                currentCount.value = props.mData.expand.daoJiShiBetTime

                countDownInterval = setInterval(() => {
                    if (currentCount.value < 0) {
                        stopCountDown()
                    } else {
                        currentCount.value -= 1
                    }
                }, 1000)
            }
        }

        const onLoadAvatarError = (e: any) => {
            e.currentTarget.src = errorAvatarImage
        }

        const stopCountDown = (): void => {
            if (typeof countDownInterval === 'number') {
                clearInterval(countDownInterval)
                countDownInterval = false
                currentCount.value = -1
            }
        }

        const redCount = computed(() => {
            if (mapdata.value) return mapdata.value.redCount
            return 0
        })

        const blueCount = computed(() => {
            if (mapdata.value) return mapdata.value.blueCount
            return 0
        })

        const greenCount = computed(() => {
            if (mapdata.value) return mapdata.value.tieCount
            return 0
        })

        const redPairCount = computed(() => {
            if (mapdata.value) return mapdata.value.redPairCount
            return 0
        })

        const bluePairCount = computed(() => {
            if (mapdata.value) return mapdata.value.bluePairCount
            return 0
        })

        const blueStat = computed(() => {
            if (mapdata.value.round === 0) return '0%'
            else
                return (
                    Math.floor((blueCount.value / mapdata.value.round) * 100) +
                    '%'
                )
        })

        const redStat = computed(() => {
            if (mapdata.value.round === 0) return '0%'
            return (
                Math.floor((redCount.value / mapdata.value.round) * 100) + '%'
            )
        })

        const greenStat = computed(() => {
            if (mapdata.value.round === 0) return '0%'
            return (
                Math.floor((greenCount.value / mapdata.value.round) * 100) + '%'
            )
        })

        const timerColor = computed(() => {
            if (currentCount.value === -1) {
                return 'wait'
            } else if (currentCount.value <= 10) {
                return 'red'
            } else {
                return ''
            }
        })

        const roundCount = computed(() => mapdata.value.round)

        const tableName = computed(() => {
            const { tableNo, gameHallType } = props.mData

            let _tableName = ''
            if (props.mData.gameTypeNo.toUpperCase() === 'G002')
                _tableName = `${t('desklist.baccarattable', {
                    tablenumber: tableNo
                })}`
            else
                _tableName = `${t('desklist.dragontigertable', {
                    tablenumber: tableNo
                })}`

            if (gameHallType === 2)
                _tableName = t('desklist.classic') + _tableName

            return _tableName
        })

        const redLabel = computed(() =>
            gameType.value === 1 ? t('roadmap.banker') : t('roadmap.dragon')
        )
        const blueLabel = computed(() =>
            gameType.value === 1 ? t('roadmap.player') : t('roadmap.tiger')
        )

        const gameType = computed(() => {
            return props.mData.gameTypeNo === 'G002' ? 1 : 2
        })

        const tableStatus = computed(() => {
            if (props.mData.status === 4) return t('desklist.shuffling')

            return t('desklist.opening')
        })

        const isMobile = computed(() => store.getters['isMobile'])

        watch(
            () => props.mData,
            (_v) => {
                if (_v.expand.daoJiShiBetTime > 0) {
                    startCountDown()
                } else {
                    stopCountDown()
                }
            },
            { immediate: true }
        )
        watch(
            () => props.display,
            () => {
                map1key.value = `map1-key${new Date().getTime()}`
                map2key.value = `map2-key${new Date().getTime()}`
            }
        )

        return {
            redCount,
            blueCount,
            greenCount,
            redPairCount,
            bluePairCount,
            blueStat,
            redStat,
            greenStat,
            roundCount,
            tableName,
            gameType,
            redLabel,
            blueLabel,
            currentCount,
            goodWayLabel,
            blinkTimeOut,
            timerColor,
            tableStatus,
            onRoadMapDataParsed,
            errorAvatarImage,
            map1key,
            map2key,
            isMobile,
            onLoadAvatarError,
            t
        }
    }
})
