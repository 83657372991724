
import { withdrawHistory } from '@/api'
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import { getPageNumbers } from '@/utils'
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref,
    TopupAndWithdrawRecord,
    watch
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default defineComponent({
    setup() {
        const store = useStore()
        const router = useRouter()
        const message = ref<string>('')
        const emitter = inject('emitter') as Emitter
        const { t } = useI18n()

        const lists = ref<TopupAndWithdrawRecord[]>([])

        const pagerInfo = ref({
            itemsCount: 0,
            pageNo: 0,
            pageSize: 0,
            totalPage: 0,
            totalSize: 0
        })

        const directPage = ref<number>()

        const pageNumbers = ref<number[]>([])

        let typingTimer: number | boolean | any = false
        const stopedTyping = ref(false)

        onMounted(() => {
            getHistory(1)
        })

        const getHistory = (_page = 1) => {
            if (_page < 1 || pagerInfo.value.pageNo === _page) return
            store.commit(AppMutation.SHOW_LOADING)
            const params = {
                memberId: memberid.value,
                token: token.value,
                page: _page,
                pageSize: 14
            }

            withdrawHistory(params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    if (response.status === 200) {
                        const {
                            items,
                            itemsCount,
                            pageNo,
                            pageSize,
                            totalPage,
                            totalSize
                        } = response.data.data.memberBankRecordList

                        if (items) lists.value = items

                        pagerInfo.value = {
                            itemsCount,
                            pageNo,
                            pageSize,
                            totalPage,
                            totalSize
                        }

                        pageNumbers.value = getPageNumbers(
                            pageNo,
                            totalPage,
                            2
                        ).filter(
                            (value, index, self) =>
                                self.indexOf(value) === index
                        )
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        t('prompt.networkerror')
                    )
                })
        }

        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'withdraw-history'
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const prevPage = () => {
            const toPage = pagerInfo.value.pageNo - 1

            if (toPage < 1) return
            getHistory(toPage)
        }

        const nextPage = () => {
            const toPage = pagerInfo.value.pageNo + 1
            if (toPage > pagerInfo.value.totalPage) return
            getHistory(toPage)
        }

        const getState = (state: number) => {
            if (state === 0) return t('withdrawhistory.pending')
            else if (state === 1) return t('withdrawhistory.processed')
            else if (state === -1) return t('withdrawhistory.rejected')
            else return ''
        }

        const getStateClass = (state: number) => {
            if (state === 0) return 'pending'
            else if (state === 1) return 'completed'
            else if (state === -1) return 'rejected'
            else return ''
        }

        const transactionType = (bankType: number) => {
            if (bankType === 1) return t('common.topup')
            else if (bankType === -1) return t('common.withdraw')
            else return ''
        }

        const getPageLabel = (n: number): string => {
            if (n < 1) {
                return '...'
            }

            return `${n}`
        }

        onBeforeUnmount(() => {
            resetTypingTimer()
        })

        const resetTypingTimer = () => {
            if (typeof typingTimer === 'number') {
                clearTimeout(typingTimer)
                typingTimer = false
            }
        }

        const onInputChange = () => {
            resetTypingTimer()

            typingTimer = setTimeout(() => {
                stopedTyping.value = true
                // got to page

                if (!isNaN(parseFloat(String(directPage.value)))) {
                    getHistory(directPage.value)
                }
            }, 1000)
        }

        // const coin = computed(() => store.getters['coin'])
        const token = computed(() => store.getters['token'])
        const memberid = computed(() => store.getters['memberid'])

        watch(
            () => directPage.value,
            () => {
                stopedTyping.value = false
            }
        )

        return {
            lists,
            message,
            pagerInfo,
            pageNumbers,
            directPage,
            onInputChange,
            nextPage,
            prevPage,
            getHistory,
            getPageLabel,
            getState,
            getStateClass,
            transactionType,
            close,
            t
        }
    }
})
