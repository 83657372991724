<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28.253"
        height="23.813"
        viewBox="0 0 28.253 23.813"
    >
        <path
            id="Path_106"
            data-name="Path 106"
            d="M26.879,11.551A10.747,10.747,0,0,0,12.941,26.028C13.5,27.22,10,22.9,10,22.9m8.24,8.408A10.752,10.752,0,0,0,32.055,16.089c-.5-.853,3.381,2.529,3.381,2.529"
            transform="translate(-8.593 -9.517)"
            fill="none"
            stroke="#dccb96"
            stroke-linecap="round"
            stroke-width="2"
            fill-rule="evenodd"
        />
    </svg>
</template>