import router, { ROUTES } from './'
import { store } from '@/store'

const whiteList = ['/login']

router.beforeEach(async (to, from, next) => {
    const token = store.getters['token']
    const user = store.getters['user']

    if (token && user) {
        if (to.path === whiteList[0]) {
            next({ path: '/' })
        } else {
            next()
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next(whiteList[0])
        }
    }
})
