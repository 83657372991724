export enum CookiesToken {
    USER_NAME = 'kkw001',
    CUSTOM_CHIP = 'kkw002',
    SOUND = 'kkw003',
    TOKEN = 'kkw004',
    SELECTED_CHIP = 'kkw005',
    POINT = 'kkw006',
    LOBY_NOTICE = 'kkw008',
    DESK_NOTICE = 'kkw009',
    LIMIT = 'kkw010',
    CHIPS = 'kkw011',
    LANG = 'kkw012',
    VIDEO = 'kkw013',
    DESK_OPTIONS = 'kkw014',
    MEMBER = 'kkw015',
    FREE_MEMBER = 'kkw016',
    COMMAND_TYPE = 'kkw017',
    CURRENT_CHIP = 'kkw018',
    USER_INFO = 'kkw019',
    DESK = 'kkw020',
    CLIENT_TIME_COUNT = 'kkw021',
    VISITOR_TABLE = 'kkw022',
    CHIPS_DEFAULT = 'km01',
    CHIPS_OPTION = 'km02',
    MUSIC_VOLUME = 'km03',
    MUSIC_CAN_PLAY = 'km04',
    SOUND_VOLUME = 'km05',
    BET_LAYOUT = 'km06',
    GAME_TYPES = 'km07',
    LIMITS = 'km08',
    MULTI_TABLES = 'km09',
    AVATAR = 'km10',
    GOOD_WAY = 'km11',
    MEMBER_INFO = 'km12',
    MULTI_TABLE_VIDEO = 'km13',
    SHOW_TABLE_MORE_INFO = 'km14',
    HAS_ANCHOR_TABLES = 'azp_1',
    HAS_CLASSIC_TABLES = 'azp_2',
    EVEN_MULTIPLIER = 'azp_3',
    DOUBLE_MULTIPLIER = 'azp_4',
    SUPER_MULTIPLIER = 'azp_5',
    CASH_OUT_INFO = 'azp_6',
    CURRENCY = 'azp_7'
}
