import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "10.828",
  height: "6.828",
  viewBox: "0 0 10.828 6.828"
}
const _hoisted_2 = {
  id: "Component_15_58",
  "data-name": "Component 15 – 58",
  transform: "translate(9.414 1.414) rotate(90)"
}
const _hoisted_3 = ["stroke"]
const _hoisted_4 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("g", _hoisted_2, [
      _createElementVNode("line", {
        id: "Line_46",
        "data-name": "Line 46",
        x2: "4",
        y2: "4",
        fill: "none",
        stroke: _ctx.stroke,
        "stroke-linecap": "round",
        "stroke-width": "2"
      }, null, 8, _hoisted_3),
      _createElementVNode("line", {
        id: "Line_47",
        "data-name": "Line 47",
        y1: "4",
        x2: "4",
        transform: "translate(0 4)",
        fill: "none",
        stroke: _ctx.stroke,
        "stroke-linecap": "round",
        "stroke-width": "2"
      }, null, 8, _hoisted_4)
    ])
  ]))
}