import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Loby from '@/views/Loby.vue'

export enum ROUTES {
    LOGIN = 'login',
    LOBY = 'loby',
    GAME = 'game',
    MULTI = 'multi-table-betting',
    NIU_NIU = 'nui-nui',
    NIU_NIU_CLASSIC = 'nui-nui-clasic',
    DEFAULT_GAME = 'default-game',
    CLASSIC_GAME = 'classic-game'
}

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: ROUTES.LOBY,
        component: Loby,

        props: (_route) => ({
            hall: parseInt(String(_route.query.hall ?? 0))
        })
    },
    {
        path: `/${ROUTES.GAME}`,
        name: ROUTES.GAME,
        props: (_route) => ({
            desk: parseInt(String(_route.query.desk ?? -1)),
            hall: parseInt(String(_route.query.hall ?? 0))
        }),
        // component: () => import('@/views/Game.vue')
        component: () => import('@/views/game/Index.vue'),
        children: [
            {
                path: '',
                name: ROUTES.DEFAULT_GAME,
                component: () => import('@/views/game/Default.vue')
            },
            {
                path: '/1',
                name: ROUTES.NIU_NIU,
                component: () => import('@/views/game/Nuinui.vue')
            },
            {
                path: '/2',
                name: ROUTES.CLASSIC_GAME,
                component: () => import('@/views/game/Classic.vue')
            },
            {
                path: '/3',
                name: ROUTES.NIU_NIU_CLASSIC,
                component: () => import('@/views/game/NuinuiClasic.vue')
            }
        ]
    },
    {
        path: `/${ROUTES.LOGIN}`,
        name: ROUTES.LOGIN,
        component: () => import('@/views/Login.vue')
    },
    {
        path: `/${ROUTES.MULTI}`,
        name: ROUTES.MULTI,
        component: () => import('@/views/MultiTableBetting.vue'),
        props: (_route) => ({
            hall: parseInt(String(_route.query.hall ?? 0))
        })
    }
    // {
    //     path: `/${ROUTES.NIU_NIU}`,
    //     name: ROUTES.NIU_NIU,
    //     component: () => import('@/views/Game2.vue'),
    //     props: (_route) => ({
    //         desk: parseInt(String(_route.query.desk ?? -1)),
    //         hall: parseInt(String(_route.query.hall ?? 1))
    //     })
    // }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
