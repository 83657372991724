
import { ROUTES } from '@/router'
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import {
    defineComponent,
    Emitter,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    ref
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default defineComponent({
    setup() {
        const store = useStore()
        const router = useRouter()
        const message = ref<string>('')
        const emitter = inject('emitter') as Emitter
        const { t } = useI18n()

        type CallbackFunction = () => void

        let callback: CallbackFunction | undefined

        onBeforeMount(() => {
            emitter.on(EventBuss.CONFIRM_CANCEL_CONTENT, handleHint)
        })

        onBeforeUnmount(() => {
            emitter.off(EventBuss.CONFIRM_CANCEL_CONTENT, handleHint)
        })

        const handleHint = (_message: {
            message: string
            callback: CallbackFunction
        }) => {
            message.value = _message.message
            callback = _message.callback
        }

        const callBackFunc = () => {
            // emitter.emit(EventBuss.SOCKET_CLOSE)
            // store.commit(AppMutation.CLEAR_ALL)
            // router.push({ name: ROUTES.LOGIN })
            // const _evt: MessageBoxEvent = {
            //     type: MessageBoxActionType.close,
            //     name: 'confirm-cancel-message'
            // }
            // emitter.emit(EventBuss.DIALOG, _evt)

            if (callback) callback()
            close()
        }

        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'confirm-cancel-message'
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        return { callBackFunc, message, close, t }
    }
})
