import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "22.506",
  height: "28.124",
  viewBox: "0 0 22.506 28.124"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      id: "Path_6",
      "data-name": "Path 6",
      d: "M27.384,33.125H9.123A2.127,2.127,0,0,1,7,30.994V12.519L14.491,5H27.384a2.126,2.126,0,0,1,2.122,2.13V30.994A2.126,2.126,0,0,1,27.384,33.125Zm.726-24.57a2.021,2.021,0,0,0-2.017-2.024H15.513l-.461.463v6.078H9l-.6.6v15.9a2.021,2.021,0,0,0,2.017,2.025h15.68a2.021,2.021,0,0,0,2.017-2.025ZM10.4,17.082H26.959V20.54H10.4Zm13.589,8.049H10.4V21.666H23.986Zm-2.548,4.584H10.4V26.258h11.04Z",
      transform: "translate(-7 -5.001)",
      fill: _ctx.fillColor,
      "fill-rule": "evenodd"
    }, null, 8, _hoisted_2)
  ]))
}