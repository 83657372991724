<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10.828"
        height="6.828"
        viewBox="0 0 10.828 6.828"
    >
        <g
            id="Component_15_41"
            data-name="Component 15 – 41"
            transform="translate(9.414 1.414) rotate(90)"
        >
            <line
                id="Line_46"
                data-name="Line 46"
                x2="4"
                y2="4"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-width="2"
            />
            <line
                id="Line_47"
                data-name="Line 47"
                y1="4"
                x2="4"
                transform="translate(0 4)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-width="2"
            />
        </g>
    </svg>
</template>