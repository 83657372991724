import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-447a633a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "good-way-setting-root" }
const _hoisted_2 = { class: "pc-good-way-setting-group" }
const _hoisted_3 = { class: "pc-good-way-setting-title-wrap" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "pc-good-way-setting-list-wrap" }
const _hoisted_6 = { class: "pc-good-way-setting-list" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = { class: "label" }
const _hoisted_10 = { class: "pc-good-way-setting-button-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('setting.goodroadsetting')), 1),
        _createElementVNode("div", {
          class: "close-btn btn",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"]))
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.baccaratWays, (a, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["pc-good-way-setting-list-item", {
                            active: _ctx.isSelected(a)
                        }]),
              key: i,
              onClick: ($event: any) => (_ctx.onSelectGoodway(a))
            }, [
              _createElementVNode("img", {
                src: a.src,
                alt: a.name
              }, null, 8, _hoisted_8),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.t(`goodroad.${a.name}`)), 1)
            ], 10, _hoisted_7))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", {
          class: "btn-cancel btn",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
        }, _toDisplayString(_ctx.t('common.cancel')), 1),
        _createElementVNode("div", {
          class: "btn-confirm btn",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args)))
        }, _toDisplayString(_ctx.t('common.confirm')), 1)
      ])
    ])
  ]))
}