
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType } from '@/types/MessageBox'
import { computed, defineComponent, Emitter, inject, onMounted, ref } from 'vue'

export default defineComponent({
    setup() {
        const emitter = inject('emitter') as Emitter
        const store = useStore()
        const layoutId = ref(0)
        const layoutWhiteList = ref([0])

        onMounted(() => {
            console.log(betLayout.value)
            layoutId.value = betLayout.value
        })
        const close = () => {
            const evt = {
                type: MessageBoxActionType.close,
                name: 'switch-play'
            }
            emitter.emit(EventBuss.DIALOG, evt)
        }

        const setLayoutId = (_layoutId: number) => {
            if (
                layoutId.value !== _layoutId &&
                layoutWhiteList.value.indexOf(_layoutId) !== -1
            ) {
                layoutId.value = _layoutId
            }
        }

        const confirm = () => {
            store.commit(AppMutation.SET_BET_LAYOUT, layoutId.value)
            close()
        }

        const betLayout = computed((): number => store.getters['betLayout'])

        return { layoutId, layoutWhiteList, setLayoutId, close, confirm }
    }
})
