
import { defineComponent, PropType } from 'vue'

export default defineComponent({
    props: {
        fill: {
            type: String as PropType<string>,
            required: false,
            default: () => '#dccb96'
        }
    },
    setup() {
        //
    }
})
