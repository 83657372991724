import { Assets, ref } from 'vue'

export function useAssetPreload() {
    //#region Mobile Assets
    const imgSrc: Assets[] = [
        {
            name: 'bg_public_chip_selected',
            src: require('@/assets/imgs/mobile/bg_public_chip_selected.png'),
            type: 'image'
        },
        {
            name: 'bg_table1_1',
            src: require('@/assets/imgs/mobile/bg_table1_1.png'),
            type: 'image'
        },
        {
            name: 'bg_table_loading',
            src: require('@/assets/imgs/mobile/bg_table_loading.png'),
            type: 'image'
        },
        {
            name: 'but_pop_title@2x',
            src: require('@/assets/imgs/mobile/but_pop_title@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_01',
            src: require('@/assets/imgs/mobile/chip_public_01.png'),
            type: 'image'
        },
        {
            name: 'chip_public_02',
            src: require('@/assets/imgs/mobile/chip_public_02.png'),
            type: 'image'
        },
        {
            name: 'chip_public_03',
            src: require('@/assets/imgs/mobile/chip_public_03.png'),
            type: 'image'
        },
        {
            name: 'chip_public_04',
            src: require('@/assets/imgs/mobile/chip_public_04.png'),
            type: 'image'
        },
        {
            name: 'chip_public_05',
            src: require('@/assets/imgs/mobile/chip_public_05.png'),
            type: 'image'
        },
        {
            name: 'chip_public_06',
            src: require('@/assets/imgs/mobile/chip_public_06.png'),
            type: 'image'
        },
        {
            name: 'chip_public_07',
            src: require('@/assets/imgs/mobile/chip_public_07.png'),
            type: 'image'
        },
        {
            name: 'chip_public_08',
            src: require('@/assets/imgs/mobile/chip_public_08.png'),
            type: 'image'
        },
        {
            name: 'chip_public_09',
            src: require('@/assets/imgs/mobile/chip_public_09.png'),
            type: 'image'
        },
        {
            name: 'chip_public_10',
            src: require('@/assets/imgs/mobile/chip_public_10.png'),
            type: 'image'
        },
        {
            name: 'chip_public_11',
            src: require('@/assets/imgs/mobile/chip_public_11.png'),
            type: 'image'
        },
        {
            name: 'chip_public_12',
            src: require('@/assets/imgs/mobile/chip_public_12.png'),
            type: 'image'
        },
        {
            name: 'chip_public_13',
            src: require('@/assets/imgs/mobile/chip_public_13.png'),
            type: 'image'
        },
        {
            name: 'chip_public_14',
            src: require('@/assets/imgs/mobile/chip_public_14.png'),
            type: 'image'
        },
        {
            name: 'chip_public_15',
            src: require('@/assets/imgs/mobile/chip_public_15.png'),
            type: 'image'
        },
        {
            name: 'chip_public_16',
            src: require('@/assets/imgs/mobile/chip_public_16.png'),
            type: 'image'
        },
        {
            name: 'chip_public_17',
            src: require('@/assets/imgs/mobile/chip_public_17.png'),
            type: 'image'
        },
        {
            name: 'chip_public_18',
            src: require('@/assets/imgs/mobile/chip_public_18.png'),
            type: 'image'
        },
        {
            name: 'chip_public_19',
            src: require('@/assets/imgs/mobile/chip_public_19.png'),
            type: 'image'
        },
        {
            name: 'font_table_bet_b_1',
            src: require('@/assets/imgs/mobile/font_table_bet_b_1.png'),
            type: 'image'
        },
        {
            name: 'font_table_bet_b_2',
            src: require('@/assets/imgs/mobile/font_table_bet_b_2.png'),
            type: 'image'
        },
        {
            name: 'font_table_bet_b_pair',
            src: require('@/assets/imgs/mobile/font_table_bet_b_pair.png'),
            type: 'image'
        },
        {
            name: 'font_table_bet_dragon',
            src: require('@/assets/imgs/mobile/font_table_bet_dragon.png'),
            type: 'image'
        },
        {
            name: 'font_table_bet_p',
            src: require('@/assets/imgs/mobile/font_table_bet_p.png'),
            type: 'image'
        },
        {
            name: 'font_table_bet_p_pair',
            src: require('@/assets/imgs/mobile/font_table_bet_p_pair.png'),
            type: 'image'
        },
        {
            name: 'font_table_bet_tie_1',
            src: require('@/assets/imgs/mobile/font_table_bet_tie_1.png'),
            type: 'image'
        },
        {
            name: 'font_table_bet_tiger',
            src: require('@/assets/imgs/mobile/font_table_bet_tiger.png'),
            type: 'image'
        },
        {
            name: 'icon_public_settingchips',
            src: require('@/assets/imgs/mobile/icon_public_settingchips.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_bac_b',
            src: require('@/assets/imgs/mobile/m_bg_bet_bac_b.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_bac_b_win',
            src: require('@/assets/imgs/mobile/m_bg_bet_bac_b_win.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_bac_bpair',
            src: require('@/assets/imgs/mobile/m_bg_bet_bac_bpair.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_bac_bpair_win',
            src: require('@/assets/imgs/mobile/m_bg_bet_bac_bpair_win.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_bac_p',
            src: require('@/assets/imgs/mobile/m_bg_bet_bac_p.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_bac_p_win',
            src: require('@/assets/imgs/mobile/m_bg_bet_bac_p_win.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_bac_ppair',
            src: require('@/assets/imgs/mobile/m_bg_bet_bac_ppair.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_bac_ppair_win',
            src: require('@/assets/imgs/mobile/m_bg_bet_bac_ppair_win.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_bac_tie',
            src: require('@/assets/imgs/mobile/m_bg_bet_bac_tie.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_bac_tie_win',
            src: require('@/assets/imgs/mobile/m_bg_bet_bac_tie_win.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_longhu_long',
            src: require('@/assets/imgs/mobile/m_bg_bet_longhu_long.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_longhu_long_win',
            src: require('@/assets/imgs/mobile/m_bg_bet_longhu_long_win.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_longhu_tie',
            src: require('@/assets/imgs/mobile/m_bg_bet_longhu_tie.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_longhu_tier_win',
            src: require('@/assets/imgs/mobile/m_bg_bet_longhu_tier_win.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_longhu_tiger',
            src: require('@/assets/imgs/mobile/m_bg_bet_longhu_tiger.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_longhu_tiger_win',
            src: require('@/assets/imgs/mobile/m_bg_bet_longhu_tiger_win.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_niuniu_set1',
            src: require('@/assets/imgs/mobile/m_bg_bet_niuniu_set1.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_niuniu_set1_chaobei',
            src: require('@/assets/imgs/mobile/m_bg_bet_niuniu_set1_chaobei.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_niuniu_set1_chaobei_win',
            src: require('@/assets/imgs/mobile/m_bg_bet_niuniu_set1_chaobei_win.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_niuniu_set1_fanbei',
            src: require('@/assets/imgs/mobile/m_bg_bet_niuniu_set1_fanbei.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_niuniu_set1_fanbei_win',
            src: require('@/assets/imgs/mobile/m_bg_bet_niuniu_set1_fanbei_win.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_niuniu_set1_pingbei',
            src: require('@/assets/imgs/mobile/m_bg_bet_niuniu_set1_pingbei.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_niuniu_set1_pingbei_win',
            src: require('@/assets/imgs/mobile/m_bg_bet_niuniu_set1_pingbei_win.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_niuniu_set2',
            src: require('@/assets/imgs/mobile/m_bg_bet_niuniu_set2.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_niuniu_set2_chaobei',
            src: require('@/assets/imgs/mobile/m_bg_bet_niuniu_set2_chaobei.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_niuniu_set2_chaobei_win',
            src: require('@/assets/imgs/mobile/m_bg_bet_niuniu_set2_chaobei_win.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_niuniu_set2_fanbei',
            src: require('@/assets/imgs/mobile/m_bg_bet_niuniu_set2_fanbei.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_niuniu_set2_fanbei_win',
            src: require('@/assets/imgs/mobile/m_bg_bet_niuniu_set2_fanbei_win.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_niuniu_set2_pingbei',
            src: require('@/assets/imgs/mobile/m_bg_bet_niuniu_set2_pingbei.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_niuniu_set2_pingbei_win',
            src: require('@/assets/imgs/mobile/m_bg_bet_niuniu_set2_pingbei_win.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_niuniu_set3',
            src: require('@/assets/imgs/mobile/m_bg_bet_niuniu_set3.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_niuniu_set3_chaobei',
            src: require('@/assets/imgs/mobile/m_bg_bet_niuniu_set3_chaobei.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_niuniu_set3_chaobei_win',
            src: require('@/assets/imgs/mobile/m_bg_bet_niuniu_set3_chaobei_win.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_niuniu_set3_fanbei',
            src: require('@/assets/imgs/mobile/m_bg_bet_niuniu_set3_fanbei.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_niuniu_set3_fanbei_win',
            src: require('@/assets/imgs/mobile/m_bg_bet_niuniu_set3_fanbei_win.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_niuniu_set3_pingbei',
            src: require('@/assets/imgs/mobile/m_bg_bet_niuniu_set3_pingbei.png'),
            type: 'image'
        },
        {
            name: 'm_bg_bet_niuniu_set3_pingbei_win',
            src: require('@/assets/imgs/mobile/m_bg_bet_niuniu_set3_pingbei_win.png'),
            type: 'image'
        },
        {
            name: 'switch-tab-active-bg',
            src: require('@/assets/imgs/mobile/switch-tab-active-bg.png'),
            type: 'image'
        },
        {
            name: 'bg_table_bac_classic_top',
            src: require('@/assets/imgs/mobile/bg_table_bac_classic_top.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_classic_bpair',
            src: require('@/assets/imgs/mobile/bg_bet_bac_classic_bpair.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_classic_ppair_win',
            src: require('@/assets/imgs/mobile/bg_bet_bac_classic_ppair_win.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_classic_tie',
            src: require('@/assets/imgs/mobile/bg_bet_bac_classic_tie.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_classic_p',
            src: require('@/assets/imgs/mobile/bg_bet_bac_classic_p.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_classic_b_win',
            src: require('@/assets/imgs/mobile/bg_bet_bac_classic_b_win.png'),
            type: 'image'
        },
        {
            name: 'bg_table_bac_classic_chips',
            src: require('@/assets/imgs/mobile/bg_table_bac_classic_chips.png'),
            type: 'image'
        },
        {
            name: 'flag_china',
            src: require('@/assets/imgs/mobile/flag_china.png'),
            type: 'image'
        },
        {
            name: 'flag_english',
            src: require('@/assets/imgs/mobile/flag_english.png'),
            type: 'image'
        },
        {
            name: 'flag_taiwan',
            src: require('@/assets/imgs/mobile/flag_taiwan.jpg'),
            type: 'image'
        },
        {
            name: 'flag_southkorea',
            src: require('@/assets/imgs/mobile/flag_southkorea.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p1_label',
            src: require('@/assets/imgs/mobile/nui_classic_p1_label.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p1_1',
            src: require('@/assets/imgs/mobile/nui_classic_p1_1.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p1_2',
            src: require('@/assets/imgs/mobile/nui_classic_p1_2.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p1_s',
            src: require('@/assets/imgs/mobile/nui_classic_p1_s.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p2_label',
            src: require('@/assets/imgs/mobile/nui_classic_p2_label.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p2_2',
            src: require('@/assets/imgs/mobile/nui_classic_p2_2.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p2_1',
            src: require('@/assets/imgs/mobile/nui_classic_p2_1.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p2_s',
            src: require('@/assets/imgs/mobile/nui_classic_p2_s.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p3_label',
            src: require('@/assets/imgs/mobile/nui_classic_p3_label.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p3_1',
            src: require('@/assets/imgs/mobile/nui_classic_p3_1.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p3_2',
            src: require('@/assets/imgs/mobile/nui_classic_p3_2.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p3_s',
            src: require('@/assets/imgs/mobile/nui_classic_p3_s.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p1_s_win',
            src: require('@/assets/imgs/mobile/nui_classic_p1_s_win.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p1_2_win',
            src: require('@/assets/imgs/mobile/nui_classic_p1_2_win.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p1_1_win',
            src: require('@/assets/imgs/mobile/nui_classic_p1_1_win.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p2_s_win',
            src: require('@/assets/imgs/mobile/nui_classic_p2_s_win.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p2_2_win',
            src: require('@/assets/imgs/mobile/nui_classic_p2_2_win.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p2_1_win',
            src: require('@/assets/imgs/mobile/nui_classic_p2_1_win.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p3_s_win',
            src: require('@/assets/imgs/mobile/nui_classic_p3_s_win.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p3_2_win',
            src: require('@/assets/imgs/mobile/nui_classic_p3_2_win.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p3_1_win',
            src: require('@/assets/imgs/mobile/nui_classic_p3_1_win.png'),
            type: 'image'
        },
        {
            name: 'bg_table_loading_nui_classic',
            src: require('@/assets/imgs/mobile/bg_table_loading_nui_classic.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set1_jakpot',
            src: require('@/assets/imgs/mobile/bg_bet_niuniu_set1_jakpot.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set2_jakpot',
            src: require('@/assets/imgs/mobile/bg_bet_niuniu_set2_jakpot.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set3_jakpot',
            src: require('@/assets/imgs/mobile/bg_bet_niuniu_set3_jakpot.png'),
            type: 'image'
        }
    ]
    //#endregion

    //#region  PC Assets
    const pcAssets: Assets[] = [
        {
            name: 'a1@2x',
            src: require('@/assets/imgs/pc/a1@2x.png'),
            type: 'image'
        },
        {
            name: 'a1_label@2x',
            src: require('@/assets/imgs/pc/a1_label@2x.png'),
            type: 'image'
        },
        {
            name: 'a2@2x',
            src: require('@/assets/imgs/pc/a2@2x.png'),
            type: 'image'
        },
        {
            name: 'a2_label@2x',
            src: require('@/assets/imgs/pc/a2_label@2x.png'),
            type: 'image'
        },
        {
            name: 'a3@2x',
            src: require('@/assets/imgs/pc/a3@2x.png'),
            type: 'image'
        },
        {
            name: 'a3_label@2x',
            src: require('@/assets/imgs/pc/a3_label@2x.png'),
            type: 'image'
        },
        {
            name: 'any_pair_label@2x',
            src: require('@/assets/imgs/pc/any_pair_label@2x.png'),
            type: 'image'
        },
        {
            name: 'ar4_1@2x',
            src: require('@/assets/imgs/pc/ar4_1@2x.png'),
            type: 'image'
        },
        {
            name: 'ar4_2@2x',
            src: require('@/assets/imgs/pc/ar4_2@2x.png'),
            type: 'image'
        },
        {
            name: 'ar4_2_label@2x',
            src: require('@/assets/imgs/pc/ar4_2_label@2x.png'),
            type: 'image'
        },
        {
            name: 'ar4_3@2x',
            src: require('@/assets/imgs/pc/ar4_3@2x.png'),
            type: 'image'
        },
        {
            name: 'ar4_4@2x',
            src: require('@/assets/imgs/pc/ar4_4@2x.png'),
            type: 'image'
        },
        {
            name: 'avatar-frame1@2x',
            src: require('@/assets/imgs/pc/avatar-frame1@2x.png'),
            type: 'image'
        },
        {
            name: 'avatar10@2x',
            src: require('@/assets/imgs/pc/avatar10@2x.png'),
            type: 'image'
        },
        {
            name: 'avatar11@2x',
            src: require('@/assets/imgs/pc/avatar11@2x.png'),
            type: 'image'
        },
        {
            name: 'avatar12@2x',
            src: require('@/assets/imgs/pc/avatar12@2x.png'),
            type: 'image'
        },
        {
            name: 'avatar13@2x',
            src: require('@/assets/imgs/pc/avatar13@2x.png'),
            type: 'image'
        },
        {
            name: 'avatar14@2x',
            src: require('@/assets/imgs/pc/avatar14@2x.png'),
            type: 'image'
        },
        {
            name: 'avatar15@2x',
            src: require('@/assets/imgs/pc/avatar15@2x.png'),
            type: 'image'
        },
        {
            name: 'avatar16@2x',
            src: require('@/assets/imgs/pc/avatar16@2x.png'),
            type: 'image'
        },
        {
            name: 'avatar17@2x',
            src: require('@/assets/imgs/pc/avatar17@2x.png'),
            type: 'image'
        },
        {
            name: 'avatar18@2x',
            src: require('@/assets/imgs/pc/avatar18@2x.png'),
            type: 'image'
        },
        {
            name: 'avatar19@2x',
            src: require('@/assets/imgs/pc/avatar19@2x.png'),
            type: 'image'
        },
        {
            name: 'avatar1@2x',
            src: require('@/assets/imgs/pc/avatar1@2x.png'),
            type: 'image'
        },
        {
            name: 'avatar20@2x',
            src: require('@/assets/imgs/pc/avatar20@2x.png'),
            type: 'image'
        },
        {
            name: 'avatar21@2x',
            src: require('@/assets/imgs/pc/avatar21@2x.png'),
            type: 'image'
        },
        {
            name: 'avatar2@2x',
            src: require('@/assets/imgs/pc/avatar2@2x.png'),
            type: 'image'
        },
        {
            name: 'avatar3@2x',
            src: require('@/assets/imgs/pc/avatar3@2x.png'),
            type: 'image'
        },
        {
            name: 'avatar4@2x',
            src: require('@/assets/imgs/pc/avatar4@2x.png'),
            type: 'image'
        },
        {
            name: 'avatar5@2x',
            src: require('@/assets/imgs/pc/avatar5@2x.png'),
            type: 'image'
        },
        {
            name: 'avatar6@2x',
            src: require('@/assets/imgs/pc/avatar6@2x.png'),
            type: 'image'
        },
        {
            name: 'avatar7@2x',
            src: require('@/assets/imgs/pc/avatar7@2x.png'),
            type: 'image'
        },
        {
            name: 'avatar8@2x',
            src: require('@/assets/imgs/pc/avatar8@2x.png'),
            type: 'image'
        },
        {
            name: 'avatar9@2x',
            src: require('@/assets/imgs/pc/avatar9@2x.png'),
            type: 'image'
        },
        {
            name: 'b1@2x',
            src: require('@/assets/imgs/pc/b1@2x.png'),
            type: 'image'
        },
        {
            name: 'b1_baccarat@2x',
            src: require('@/assets/imgs/pc/b1_baccarat@2x.png'),
            type: 'image'
        },
        {
            name: 'b1_baccarat_label@2x',
            src: require('@/assets/imgs/pc/b1_baccarat_label@2x.png'),
            type: 'image'
        },
        {
            name: 'b1_label@2x',
            src: require('@/assets/imgs/pc/b1_label@2x.png'),
            type: 'image'
        },
        {
            name: 'b2@2x',
            src: require('@/assets/imgs/pc/b2@2x.png'),
            type: 'image'
        },
        {
            name: 'b2_baccarat@2x',
            src: require('@/assets/imgs/pc/b2_baccarat@2x.png'),
            type: 'image'
        },
        {
            name: 'b2_label@2x',
            src: require('@/assets/imgs/pc/b2_label@2x.png'),
            type: 'image'
        },
        {
            name: 'b3@2x',
            src: require('@/assets/imgs/pc/b3@2x.png'),
            type: 'image'
        },
        {
            name: 'b3_label@2x',
            src: require('@/assets/imgs/pc/b3_label@2x.png'),
            type: 'image'
        },
        {
            name: 'b_tie_1_8@2x',
            src: require('@/assets/imgs/pc/b_tie_1_8@2x.png'),
            type: 'image'
        },
        {
            name: 'b_tie_and_super@2x',
            src: require('@/assets/imgs/pc/b_tie_and_super@2x.png'),
            type: 'image'
        },
        {
            name: 'bet-layout-setting@2x',
            src: require('@/assets/imgs/pc/bet-layout-setting@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_b',
            src: require('@/assets/imgs/pc/bg_bet_bac_b.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_b1',
            src: require('@/assets/imgs/pc/bg_bet_bac_b1.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_bpair',
            src: require('@/assets/imgs/pc/bg_bet_bac_bpair.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_bpair1',
            src: require('@/assets/imgs/pc/bg_bet_bac_bpair1.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_p',
            src: require('@/assets/imgs/pc/bg_bet_bac_p.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_p1',
            src: require('@/assets/imgs/pc/bg_bet_bac_p1.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_ppair',
            src: require('@/assets/imgs/pc/bg_bet_bac_ppair.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_ppair1',
            src: require('@/assets/imgs/pc/bg_bet_bac_ppair1.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_tie',
            src: require('@/assets/imgs/pc/bg_bet_bac_tie.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_tie1',
            src: require('@/assets/imgs/pc/bg_bet_bac_tie1.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_longhu_dragon',
            src: require('@/assets/imgs/pc/bg_bet_longhu_dragon.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_longhu_dragon_win',
            src: require('@/assets/imgs/pc/bg_bet_longhu_dragon_win.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_longhu_tie',
            src: require('@/assets/imgs/pc/bg_bet_longhu_tie.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_longhu_tie_win',
            src: require('@/assets/imgs/pc/bg_bet_longhu_tie_win.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_longhu_tiger',
            src: require('@/assets/imgs/pc/bg_bet_longhu_tiger.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_longhu_tiger_win',
            src: require('@/assets/imgs/pc/bg_bet_longhu_tiger_win.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set1',
            src: require('@/assets/imgs/pc/bg_bet_niuniu_set1.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set1_chaobei',
            src: require('@/assets/imgs/pc/bg_bet_niuniu_set1_chaobei.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set1_chaobei_win',
            src: require('@/assets/imgs/pc/bg_bet_niuniu_set1_chaobei_win.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set1_fangbei',
            src: require('@/assets/imgs/pc/bg_bet_niuniu_set1_fangbei.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set1_fangbei_win',
            src: require('@/assets/imgs/pc/bg_bet_niuniu_set1_fangbei_win.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set1_pinbei',
            src: require('@/assets/imgs/pc/bg_bet_niuniu_set1_pinbei.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set1_pinbei_win',
            src: require('@/assets/imgs/pc/bg_bet_niuniu_set1_pinbei_win.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set2',
            src: require('@/assets/imgs/pc/bg_bet_niuniu_set2.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set2_chaobei',
            src: require('@/assets/imgs/pc/bg_bet_niuniu_set2_chaobei.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set2_chaobei_win',
            src: require('@/assets/imgs/pc/bg_bet_niuniu_set2_chaobei_win.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set2_fangbei',
            src: require('@/assets/imgs/pc/bg_bet_niuniu_set2_fangbei.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set2_fangbei_win',
            src: require('@/assets/imgs/pc/bg_bet_niuniu_set2_fangbei_win.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set2_pinbei',
            src: require('@/assets/imgs/pc/bg_bet_niuniu_set2_pinbei.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set2_pinbei_win',
            src: require('@/assets/imgs/pc/bg_bet_niuniu_set2_pinbei_win.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set3',
            src: require('@/assets/imgs/pc/bg_bet_niuniu_set3.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set3_chaobei',
            src: require('@/assets/imgs/pc/bg_bet_niuniu_set3_chaobei.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set3_chaobei_win',
            src: require('@/assets/imgs/pc/bg_bet_niuniu_set3_chaobei_win.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set3_fangbei',
            src: require('@/assets/imgs/pc/bg_bet_niuniu_set3_fangbei.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set3_fangbei_win',
            src: require('@/assets/imgs/pc/bg_bet_niuniu_set3_fangbei_win.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set3_pinbei',
            src: require('@/assets/imgs/pc/bg_bet_niuniu_set3_pinbei.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_niuniu_set3_pinbei_win',
            src: require('@/assets/imgs/pc/bg_bet_niuniu_set3_pinbei_win.png'),
            type: 'image'
        },
        {
            name: 'bg_lobby_goodraod_recommend_bac@2x',
            src: require('@/assets/imgs/pc/bg_lobby_goodraod_recommend_bac@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_lobby_goodraod_recommend_dragon&tiger@2x',
            src: require('@/assets/imgs/pc/bg_lobby_goodraod_recommend_dragon&tiger@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_pop_close@2x',
            src: require('@/assets/imgs/pc/bg_pop_close@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_pop_setting-goodroad_01@2x',
            src: require('@/assets/imgs/pc/bg_pop_setting-goodroad_01@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_pop_setting-goodroad_02@2x',
            src: require('@/assets/imgs/pc/bg_pop_setting-goodroad_02@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_pop_setting-goodroad_03@2x',
            src: require('@/assets/imgs/pc/bg_pop_setting-goodroad_03@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_pop_setting-goodroad_04@2x',
            src: require('@/assets/imgs/pc/bg_pop_setting-goodroad_04@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_pop_setting-goodroad_05@2x',
            src: require('@/assets/imgs/pc/bg_pop_setting-goodroad_05@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_pop_setting-goodroad_06@2x',
            src: require('@/assets/imgs/pc/bg_pop_setting-goodroad_06@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_pop_setting-goodroad_07@2x',
            src: require('@/assets/imgs/pc/bg_pop_setting-goodroad_07@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_pop_setting-goodroad_08@2x',
            src: require('@/assets/imgs/pc/bg_pop_setting-goodroad_08@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_pop_setting-goodroad_09@2x',
            src: require('@/assets/imgs/pc/bg_pop_setting-goodroad_09@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_pop_setting-goodroad_10@2x',
            src: require('@/assets/imgs/pc/bg_pop_setting-goodroad_10@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_public_chip_selected',
            src: require('@/assets/imgs/pc/bg_public_chip_selected.png'),
            type: 'image'
        },
        {
            name: 'bg_public_chip_selected@2x',
            src: require('@/assets/imgs/pc/bg_public_chip_selected@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_public_result_b',
            src: require('@/assets/imgs/pc/bg_public_result_b.png'),
            type: 'image'
        },
        {
            name: 'bg_public_result_dragon',
            src: require('@/assets/imgs/pc/bg_public_result_dragon.png'),
            type: 'image'
        },
        {
            name: 'bg_public_result_p',
            src: require('@/assets/imgs/pc/bg_public_result_p.png'),
            type: 'image'
        },
        {
            name: 'bg_public_result_pair',
            src: require('@/assets/imgs/pc/bg_public_result_pair.png'),
            type: 'image'
        },
        {
            name: 'bg_public_result_tiger',
            src: require('@/assets/imgs/pc/bg_public_result_tiger.png'),
            type: 'image'
        },
        {
            name: 'bg_public_result_win1',
            src: require('@/assets/imgs/pc/bg_public_result_win1.png'),
            type: 'image'
        },
        {
            name: 'bg_public_result_win2',
            src: require('@/assets/imgs/pc/bg_public_result_win2.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat1@2x',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat1@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat1_chaobei@2x',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat1_chaobei@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat1_chaobei_win',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat1_chaobei_win.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat1_fanbei@2x',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat1_fanbei@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat1_fanbei_win',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat1_fanbei_win.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat1_pinbei@2x',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat1_pinbei@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat1_pinbei_win',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat1_pinbei_win.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat2@2x',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat2@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat2_chaobei@2x',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat2_chaobei@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat2_chaobei_win',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat2_chaobei_win.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat2_fanbei@2x',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat2_fanbei@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat2_fanbei_win',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat2_fanbei_win.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat2_pinbei@2x',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat2_pinbei@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat2_pinbei_win',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat2_pinbei_win.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat3@2x',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat3@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat3_chaobei@2x',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat3_chaobei@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat3_chaobei_win',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat3_chaobei_win.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat3_fanbei@2x',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat3_fanbei@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat3_fanbei_win',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat3_fanbei_win.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat3_pinbei@2x',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat3_pinbei@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat3_pinbei_win',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat3_pinbei_win.png'),
            type: 'image'
        },
        {
            name: 'bg_table_01',
            src: require('@/assets/imgs/pc/bg_table_01.png'),
            type: 'image'
        },
        {
            name: 'bg_table_02',
            src: require('@/assets/imgs/pc/bg_table_02.png'),
            type: 'image'
        },
        {
            name: 'bg_table_03',
            src: require('@/assets/imgs/pc/bg_table_03.png'),
            type: 'image'
        },
        {
            name: 'bg_table_04',
            src: require('@/assets/imgs/pc/bg_table_04.png'),
            type: 'image'
        },
        {
            name: 'bg_table_05',
            src: require('@/assets/imgs/pc/bg_table_05.png'),
            type: 'image'
        },
        {
            name: 'bg_table_06',
            src: require('@/assets/imgs/pc/bg_table_06.png'),
            type: 'image'
        },
        {
            name: 'bg_table_07',
            src: require('@/assets/imgs/pc/bg_table_07.png'),
            type: 'image'
        },
        {
            name: 'bg_table_08',
            src: require('@/assets/imgs/pc/bg_table_08.png'),
            type: 'image'
        },
        {
            name: 'bg_table_bet_bac_classic01@2x',
            src: require('@/assets/imgs/pc/bg_table_bet_bac_classic01@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_table_loading',
            src: require('@/assets/imgs/pc/bg_table_loading.png'),
            type: 'image'
        },
        {
            name: 'big_tiger@2x',
            src: require('@/assets/imgs/pc/big_tiger@2x.png'),
            type: 'image'
        },
        {
            name: 'br_1@2x',
            src: require('@/assets/imgs/pc/br_1@2x.png'),
            type: 'image'
        },
        {
            name: 'br_2@2x',
            src: require('@/assets/imgs/pc/br_2@2x.png'),
            type: 'image'
        },
        {
            name: 'br_3@2x',
            src: require('@/assets/imgs/pc/br_3@2x.png'),
            type: 'image'
        },
        {
            name: 'br_4@2x',
            src: require('@/assets/imgs/pc/br_4@2x.png'),
            type: 'image'
        },
        {
            name: 'br_4_banker@2x',
            src: require('@/assets/imgs/pc/br_4_banker@2x.png'),
            type: 'image'
        },
        {
            name: 'but_lobby_title_select01@2x',
            src: require('@/assets/imgs/pc/but_lobby_title_select01@2x.png'),
            type: 'image'
        },
        {
            name: 'but_lobby_title_select02@2x',
            src: require('@/assets/imgs/pc/but_lobby_title_select02@2x.png'),
            type: 'image'
        },
        {
            name: 'but_pop_goodroad-setting_select@2x',
            src: require('@/assets/imgs/pc/but_pop_goodroad-setting_select@2x.png'),
            type: 'image'
        },
        {
            name: 'but_table_change-play@2x',
            src: require('@/assets/imgs/pc/but_table_change-play@2x.png'),
            type: 'image'
        },
        {
            name: 'but_table_change-table@2x',
            src: require('@/assets/imgs/pc/but_table_change-table@2x.png'),
            type: 'image'
        },
        {
            name: 'but_table_commission_off',
            src: require('@/assets/imgs/pc/but_table_commission_off.png'),
            type: 'image'
        },
        {
            name: 'but_table_commission_on',
            src: require('@/assets/imgs/pc/but_table_commission_on.png'),
            type: 'image'
        },
        {
            name: 'chip_public_01@2x',
            src: require('@/assets/imgs/pc/chip_public_01@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_02@2x',
            src: require('@/assets/imgs/pc/chip_public_02@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_03@2x',
            src: require('@/assets/imgs/pc/chip_public_03@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_04@2x',
            src: require('@/assets/imgs/pc/chip_public_04@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_05@2x',
            src: require('@/assets/imgs/pc/chip_public_05@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_06@2x',
            src: require('@/assets/imgs/pc/chip_public_06@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_07@2x',
            src: require('@/assets/imgs/pc/chip_public_07@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_08@2x',
            src: require('@/assets/imgs/pc/chip_public_08@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_09@2x',
            src: require('@/assets/imgs/pc/chip_public_09@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_10@2x',
            src: require('@/assets/imgs/pc/chip_public_10@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_11@2x',
            src: require('@/assets/imgs/pc/chip_public_11@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_12@2x',
            src: require('@/assets/imgs/pc/chip_public_12@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_13@2x',
            src: require('@/assets/imgs/pc/chip_public_13@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_14@2x',
            src: require('@/assets/imgs/pc/chip_public_14@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_15@2x',
            src: require('@/assets/imgs/pc/chip_public_15@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_16@2x',
            src: require('@/assets/imgs/pc/chip_public_16@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_17@2x',
            src: require('@/assets/imgs/pc/chip_public_17@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_18@2x',
            src: require('@/assets/imgs/pc/chip_public_18@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_19@2x',
            src: require('@/assets/imgs/pc/chip_public_19@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_bet_01@2x',
            src: require('@/assets/imgs/pc/chip_public_bet_01@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_bet_02@2x',
            src: require('@/assets/imgs/pc/chip_public_bet_02@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_bet_03@2x',
            src: require('@/assets/imgs/pc/chip_public_bet_03@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_bet_04@2x',
            src: require('@/assets/imgs/pc/chip_public_bet_04@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_bet_05@2x',
            src: require('@/assets/imgs/pc/chip_public_bet_05@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_bet_06@2x',
            src: require('@/assets/imgs/pc/chip_public_bet_06@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_bet_07@2x',
            src: require('@/assets/imgs/pc/chip_public_bet_07@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_bet_08@2x',
            src: require('@/assets/imgs/pc/chip_public_bet_08@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_bet_09@2x',
            src: require('@/assets/imgs/pc/chip_public_bet_09@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_bet_10@2x',
            src: require('@/assets/imgs/pc/chip_public_bet_10@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_bet_11@2x',
            src: require('@/assets/imgs/pc/chip_public_bet_11@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_bet_12@2x',
            src: require('@/assets/imgs/pc/chip_public_bet_12@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_bet_13@2x',
            src: require('@/assets/imgs/pc/chip_public_bet_13@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_bet_14@2x',
            src: require('@/assets/imgs/pc/chip_public_bet_14@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_bet_15@2x',
            src: require('@/assets/imgs/pc/chip_public_bet_15@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_bet_16@2x',
            src: require('@/assets/imgs/pc/chip_public_bet_16@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_bet_17@2x',
            src: require('@/assets/imgs/pc/chip_public_bet_17@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_bet_18@2x',
            src: require('@/assets/imgs/pc/chip_public_bet_18@2x.png'),
            type: 'image'
        },
        {
            name: 'chip_public_bet_19@2x',
            src: require('@/assets/imgs/pc/chip_public_bet_19@2x.png'),
            type: 'image'
        },
        {
            name: 'classic_dragon_box@2x',
            src: require('@/assets/imgs/pc/classic_dragon_box@2x.png'),
            type: 'image'
        },
        {
            name: 'classic_dragon_tiger_tie@2x',
            src: require('@/assets/imgs/pc/classic_dragon_tiger_tie@2x.png'),
            type: 'image'
        },
        {
            name: 'classic_dragon_tiger_tie_box@2x',
            src: require('@/assets/imgs/pc/classic_dragon_tiger_tie_box@2x.png'),
            type: 'image'
        },
        {
            name: 'classic_tiger@2x',
            src: require('@/assets/imgs/pc/classic_tiger@2x.png'),
            type: 'image'
        },
        {
            name: 'classic_tiger_box@2x',
            src: require('@/assets/imgs/pc/classic_tiger_box@2x.png'),
            type: 'image'
        },
        {
            name: 'dargon_1@2x',
            src: require('@/assets/imgs/pc/dargon_1@2x.png'),
            type: 'image'
        },
        {
            name: 'dealer1@2x',
            src: require('@/assets/imgs/pc/dealer1@2x.png'),
            type: 'image'
        },
        {
            name: 'dragon_2@2x',
            src: require('@/assets/imgs/pc/dragon_2@2x.png'),
            type: 'image'
        },
        {
            name: 'dragon_7@2x',
            src: require('@/assets/imgs/pc/dragon_7@2x.png'),
            type: 'image'
        },
        {
            name: 'dragon_and_tiger_tie@2x',
            src: require('@/assets/imgs/pc/dragon_and_tiger_tie@2x.png'),
            type: 'image'
        },
        {
            name: 'font_loading@2x',
            src: require('@/assets/imgs/pc/font_loading@2x.png'),
            type: 'image'
        },
        {
            name: 'font_table_bet_b_2',
            src: require('@/assets/imgs/pc/font_table_bet_b_2.png'),
            type: 'image'
        },
        {
            name: 'free_day_card@2x',
            src: require('@/assets/imgs/pc/free_day_card@2x.png'),
            type: 'image'
        },
        {
            name: 'icon_lobby_shuffle01@2x',
            src: require('@/assets/imgs/pc/icon_lobby_shuffle01@2x.png'),
            type: 'image'
        },
        {
            name: 'icon_lobby_shuffle02@2x',
            src: require('@/assets/imgs/pc/icon_lobby_shuffle02@2x.png'),
            type: 'image'
        },
        {
            name: 'icon_public_settingchips@2x',
            src: require('@/assets/imgs/pc/icon_public_settingchips@2x.png'),
            type: 'image'
        },
        {
            name: 'litle_tiger@2x',
            src: require('@/assets/imgs/pc/litle_tiger@2x.png'),
            type: 'image'
        },
        {
            name: 'loby-header-bg@2x',
            src: require('@/assets/imgs/pc/loby-header-bg@2x.png'),
            type: 'image'
        },
        {
            name: 'login-bg',
            src: require('@/assets/imgs/pc/login-bg.jpeg'),
            type: 'image'
        },
        {
            name: 'logo_lobby@2x',
            src: require('@/assets/imgs/pc/logo_lobby@2x.png'),
            type: 'image'
        },
        {
            name: 'logo_login@2x',
            src: require('@/assets/imgs/pc/logo_login@2x.png'),
            type: 'image'
        },
        {
            name: 'panda_8@2x',
            src: require('@/assets/imgs/pc/panda_8@2x.png'),
            type: 'image'
        },
        {
            name: 'perfect_pair@2x',
            src: require('@/assets/imgs/pc/perfect_pair@2x.png'),
            type: 'image'
        },
        {
            name: 'slider_thumb@2x',
            src: require('@/assets/imgs/pc/slider_thumb@2x.png'),
            type: 'image'
        },
        {
            name: 'super_6@2x',
            src: require('@/assets/imgs/pc/super_6@2x.png'),
            type: 'image'
        },
        {
            name: 'super_and_tie@2x',
            src: require('@/assets/imgs/pc/super_and_tie@2x.png'),
            type: 'image'
        },
        {
            name: 'super_pair@2x',
            src: require('@/assets/imgs/pc/super_pair@2x.png'),
            type: 'image'
        },
        {
            name: 'tian_pai@2x',
            src: require('@/assets/imgs/pc/tian_pai@2x.png'),
            type: 'image'
        },
        {
            name: 'tiger_1@2x',
            src: require('@/assets/imgs/pc/tiger_1@2x.png'),
            type: 'image'
        },
        {
            name: 'tiger_and_tie@2x',
            src: require('@/assets/imgs/pc/tiger_and_tie@2x.png'),
            type: 'image'
        },
        {
            name: 'tiger_pair@2x',
            src: require('@/assets/imgs/pc/tiger_pair@2x.png'),
            type: 'image'
        },
        {
            name: 'verify1@2x',
            src: require('@/assets/imgs/pc/verify1@2x.png'),
            type: 'image'
        },
        {
            name: 'video_bg@2x',
            src: require('@/assets/imgs/pc/video_bg@2x.png'),
            type: 'image'
        },
        {
            name: 'zhaung-long-bao@2x',
            src: require('@/assets/imgs/pc/zhaung-long-bao@2x.png'),
            type: 'image'
        },
        {
            name: 'zhuang_tian_brand@2x',
            src: require('@/assets/imgs/pc/zhuang_tian_brand@2x.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_classic_ppair',
            src: require('@/assets/imgs/pc/bg_bet_bac_classic_ppair.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_classic_ppair_win',
            src: require('@/assets/imgs/pc/bg_bet_bac_classic_ppair_win.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_classic_tie',
            src: require('@/assets/imgs/pc/bg_bet_bac_classic_tie.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_classic_tie_win',
            src: require('@/assets/imgs/pc/bg_bet_bac_classic_tie_win.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_classic_bpair',
            src: require('@/assets/imgs/pc/bg_bet_bac_classic_bpair.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_classic_bpair_win',
            src: require('@/assets/imgs/pc/bg_bet_bac_classic_bpair_win.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_classic_p',
            src: require('@/assets/imgs/pc/bg_bet_bac_classic_p.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_classic_p_win',
            src: require('@/assets/imgs/pc/bg_bet_bac_classic_p_win.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_classic_b',
            src: require('@/assets/imgs/pc/bg_bet_bac_classic_b.png'),
            type: 'image'
        },
        {
            name: 'bg_bet_bac_classic_b_win',
            src: require('@/assets/imgs/pc/bg_bet_bac_classic_b_win.png'),
            type: 'image'
        },
        {
            name: 'flag_china',
            src: require('@/assets/imgs/pc/flag_china.png'),
            type: 'image'
        },
        {
            name: 'flag_english',
            src: require('@/assets/imgs/pc/flag_english.png'),
            type: 'image'
        },
        {
            name: 'flag_taiwan',
            src: require('@/assets/imgs/pc/flag_taiwan.jpg'),
            type: 'image'
        },
        {
            name: 'flag_southkorea',
            src: require('@/assets/imgs/pc/flag_southkorea.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p1_label',
            src: require('@/assets/imgs/pc/nui_classic_p1_label.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p1_1',
            src: require('@/assets/imgs/pc/nui_classic_p1_1.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p1_2',
            src: require('@/assets/imgs/pc/nui_classic_p1_2.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p1_s',
            src: require('@/assets/imgs/pc/nui_classic_p1_s.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p2_label',
            src: require('@/assets/imgs/pc/nui_classic_p2_label.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p2_2',
            src: require('@/assets/imgs/pc/nui_classic_p2_2.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p2_1',
            src: require('@/assets/imgs/pc/nui_classic_p2_1.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p2_s',
            src: require('@/assets/imgs/pc/nui_classic_p2_s.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p3_label',
            src: require('@/assets/imgs/pc/nui_classic_p3_label.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p3_1',
            src: require('@/assets/imgs/pc/nui_classic_p3_1.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p3_2',
            src: require('@/assets/imgs/pc/nui_classic_p3_2.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p3_s',
            src: require('@/assets/imgs/pc/nui_classic_p3_s.png'),
            type: 'image'
        },
        {
            name: 'bg_table_loading_nui_classic',
            src: require('@/assets/imgs/pc/bg_table_loading_nui_classic.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p1_s_win',
            src: require('@/assets/imgs/pc/nui_classic_p1_s_win.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p1_2_win',
            src: require('@/assets/imgs/pc/nui_classic_p1_2_win.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p1_1_win',
            src: require('@/assets/imgs/pc/nui_classic_p1_1_win.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p2_s_win',
            src: require('@/assets/imgs/pc/nui_classic_p2_s_win.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p2_2_win',
            src: require('@/assets/imgs/pc/nui_classic_p2_2_win.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p2_1_win',
            src: require('@/assets/imgs/pc/nui_classic_p2_1_win.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p3_s_win',
            src: require('@/assets/imgs/pc/nui_classic_p3_s_win.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p3_2_win',
            src: require('@/assets/imgs/pc/nui_classic_p3_2_win.png'),
            type: 'image'
        },
        {
            name: 'nui_classic_p3_1_win',
            src: require('@/assets/imgs/pc/nui_classic_p3_1_win.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat1_jakpot',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat1_jakpot.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat2_jakpot',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat2_jakpot.png'),
            type: 'image'
        },
        {
            name: 'bg_tabel_bet_niuniu_seat3_jakpot',
            src: require('@/assets/imgs/pc/bg_tabel_bet_niuniu_seat3_jakpot.png'),
            type: 'image'
        },
        {
            name: 'flag_vietnam',
            src: require('@/assets/imgs/pc/flag_vietnam.jpeg'),
            type: 'image'
        }
    ]
    //#endregion

    //#region Common Assets

    const commonAssets: Assets[] = [
        {
            name: 'flags_japan',
            src: require('@/assets/imgs/pc/flag_thailand.png'),
            type: 'image'
        },
        {
            name: 'avatar1',
            src: require('@/assets/imgs/avatar1.png'),
            type: 'image'
        },
        {
            name: 'chips',
            src: require('@/assets/imgs/chips.png'),
            type: 'image'
        },
        {
            name: 'dealer1',
            src: require('@/assets/imgs/dealer1.png'),
            type: 'image'
        },
        {
            name: 'dialog_close_btn',
            src: require('@/assets/imgs/dialog_close_btn.png'),
            type: 'image'
        },
        {
            name: 'h_clubs',
            src: require('@/assets/imgs/h_clubs.png'),
            type: 'image'
        },
        {
            name: 'h_diamonds',
            src: require('@/assets/imgs/h_diamonds.png'),
            type: 'image'
        },
        {
            name: 'h_hearts',
            src: require('@/assets/imgs/h_hearts.png'),
            type: 'image'
        },
        {
            name: 'h_spades',
            src: require('@/assets/imgs/h_spades.png'),
            type: 'image'
        },
        {
            name: 'loader',
            src: require('@/assets/imgs/loader.gif'),
            type: 'image'
        },
        {
            name: 'loading copy',
            src: require('@/assets/imgs/loading copy.png'),
            type: 'image'
        },
        {
            name: 'loading',
            src: require('@/assets/imgs/loading.png'),
            type: 'image'
        },
        {
            name: 'm-loader-sub-text',
            src: require('@/assets/imgs/m-loader-sub-text.png'),
            type: 'image'
        },
        {
            name: 'm-login-bg',
            src: require('@/assets/imgs/m-login-bg.jpeg'),
            type: 'image'
        },
        {
            name: 'm-side-toggle-bg',
            src: require('@/assets/imgs/m-side-toggle-bg.png'),
            type: 'image'
        },
        {
            name: 'phone_title_BG',
            src: require('@/assets/imgs/phone_title_BG.png'),
            type: 'image'
        },
        {
            name: 'reserved',
            src: require('@/assets/imgs/reserved.png'),
            type: 'image'
        },
        {
            name: 'room-1',
            src: require('@/assets/imgs/room-1.png'),
            type: 'image'
        },
        {
            name: 'room-2',
            src: require('@/assets/imgs/room-2.png'),
            type: 'image'
        },
        {
            name: 'room-3',
            src: require('@/assets/imgs/room-3.png'),
            type: 'image'
        },
        {
            name: 't_left',
            src: require('@/assets/imgs/t_left.png'),
            type: 'image'
        },
        {
            name: 't_right',
            src: require('@/assets/imgs/t_right.png'),
            type: 'image'
        },
        {
            name: 'v_clubs',
            src: require('@/assets/imgs/v_clubs.png'),
            type: 'image'
        },
        {
            name: 'v_diamonds',
            src: require('@/assets/imgs/v_diamonds.png'),
            type: 'image'
        },
        {
            name: 'v_hearts',
            src: require('@/assets/imgs/v_hearts.png'),
            type: 'image'
        },
        {
            name: 'v_spades',
            src: require('@/assets/imgs/v_spades.png'),
            type: 'image'
        },
        {
            name: 'verify-1',
            src: require('@/assets/imgs/verify-1.jpg'),
            type: 'image'
        },
        {
            name: 'poker_mask',
            src: require('@/assets/imgs/poker_mask.png'),
            type: 'image'
        },
        {
            name: 'poker_back',
            src: require('@/assets/imgs/poker_back.png'),
            type: 'image'
        },
        {
            name: 'effect_bonus',
            src: require('@/assets/imgs/effect_bonus.png'),
            type: 'image'
        },
        {
            name: 'effect_gold',
            src: require('@/assets/imgs/effect_gold.gif'),
            type: 'image'
        },
        {
            name: 'fire_works',
            src: require('@/assets/imgs/fire_works.gif'),
            type: 'image'
        }
    ]
    //#endregion

    const assets = [...imgSrc, ...pcAssets, ...commonAssets]
    const refVar = ref<{
        isLoaded: boolean
        loadCount: number
        assetCount: number
    }>({ isLoaded: false, loadCount: 0, assetCount: assets.length })

    const LoadAssest = (_assets: Assets) => {
        return new Promise((resolve, reject) => {
            if (_assets.type === 'image') {
                const img = new Image()
                img.src = _assets.src
                img.onload = () => {
                    if (refVar.value) {
                        refVar.value.loadCount++
                        if (
                            refVar.value.assetCount === refVar.value.loadCount
                        ) {
                            refVar.value.isLoaded = true
                        }
                    }
                    resolve(img)
                }

                img.onerror = img.onabort = () => {
                    if (refVar.value) refVar.value.loadCount++
                    reject(_assets)
                }
            }
        })
    }

    const PreloadAssets = () => {
        assets.map((imgs) => LoadAssest(imgs).catch((err) => err))
    }

    return {
        refVar,
        PreloadAssets
    }
}
