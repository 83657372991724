import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3485ad6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-navigation-root" }
const _hoisted_2 = { class: "icon" }
const _hoisted_3 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_all_games = _resolveComponent("icon-all-games")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["navigation-item", { active: _ctx.modelValue === 1 }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickNavItem(1))),
      onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showOtherOptions = false))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_icon_all_games, {
          class: "icon",
          isOn: _ctx.modelValue === 1
        }, null, 8, ["isOn"])
      ]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('loby.allgames')), 1)
    ], 34),
    _createVNode(_Transition, { name: "fadein-out" }, {
      default: _withCtx(() => [
        (_ctx.showOtherOptions)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "other-options",
              onMouseleave: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showOtherOptions = false))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["baccarat-option", { active: _ctx.otherOption.indexOf(0) !== -1 }]),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setOtherOptions(0)))
              }, null, 2),
              _createElementVNode("div", {
                class: _normalizeClass(["dragon-tiger-option", { active: _ctx.otherOption.indexOf(1) !== -1 }]),
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setOtherOptions(1)))
              }, null, 2)
            ], 32))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}