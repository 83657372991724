import { createI18n } from 'vue-i18n'
import { getCookieValue } from '.'
import { CookiesToken } from '@/types/CookiesToken'

import rules from './gamerules'
import nuibonusrules from './nuibonus'

const messages = {
    en: {
        setting: {
            title: 'System Setting',
            language: 'Language',
            chipsetting: 'Chips Setting',
            goodroadsetting: 'Good Road',
            gamerules: 'Game Rules',
            changepassword: 'Edit Pwd.',
            customerservice: 'Customer Service',
            signout: 'Sign out',
            soundsetting: 'Sound',
            music: 'Music',
            voice: 'Voice',
            bettingrecord: 'Bet Record',
            userinfo: 'User Information',
            avatar: 'Avatar',
            nuibonusinfo: 'Jackpot Description'
        },
        common: {
            cancel: 'Cancel',
            confirm: 'Confirm',
            hint: 'Hint',
            limit: 'Red Limit',
            more: 'More',
            allroad: 'All Road',
            mainroad: 'Big Road',
            statistics: 'Statistics',
            niuniu: 'Niu-Niu',
            time: 'Time',
            to: 'to',
            reload: 'Reload',
            addall: 'Add all',
            countdown: 'Time',
            remove: 'Remove',
            add: 'Add',
            customize: 'Custom',
            topup: 'Top up',
            withdraw: 'Withdraw',
            record: 'Record',
            next: 'Next',
            prev: 'Pev',
            topage: 'Page',
            totalpage: 'Total {0} Items',
            commission: 'comm.',
            loading: 'Loading',
            printing: 'Print receipt',
            processing: 'Processing'
        },
        chips: {
            enteramount: '???'
        },
        hints: {
            logout: 'Are you certain you want to log out of the game?',
            multibettingselecthint:
                'Please choose to add a table from the right',
            multibettingalladdedhint: 'All have been added',
            orientationhint:
                'For a better display experience<br/>Please rotate your phone',
            commissionconvertionsuccessful: 'Convertion successful'
        },
        roadmap: {
            banker: 'B',
            player: 'P',
            dragon: 'D',
            tiger: 'T',
            tie: 'T',
            pair: 'Pr.'
        },
        login: {
            trial: 'Try it out',
            login: 'Login',
            usernameplaceholder: 'Please Enter Your Username',
            passwordplaceholder: 'Please Enter Password',
            forgetpassword: 'Forget Password?',
            quickregistration: 'Quick Registration',
            verifyimagetitle: 'Drag slider to complete the puzzle',
            verifyimagehint: 'Drag slider to fill the puzzle'
        },
        loby: {
            anchorroom: 'ANCHOR R.M',
            classicroom: 'CLASSIC R.M',
            multibet: 'MULTIPLE',
            goodroadrecomended: 'Recomended',
            changeroom: 'ROOMS',
            allgames: 'All Games',
            converttobalance: 'To Balance'
        },
        desklist: {
            niuniutable: 'Niu-Niu {tablenumber}',
            classic: 'Cls.',
            shuffling: 'Shuffling',
            opening: 'Opening',
            rounds: 'Round',
            entergame: 'ENTER TABLE'
        },
        ingame: {
            limitpair: 'Pair',
            limittie: 'Tie',
            limitpot: 'Pot',
            gameid: 'GameID',
            switchgameplay: 'Game Play',
            nocommission: 'No Cm.',
            withcommission: 'W/ Comm',
            selecttable: 'Tables',
            prediction: '.ASK',
            tableswitcher: 'S.TABLES',
            bet: 'BET',
            totalbetamount: 'Total Bet Amt:',
            totaldepositamount: 'Total Desposit Amt:',
            currentamount: 'Current Amt:',
            jackpotmessage: 'Congratulations to {0} for wining {1} bonus {2}'
        },
        record: {
            gameidplaceholder: 'Please enter gameID',
            search: 'Search',
            bettingtime: 'Date',
            gameid: 'Game ID',
            gametype: 'Game',
            tablenumber: 'Table',
            result: 'Result',
            howtoplay: 'How to Play',
            betamount: 'BET Amt.',
            payout: 'Payout',
            validbet: 'Valid BET',
            norecordfound: 'No Record Found'
        },
        userinfo: {
            nickname: 'Nick Name',
            todaybetamount: 'Today Bet Amt.',
            todayvalidamount: 'Today Valid Amt.',
            todaywinlos: 'Today WIN/LOSS',
            weekbetamount: 'Week BET Amt.',
            weekvalidamount: 'Week Valid Amt.',
            weekcommissionamount: 'Week Comm. Amt.',
            lastweekbetamount: 'Last Week BET Amt.',
            lastweekvalidamount: 'Last Week Valid Amt.',
            lastweekcommissionamount: 'Last Week Comm. Amt.',
            changeavatar: 'Avatar'
        },
        editpassword: {
            oldpassword: 'Old Pwd',
            oldpasswordplaceholder: 'Please Enter Old Pwd.',
            newpassword: 'New Pwd.',
            newpasswordplaceholder: 'Please Enter New Pwd.',
            newpassworderror: 'Must contains letters and numbers!',
            confirmpassword: 'Confirm Pwd.',
            confirmpasswordplaceholder: 'Retype new pwd.',
            confirmpassworderror: 'Passwords not match'
        },
        rules: {
            niuniu: rules['en'].niuniu
        },
        niuniu: {
            niuniu: '牛牛',
            niu: '牛',
            no: '无牛 ',
            bomCow: '炸弹牛',
            fivebeff: '五花牛',
            five: '五小',
            flush: 'Flush',
            royalflush: 'Royal Flush',
            straightflush: 'Straight Flush',
            fourkind: 'Four of a kind',
            fullhouse: 'Full House',
            straight: 'Straight',
            threekind: 'Three of a kind'
        },
        prompt: {
            betsucessfull: 'Sucessfull bet',
            networkerror: 'The current network is not good, please try again',
            bettingnotallowed: 'Betting is not possible at this time.',
            insufficientbalance: 'Insufficient balance',
            minandmaxchipsselected: 'Please select 5-8 chips',
            idle: 'You have not bet for a long time, please log in to the system again, thank you!',
            jackpotmaxbet: 'You can only bet up to 2000'
        },
        nuibonushint: nuibonusrules.en,
        topup: {
            bankname: 'Bank name',
            banknamehint: 'Enter bank name',
            banknameerror: 'Invalid bank name',
            depositorname: 'Name',
            depositornamehint: 'Enter name',
            depositornameerror: 'Invalid name',
            accountnumber: 'Card No.',
            accountnumberhint: 'Please enter card no.',
            accountnumbererror: 'Invalid card no.',
            amount: 'Amount',
            amounthint: 'Enter Amount',
            amounterror: 'Invalid amount',
            phonenumber: 'Phone Number',
            phonenumberhint: 'Please Enter Phone Number',
            phonenumbererror: 'Invalid phone number',
            confirminfohint:
                'Confirm card number <span class="number-color">{0}</span> and enter it into the system? If you need to modify it again, please contact customer service',
            rechargesent: 'Recharge Information is sent successfully'
        },
        withdraw: {
            hint: 'The deposit/withdrawal limit is 9 million Korean won (only for Korean customers)',
            bankname: 'Bank name',
            banknamehint: 'Enter bank name',
            banknameerror: 'Invalid bank name',
            currentbalance: 'Current Balance',
            membername: 'Name',
            membernamehint: 'Enter name',
            membernameerror: 'Invalid name',
            accountnumber: 'Card no.',
            accountnumberhint: 'Enter Card no.',
            accountnumbererror: 'Invalid Card no.',
            amount: 'Amount',
            amounthint: 'Please enter amount',
            amounterror: 'Invalid amount',
            phonenumber: 'Phone number',
            phonenumberhint: 'Enter phone nnumber',
            phonenumbererror: 'Invalid phone number',
            withdrawsent: 'Withdrawal request is sent successfully'
        },
        withdrawhistory: {
            title: 'Topup And Withdraw Records',
            amount: 'Amount',
            name: 'Name',
            bankname: 'Bank Name',
            cardnumber: 'Account N0.',
            classification: 'Classification',
            contact: 'Contact',
            state: 'State',
            date: 'Date',
            remark: 'Remark',
            processed: 'Processed',
            pending: 'Pending',
            rejected: 'Rejected'
        }
    },
    'zh-CN': {
        setting: {
            title: '系统设置',
            language: '语言设置',
            chipsetting: '筹码设置',
            goodroadsetting: '好路设置',
            gamerules: '游戏规则',
            changepassword: '修改密码',
            customerservice: '客服中心',
            signout: '登出',
            soundsetting: '音量设置',
            music: '音乐',
            voice: '语音',
            bettingrecord: '投注记录',
            userinfo: '个人信息',
            avatar: '头像选择',
            nuibonusinfo: '头奖说明'
        },
        common: {
            cancel: '取消',
            confirm: '确定',
            hint: '提示',
            limit: '限红',
            more: '更多',
            allroad: '全路',
            mainroad: '大路',
            statistics: '统计',
            niuniu: '牛牛',
            time: '时间',
            to: '至',
            reload: '刷 新',
            addall: '全部添加',
            countdown: '倒计时',
            remove: '移除',
            add: '添加',
            customize: '自定义',
            topup: '充值',
            withdraw: '提现',
            record: '记录',
            next: '下页',
            prev: '上页',
            topage: '跳转到',
            totalpage: '共{0}条',
            commission: '佣金',
            loading: '加载中',
            printing: '打印小票',
            processing: '处理中'
        },
        chips: {
            enteramount: '输入金额'
        },
        hints: {
            logout: '是否确认登出游戏？',
            multibettingselecthint: '请从右边选择添加台桌',
            multibettingalladdedhint: '已被全部添加',
            orientationhint: '为了更好的显示体验<br/>请旋转您的手机',
            commissionconvertionsuccessful: '转换成功'
        },
        roadmap: {
            banker: '庄',
            player: '闲'
        },
        login: {
            trial: '试 玩',
            login: '登 录',
            usernameplaceholder: '请输入账户名',
            passwordplaceholder: '请输入密码',
            forgetpassword: '忘记密码?',
            quickregistration: '快速注册',
            verifyimagetitle: '拖动下方滑块完成拼图',
            verifyimagehint: '拖动滑块填充拼图'
        },
        loby: {
            anchorroom: '主播厅',
            classicroom: '大厅',
            multibet: '多 台',
            goodroadrecomended: '好路推荐',
            changeroom: '换厅',
            allgames: '全部游戏',
            converttobalance: '转换为余额'
        },
        desklist: {
            niuniutable: '牛牛 {tablenumber}',
            classic: '经典',
            shuffling: '洗牌中',
            opening: '开牌中',
            rounds: '局数',
            entergame: '进入游戏'
        },
        ingame: {
            limitpair: '庄闲对',
            limittie: '和',
            limitpot: '彩池',
            gameid: '局号',
            switchgameplay: '切换玩法',
            nocommission: '免佣',
            withcommission: '不免佣',
            selecttable: '选桌',
            prediction: '问路',
            tableswitcher: '切换台桌',
            bet: '请下注',
            totalbetamount: '投注总额:',
            totaldepositamount: '押金总额:',
            currentamount: '余额:',
            jackpotmessage: '恭喜 {0} 台获得 {1} 彩金奖励 {2}'
        },
        record: {
            gameidplaceholder: '请输入游戏编号',
            search: '查询',
            bettingtime: '下注时间',
            gameid: '游戏编号',
            gametype: '游戏类型',
            tablenumber: '桌号',
            result: '结果',
            howtoplay: '玩法',
            betamount: '投注金额',
            payout: '派彩',
            validbet: '有效投注',
            norecordfound: '没有找到记录'
        },
        userinfo: {
            nickname: '昵称',
            todaybetamount: '今日投注总额',
            todayvalidamount: '今日有效投注',
            todaywinlos: '今日输赢',
            weekbetamount: '本周投注总额',
            weekvalidamount: '本周有效投注',
            weekcommissionamount: '本周洗码费',
            lastweekbetamount: '上周投注总额',
            lastweekvalidamount: '上周有效投注',
            lastweekcommissionamount: '上周洗码费',
            changeavatar: '换头像'
        },
        editpassword: {
            oldpassword: '旧密码',
            oldpasswordplaceholder: '请输入旧密码',
            newpassword: '新密码',
            newpasswordplaceholder: '请输入新密码',
            newpassworderror: ' 请以字母和数字为组合设置密码！',
            confirmpassword: '确认密码',
            confirmpasswordplaceholder: '请再次输入新密码',
            confirmpassworderror: '密码不匹配'
        },
        rules: {
            niuniu: rules['zH-cn'].niuniu
        },
        niuniu: {
            niuniu: '牛牛',
            niu: '牛',
            no: '无牛 ',
            bomCow: '炸弹牛',
            fivebeff: '五花牛',
            five: '五小',
            flush: 'Flush',
            royalflush: 'Royal Flush',
            straightflush: 'Straight Flush',
            fourkind: 'Four of a kind',
            fullhouse: 'Full House',
            straight: 'Straight',
            threekind: 'Three of a kind'
        },
        prompt: {
            betsucessfull: '下注成功',
            networkerror: '当前网络不佳，请重新尝试',
            bettingnotallowed: '当前不能下注',
            insufficientbalance: '余额不足',
            minandmaxchipsselected: '请选择5-8个筹码',
            idle: '您长时间未投注，请重新登录系统，谢谢！',
            jackpotmaxbet: '您最多只能投注 2000'
        },
        nuibonushint: nuibonusrules['zh-cn'],
        topup: {
            bankname: '银行名称',
            banknamehint: '输入银行名称',
            banknameerror: '银行名称无效',
            depositorname: '姓名',
            depositornamehint: '请核对输入姓名',
            depositornameerror: '银行名称无效',
            accountnumber: '卡号',
            accountnumberhint: '请输入充值卡号',
            accountnumbererror: '无效卡号',
            amount: '金额',
            amounthint: '请输入充值金额',
            amounterror: '金额无效',
            phonenumber: '联系方式',
            phonenumberhint: '请输入电话号码',
            phonenumbererror: '无效的电话号码',
            confirminfohint:
                '确认卡号 <span class="number-color">{0}</span> 录入系统？如需再次修改，请联系客服',
            rechargesent: '充值信息发送成功'
        },
        withdraw: {
            hint: '1次存/提款限额是韩币900万（仅针对韩币）',
            bankname: '银行名称',
            banknamehint: '输入银行名称',
            banknameerror: '银行名称无效',
            currentbalance: '当前余额',
            membername: '姓名',
            membernamehint: '输入会员姓名',
            membernameerror: '会员名无效',
            accountnumber: '卡号',
            accountnumberhint: '输入帐号',
            accountnumbererror: '帐号无效',
            amount: '金额',
            amounthint: '请输入金额',
            amounterror: '金额无效',
            phonenumber: '联系方式',
            phonenumberhint: '输入电话号码',
            phonenumbererror: '无效的电话号码',
            withdrawsent: '提款请求发送成功'
        },
        withdrawhistory: {
            title: '申请列表',
            amount: '金额',
            name: '姓名',
            bankname: '银行名称',
            cardnumber: '卡号',
            classification: '分类',
            contact: '联系方式',
            state: '状态',
            date: '申请时间',
            remark: '备注',
            processed: '已处理',
            pending: '待处理',
            rejected: '已拒绝 '
        },
        receipt: {
            title: '牛牛投注小票'
        }
    },
    'zh-HK': {
        setting: {
            title: '系統設定',
            language: '語言設定',
            chipsetting: '籌碼設定',
            goodroadsetting: '好路設定',
            gamerules: '遊戲規則',
            changepassword: '修改密碼',
            customerservice: '聯絡客服',
            signout: '登出',
            soundsetting: '音量設定',
            music: '音樂',
            voice: '語音',
            bettingrecord: '投注記錄',
            userinfo: '個人資訊',
            avatar: '頭像選擇',
            nuibonusinfo: '頭獎說明'
        },
        common: {
            cancel: '取消',
            confirm: '確定',
            hint: '提示',
            limit: '限紅',
            more: '更多',
            allroad: '全路',
            mainroad: '大路',
            statistics: '統計',
            niuniu: '牛牛',
            time: '時間',
            to: '至',
            reload: '重新整理',
            addall: '全部添加',
            countdown: '倒數計時',
            remove: '移除',
            add: '添加',
            customize: '自訂',
            topup: '儲值',
            withdraw: '提現',
            record: '記錄',
            next: '下頁',
            prev: '上頁',
            topage: '跳到',
            totalpage: '共{0}條',
            commission: '佣金',
            loading: '載入中',
            printing: '列印小票',
            processing: '處理中'
        },
        chips: {
            enteramount: '輸入金額'
        },
        hints: {
            logout: '是否確認登出遊戲？',
            multibettingselecthint: '請從右邊選擇新增台桌',
            multibettingalladdedhint: '已全部添加',
            orientationhint: '為了更好的顯示體驗<br/>請旋轉您的手機',
            commissionconvertionsuccessful: '轉換成功'
        },
        roadmap: {
            banker: '莊',
            player: '閒',
            dragon: '龍',
            tiger: '虎',
            tie: '和',
            pair: '對'
        },
        login: {
            trial: '試 玩',
            login: '登入',
            usernameplaceholder: '請輸入帳戶名',
            passwordplaceholder: '請輸入密碼',
            forgetpassword: '忘記密碼?',
            quickregistration: '快速註冊',
            verifyimagetitle: '拖曳下方滑桿完成拼圖',
            verifyimagehint: '拖曳滑桿填充拼圖'
        },
        loby: {
            anchorroom: '主播廳',
            classicroom: '經典廳',
            multibet: '多 台',
            goodroadrecomended: '好路推薦',
            changeroom: '換廳',
            allgames: '全部遊戲',
            converttobalance: '轉換為餘額'
        },
        desklist: {
            niuniutable: '牛牛 {tablenumber}',
            classic: '經典',
            shuffling: '洗牌中',
            opening: '開牌中',
            rounds: '局數',
            entergame: '進入遊戲'
        },
        ingame: {
            limitpair: '莊閒對',
            limittie: '和',
            limitpot: '彩池',
            gameid: '局號',
            baccaratlimit: '莊閒:',
            dragontigerlimit: '龍虎:',
            switchgameplay: '切換玩法',
            nocommission: '免傭',
            withcommission: '不免傭',
            selecttable: '選桌',
            prediction: '問路',
            tableswitcher: '切換台桌',
            bet: '請下注',
            totalbetamount: '投注總額:',
            totaldepositamount: '押金總額:',
            currentamount: '餘額:',
            jackpotmessage: '恭喜 {0} 台獲得 {1} 彩金獎勵 {2}'
        },
        record: {
            gameidplaceholder: '請輸入遊戲編號',
            search: '查詢',
            bettingtime: '下注時間',
            gameid: '遊戲編號',
            gametype: '遊戲類型',
            tablenumber: '桌號',
            result: '結果',
            howtoplay: '玩法',
            betamount: '投注金額',
            payout: '派彩',
            validbet: '有效投注',
            norecordfound: '沒有找到記錄'
        },
        userinfo: {
            nickname: '暱稱',
            todaybetamount: '今日投注總額',
            todayvalidamount: '今日有效投注',
            todaywinlos: '今日輸贏',
            weekbetamount: '本週投注總額',
            weekvalidamount: '本周有效投注',
            weekcommissionamount: '本週洗碼費',
            lastweekbetamount: '上週投注總額',
            lastweekvalidamount: '上週有效投注',
            lastweekcommissionamount: '上週洗碼費',
            changeavatar: '換頭像'
        },
        editpassword: {
            oldpassword: '舊密碼',
            oldpasswordplaceholder: '請輸入舊密碼',
            newpassword: '新密碼',
            newpasswordplaceholder: '請輸入新密碼',
            newpassworderror: '請以字母和數字為組合設定密碼！',
            confirmpassword: '確認密碼',
            confirmpasswordplaceholder: '請再次輸入新密碼',
            confirmpassworderror: '密碼不匹配'
        },
        rules: {
            niuniu: rules['zH-HK'].niuniu
        },
        niuniu: {
            niuniu: '牛牛',
            niu: '牛',
            no: '無牛 ',
            bomCow: '炸彈牛',
            fivebeff: '五花牛',
            five: '五小',
            flush: 'Flush',
            royalflush: 'Royal Flush',
            straightflush: 'Straight Flush',
            fourkind: 'Four of a kind',
            fullhouse: 'Full House',
            straight: 'Straight',
            threekind: 'Three of a kind'
        },
        prompt: {
            betsucessfull: '下注成功',
            networkerror: '目前網路不佳，請重新嘗試',
            bettingnotallowed: '目前不能下注',
            insufficientbalance: '餘額不足',
            minandmaxchipsselected: '請選擇5-8個籌碼',
            idle: '您長時間未投注，請重新登入系統，謝謝！',
            jackpotmaxbet: '您最多只能投注 2000'
        },
        nuibonushint: nuibonusrules['zh-hk'],
        topup: {
            bankname: '銀行名稱',
            banknamehint: '輸入銀行名稱',
            banknameerror: '銀行名稱無效',
            depositorname: '姓名',
            depositornamehint: '請核對輸入姓名',
            depositornameerror: '銀行名稱無效',
            accountnumber: '卡號',
            accountnumberhint: '請輸入儲值卡號',
            accountnumbererror: '無效卡號',
            amount: '金額',
            amounthint: '請輸入儲值金額',
            amounterror: '金額無效',
            phonenumber: '聯絡方式',
            phonenumberhint: '請輸入電話號碼',
            phonenumbererror: '無效的電話號碼',
            confirminfohint:
                '確認卡號 <span class="number-color">{0}</span> 錄入系統？ 如需再次修改，請聯絡客服',
            rechargesent: '儲值訊息發送成功'
        },
        withdraw: {
            hint: '一次存/提款限額為韓幣900萬（僅限韓國客戶)',
            bankname: '銀行名稱',
            banknamehint: '輸入銀行名稱',
            banknameerror: '銀行名稱無效',
            currentbalance: '當前餘額',
            membername: '姓名',
            membernamehint: '輸入會員姓名',
            membernameerror: '會員名無效',
            accountnumber: '卡號',
            accountnumberhint: '輸入帳號',
            accountnumbererror: '帳號無效',
            amount: '金額',
            amounthint: '請輸入金額',
            amounterror: '金額無效',
            phonenumber: '聯絡方式',
            phonenumberhint: '輸入電話號碼',
            phonenumbererror: '無效的電話號碼',
            withdrawsent: '提款請求發送成功'
        },
        withdrawhistory: {
            title: '申請列表',
            amount: '金額',
            name: '姓名',
            bankname: '銀行名',
            cardnumber: '卡號',
            classification: '分類',
            contact: '聯絡方式',
            state: '狀態',
            date: '申請時間',
            remark: '備註',
            processed: '已處理',
            pending: '待處理',
            rejected: '已拒絕'
        }
    },
    kor: {
        setting: {
            title: '시스템 설정',
            language: '언어',
            chipsetting: '칩 설정',
            goodroadsetting: '패턴 즐겨찾기',
            gamerules: '게임규칙',
            changepassword: '비밀번호 변경',
            customerservice: '고객 대화창',
            signout: '로그아웃',
            soundsetting: '사운드 (볼륨 설정)',
            music: '음악',
            voice: '음성',
            bettingrecord: '베팅 기록',
            userinfo: '사용자 정보',
            avatar: '아바타',
            nuibonusinfo: '잭팟 설명'
        },
        common: {
            cancel: '취소',
            confirm: '확인',
            hint: '힌트',
            limit: '최소-최대',
            more: '더보기',
            allroad: '모두보기',
            mainroad: '주요보기',
            statistics: '통계',
            niuniu: '니우니우',
            time: '시간',
            to: '에게',
            reload: '새로 고침',
            addall: '전체 추가가',
            countdown: '카운트다운',
            remove: '제거',
            add: '추가',
            customize: '사용자 설정',
            topup: '충전',
            withdraw: '환전',
            record: '입출기록',
            next: 'Next',
            prev: 'Prev',
            topage: 'To Page',
            totalpage: '총{0}개 항목',
            commission: '수수료',
            loading: '로딩중',
            printing: '발권',
            processing: '처리중'
        },
        chips: {
            enteramount: '금액 입력'
        },
        hints: {
            logout: '게임에서 로그아웃하시겠습니까?',
            multibettingselecthint: '오른쪽에서 테이블을 추가하십시오.',
            multibettingalladdedhint: '모두 추가되었습니다',
            orientationhint: '스마트폰화면을 가로방향으로 플레이 하세요',
            commissionconvertionsuccessful: '변환 성공'
        },
        roadmap: {
            banker: 'B',
            player: 'P',
            dragon: 'D',
            tiger: 'T',
            tie: 'T',
            pair: 'Pr'
        },

        login: {
            trial: '둘러보기',
            login: '로그인',
            usernameplaceholder: '사용자 이름을 입력하세요',
            passwordplaceholder: '비밀번호를 입력하세요',
            forgetpassword: '비밀번호찾기',
            quickregistration: '회원가입',
            verifyimagetitle: '퍼즐을 완성하려면 아래 슬라이더를 드래그하세요.',
            verifyimagehint: '슬라이더를 드래그하여 퍼즐을 채우세요'
        },
        loby: {
            anchorroom: '앵커 룸',
            classicroom: '클래식룸',
            multibet: '다중 베팅',
            goodroadrecomended: '패턴 추천',
            changeroom: '룸 변경',
            allgames: '모든 게임',
            converttobalance: '잔액으로 변환'
        },
        desklist: {
            niuniutable: '니우니우 {tablenumber} 테이블',
            classic: '클',
            shuffling: '셔플중',
            opening: '진행중',
            rounds: '라운드',
            entergame: '테이블 입장'
        },
        ingame: {
            limitpair: '페어',
            limittie: '타이틀',
            limitpot: '팟',
            gameid: '게임 ID',
            switchgameplay: '게임플레이',
            nocommission: '수수료 없음',
            withcommission: '수수료 포함',
            selecttable: '테이블 선택',
            prediction: '예측',
            tableswitcher: '테이블 전환',
            bet: '베팅중',
            totalbetamount: '총배팅금액:',
            totaldepositamount: '총보증금:',
            currentamount: '현재금액:',
            jackpotmessage: '{0} {1} 잭팟 금액 축하드립니다 {2}'
        },
        record: {
            gameidplaceholder: '게임 ID를 입력하세요',
            search: '검색',
            bettingtime: '날짜',
            gameid: '게임 ID',
            gametype: '게임 유형',
            tablenumber: '테이블 번호',
            result: '결과',
            howtoplay: '게임 방법',
            betamount: '베팅 금액',
            payout: '배당',
            validbet: '유효한 베팅',
            norecordfound: '기록 없음'
        },
        userinfo: {
            nickname: '닉네임',
            todaybetamount: '오늘 베팅 금액',
            todayvalidamount: '오늘 유효 금액',
            todaywinlos: '오늘의 승패',
            weekbetamount: '주간 베팅 금액',
            weekvalidamount: '주간 유효 금액',
            weekcommissionamount: '주간 수수료 금액',
            lastweekbetamount: '지난 주 베팅 금액',
            lastweekvalidamount: '지난 주 유효 금액',
            lastweekcommissionamount: '지난 주 수수료 금액',
            changeavatar: '아바타 변경'
        },
        editpassword: {
            oldpassword: '이전 비밀번호',
            oldpasswordplaceholder: '이전 비밀번호를 입력하세요',
            newpassword: '새 비밀번호',
            newpasswordplaceholder: '새로운 비밀번호를 입력하세요',
            newpassworderror: '문자와 숫자를 조합하여 설정해주세요!',
            confirmpassword: '비밀번호 확인',
            confirmpasswordplaceholder: '새 비밀번호를 다시 입력해주세요',
            confirmpassworderror: '비밀번호가 일치하지 않습니다'
        },

        rules: {
            niuniu: rules['kor'].niuniu
        },
        niuniu: {
            niuniu: '니우니우',
            niu: '니우',
            no: '소없음',
            bomCow: '포카드',
            fivebeff: '올픽처',
            five: '五小',
            flush: 'Flush',
            royalflush: 'Royal Flush',
            straightflush: 'Straight Flush',
            fourkind: 'Four of a kind',
            fullhouse: 'Full House',
            straight: 'Straight',
            threekind: 'Three of a kind'
        },
        prompt: {
            betsucessfull: '베팅성공',
            networkerror:
                '현재 네트워크 상태가 좋지 않습니다. 다시 시도해 주세요.',
            bettingnotallowed: '지금은 베팅이 불가능합니다.',
            insufficientbalance: '잔액 불충분',
            minandmaxchipsselected: '칩은 5-8개 선택가능',
            idle: '오랫동안 베팅하지 않으셨습니다. 시스템에 다시 로그인해 주세요. 감사합니다!',
            jackpotmaxbet: '{0}까지만 베팅할 수 있습니다.'
        },
        nuibonushint: nuibonusrules.kr,
        topup: {
            bankname: '은행명',
            banknamehint: '은행 이름을 입력하세요',
            banknameerror: '잘못된 은행 이름',
            depositorname: '입금자명',
            depositornamehint: '예금자 이름을 입력하세요',
            depositornameerror: '잘못된 예금자 이름',
            accountnumber: '계좌번호',
            accountnumberhint: '계좌번호를 입력해주세요.',
            accountnumbererror: '잘못된 계좌번호입니다.',
            amount: '금액',
            amounthint: '충전금액 입력',
            amounterror: '잘못된 금액',
            phonenumber: '전화번호',
            phonenumberhint: '전화번호를 입력해주세요.',
            phonenumbererror: '유효하지 않은 전화 번호',
            confirminfohint:
                '카드번호 확인 <span class="number-color">{0}</span> 시스템에 입력하시겠습니까? 다시 수정해야 할 경우 고객 서비스에 문의하십시오.',
            rechargesent: '충전정보가 성공적으로 전송되었습니다'
        },
        withdraw: {
            hint: '1회 입출금 한도 금액은 900만원(한국 원화만)',
            bankname: '은행명',
            banknamehint: '은행 이름을 입력하세요',
            banknameerror: '잘못된 은행 이름',
            currentbalance: '보유금액 ',
            membername: '회원 이름',
            membernamehint: '회원 이름을 입력하세요',
            membernameerror: '회원 이름이 잘못되었습니다.',
            accountnumber: '계좌 번호',
            accountnumberhint: '계좌번호를 입력하세요',
            accountnumbererror: '계정이 유효하지 않습니다',
            amount: '금액',
            amounthint: '금액을 입력해주세요.',
            amounterror: '잘못된 금액',
            phonenumber: '연락처 정보',
            phonenumberhint: '전화번호를 입력하세요',
            phonenumbererror: '유효하지 않은 전화 번호',
            withdrawsent: '출금 요청이 성공적으로 전송되었습니다.'
        },
        withdrawhistory: {
            title: '신청 목록',
            amount: '금액',
            name: '이름',
            bankname: '은행 이름',
            cardnumber: '카드 번호',
            classification: '분류',
            contact: '연락 방법',
            state: '상태',
            date: '신청 시간',
            remark: '주목',
            processed: '처리됨',
            pending: '보류 중',
            rejected: '거부됨'
        }
    },
    'th': {
        setting: {
            title: 'การตั้งค่าระบบ',
            language: 'ตั้งค่าภาษา',
            chipsetting: 'การตั้งค่าชิป',
            goodroadsetting: 'การตั้งค่าถนนที่ดี',
            gamerules: 'กฎของเกม',
            changepassword: 'เปลี่ยนรหัสผ่าน',
            customerservice: 'ติดต่อฝ่ายบริการลูกค้า',
            signout: 'ออกจากระบบ',
            soundsetting: 'การตั้งค่าระดับเสียง',
            music: 'ดนตรี',
            voice: 'เสียง',
            bettingrecord: 'บันทึกการเดิมพัน',
            userinfo: 'ข้อมูลส่วนบุคคล',
            avatar: 'การเลือกอวตาร',
            nuibonusinfo: 'คำอธิบายแจ็คพอต'
        },
        common: {
            cancel: 'ยกเลิก',
            confirm: 'แน่นอน',
            hint: 'คำใบ้',
            limit: 'ขีดจำกัดสีแดง',
            more: 'มากกว่า',
            allroad: 'เส้นทางทั้งหมด',
            mainroad: 'โอจิ',
            statistics: 'สถิติ',
            niuniu: 'หนิว หนิว',
            time: 'เวลา',
            to: 'ถึง',
            reload: 'รีเฟรช',
            addall: 'เพิ่มทั้งหมด',
            countdown: 'นับถอยหลัง',
            remove: 'ลบ',
            add: 'เพิ่ม',
            customize: 'ปรับแต่ง',
            topup: 'ชาร์จ',
            withdraw: 'ถอนออก',
            record: 'บันทึก',
            next: 'Next',
            prev: 'Prev',
            topage: 'To Page',
            totalpage: 'ทั้งหมด{0}รายการ',
            commission: 'Comm.',
            loading: 'กำลังโหลด',
            printing: 'การออกตั๋ว',
            processing: 'กำลังประมวลผล'
        },
        chips: {
            enteramount: 'ใส่จำนวนเงิน'
        },
        hints: {
            logout: 'คุณแน่ใจหรือว่าจะออกจากเกม?',
            multibettingselecthint: 'กรุณาเลือกเพิ่มตารางจากด้านขวา',
            multibettingalladdedhint: 'เพิ่มทั้งหมดแล้ว',
            orientationhint:
                'เพื่อประสบการณ์การแสดงผลที่ดียิ่งขึ้น<br/>กรุณาหมุนโทรศัพท์ของคุณ',
            commissionconvertionsuccessful: 'การแปลงสำเร็จ'
        },
        roadmap: {
            banker: 'B',
            player: 'P',
            dragon: 'D',
            tiger: 'T',
            tie: 'T',
            pair: 'P.pair'
        },
        login: {
            trial: 'ลองมันดู',
            login: 'เข้าสู่ระบบ',
            usernameplaceholder: 'กรุณากรอกชื่อผู้ใช้ของคุณ',
            passwordplaceholder: 'กรุณากรอกรหัสผ่าน',
            forgetpassword: 'ลืมรหัสผ่าน?',
            quickregistration: 'ลงทะเบียนด่วน',
            verifyimagetitle: 'ลากแถบเลื่อนด้านล่างเพื่อไขปริศนาให้สมบูรณ์',
            verifyimagehint: 'ลากแถบเลื่อนเพื่อเติมปริศนา'
        },
        loby: {
            anchorroom: 'แองเคอร์ ฮอลล์',
            classicroom: 'ห้องคลาสสิก',
            multibet: 'หลายสถานี',
            goodroadrecomended: 'แนะนำถนนดีๆ',
            changeroom: 'ห้องเปลี่ยนเสื้อผ้า',
            allgames: 'เกมส์ทั้งหมด',
            converttobalance: 'แปลงไปสู่ความสมดุล'
        },
        desklist: {
            niuniutable: 'หนิว หนิว {tablenumber}',
            classic: 'คลาสสิค',
            shuffling: 'สับ',
            opening: 'กำลังเปิด',
            rounds: 'Rounds',
            entergame: 'เข้าเกม'
        },
        ingame: {
            limitpair: 'P.B.pair',
            limittie: 'และ',
            limitpot: 'หม้อ',
            gameid: 'เลขที่สำนัก',
            baccaratlimit: 'B.P:',
            dragontigerlimit: 'D.T:',
            switchgameplay: 'สลับการเล่นเกม',
            nocommission: 'ไม่มีค่าคอมมิชชั่น',
            withcommission: 'ไม่ได้รับการยกเว้นค่าคอมมิชชั่น',
            selecttable: 'เลือกตาราง',
            prediction: 'สอบถามเส้นทาง',
            tableswitcher: 'โต๊ะสลับ',
            bet: 'กรุณาวางเดิมพันของคุณ',
            totalbetamount: 'จำนวนเงินเดิมพันทั้งหมด:',
            totaldepositamount: 'ยอดฝากทั้งหมด:',
            currentamount: 'สมดุล:',
            jackpotmessage: '{0} โต๊ะ,{1} แจ๊กพอต,ยินดีด้วยครับ {2}'
        },
        record: {
            gameidplaceholder: 'กรุณากรอกหมายเลขเกม',
            search: 'สอบถาม',
            bettingtime: 'เวลาเดิมพัน',
            gameid: 'หมายเลขเกม',
            gametype: 'ประเภทเกม',
            tablenumber: 'โต๊ะ No.',
            result: 'ผลลัพธ์',
            howtoplay: 'วิธีการเล่น',
            betamount: 'จำนวนเงินเดิมพัน',
            payout: 'การจ่ายเงิน',
            validbet: 'เดิมพันที่ถูกต้อง',
            norecordfound: 'ไม่พบบันทึก'
        },
        userinfo: {
            nickname: 'ชื่อนิค',
            todaybetamount: 'รวมเดิมพันวันนี้',
            todayvalidamount: 'เดิมพันที่ถูกต้องวันนี้',
            todaywinlos: 'วันนี้จะชนะหรือแพ้',
            weekbetamount: 'เดิมพันรวมในสัปดาห์นี้',
            weekvalidamount: 'การเดิมพันที่ถูกต้องในสัปดาห์นี้',
            weekcommissionamount: 'ค่าล้างรหัสสัปดาห์นี้',
            lastweekbetamount: 'ยอดเดิมพันเมื่อสัปดาห์ที่แล้ว',
            lastweekvalidamount: 'เดิมพันที่ถูกต้องเมื่อสัปดาห์ที่แล้ว',
            lastweekcommissionamount: 'ค่าล้างรหัสสัปดาห์ที่แล้ว',
            changeavatar: 'เปลี่ยนอวตาร'
        },
        editpassword: {
            oldpassword: 'รหัสผ่านเก่า',
            oldpasswordplaceholder: 'กรุณากรอกรหัสผ่านเก่า',
            newpassword: 'รหัสผ่านใหม่',
            newpasswordplaceholder: 'กรุณากรอกรหัสผ่านใหม่',
            newpassworderror: 'กรุณาตั้งรหัสผ่านด้วยตัวอักษรและตัวเลขผสมกัน!',
            confirmpassword: 'ยืนยันรหัสผ่าน',
            confirmpasswordplaceholder: 'กรุณากรอกรหัสผ่านใหม่อีกครั้ง',
            confirmpassworderror: 'รหัสผ่านไม่ตรงกัน'
        },
        rules: {
            niuniu: rules['th'].niuniu
        },
        niuniu: {
            niuniu: 'หนิว หนิว',
            niu: 'วัว',
            no: 'ไม่มีวัว',
            bomCow: 'วัวระเบิด',
            fivebeff: 'เนื้อห้าดอก',
            five: '五小',
            flush: 'Flush',
            royalflush: 'Royal Flush',
            straightflush: 'Straight Flush',
            fourkind: 'Four of a kind',
            fullhouse: 'Full House',
            straight: 'Straight',
            threekind: 'Three of a kind'
        },
        prompt: {
            betsucessfull: 'เดิมพันสำเร็จ',
            networkerror: 'เครือข่ายปัจจุบันไม่ดี โปรดลองอีกครั้ง',
            bettingnotallowed: 'ไม่มีการเดิมพันในขณะนี้',
            insufficientbalance: 'ยอดคงเหลือไม่เพียงพอ',
            idle: 'คุณไม่ได้เดิมพันมานานกรุณาเข้าสู่ระบบอีกครั้งขอบคุณ!',
            jackpotmaxbet: 'คุณสามารถเดิมพันได้มากถึง 2,000 เท่านั้น'
        },
        nuibonushint: nuibonusrules.th,
        topup: {
            bankname: 'ชื่อธนาคาร',
            banknamehint: 'กรอกชื่อธนาคาร',
            banknameerror: 'ชื่อธนาคารไม่ถูกต้อง',
            depositorname: 'ชื่อผู้ฝาก',
            depositornamehint: 'กรอกชื่อผู้ฝาก',
            depositornameerror: 'ชื่อผู้ฝากไม่ถูกต้อง',
            accountnumber: 'เลขที่บัญชี',
            accountnumberhint: 'กรุณากรอกเลขที่บัญชี',
            accountnumbererror: 'หมายเลขบัญชีไม่ถูกต้อง',
            amount: 'จำนวน',
            amounthint: 'กรอกจำนวนเงินที่เติม',
            amounterror: 'จำนวนเงินไม่ถูกต้อง',
            phonenumber: 'หมายเลขโทรศัพท์',
            phonenumberhint: 'กรุณากรอกหมายเลขโทรศัพท์',
            phonenumbererror: 'หมายเลขโทรศัพท์ไม่ถูกต้อง',
            confirminfohint:
                'ยืนยันหมายเลขบัตร <span class="number-color">{0}</span> และเข้าระบบ? หากคุณต้องการแก้ไขอีกครั้งโปรดติดต่อฝ่ายบริการลูกค้า',
            rechargesent: 'ข้อมูลการเติมเงินถูกส่งเรียบร้อยแล้ว'
        },
        withdraw: {
            hint: 'วงเงินการฝาก/ถอนครั้งเดียวคือ KRW 9 ล้าน (ลูกค้าชาวเกาหลีเท่านั้น)',
            bankname: 'ชื่อธนาคาร',
            banknamehint: 'กรอกชื่อธนาคาร',
            banknameerror: 'ชื่อธนาคารไม่ถูกต้อง',
            currentbalance: 'จำนวนเงินที่ถืออยู่',
            membername: 'ชื่อสมาชิก',
            membernamehint: 'กรุณากรอกชื่อสมาชิก',
            membernameerror: 'ชื่อสมาชิกไม่ถูกต้อง',
            accountnumber: 'หมายเลขบัญชี',
            accountnumberhint: 'กรุณากรอกหมายเลขบัญชีของคุณ',
            accountnumbererror: 'บัญชีไม่ถูกต้อง',
            amount: 'จำนวน',
            amounthint: 'กรุณากรอกจำนวนเงิน',
            amounterror: 'จำนวนเงินไม่ถูกต้อง',
            phonenumber: 'หมายเลขโทรศัพท์',
            phonenumberhint: 'กรุณากรอกหมายเลขโทรศัพท์ของคุณ',
            phonenumbererror: 'หมายเลขโทรศัพท์ไม่ถูกต้อง',
            withdrawsent: 'คำขอถอนเงินของคุณถูกส่งเรียบร้อยแล้ว'
        },
        withdrawhistory: {
            title: 'รายการแอปพลิเคชัน',
            amount: 'จำนวน',
            name: 'ชื่อ',
            bankname: 'ชื่อธนาคาร',
            cardnumber: 'หมายเลขบัตร',
            classification: 'การจัดหมวดหมู่',
            contact: 'ข้อมูลติดต่อ',
            state: 'สถานะ',
            date: 'เวลาสมัคร',
            remark: 'ข้อสังเกต',
            processed: 'ดำเนินการแล้ว',
            pending: 'รอดำเนินการ',
            rejected: 'ถูกปฏิเสธ'
        }
    },
    ja: {
        setting: {
            title: 'システム設定',
            language: '言語設定',
            chipsetting: 'チップ設定',
            goodroadsetting: 'カスタム設定',
            gamerules: 'ゲーム規則',
            changepassword: 'パスワードを変更',
            customerservice: '顧客センター',
            signout: 'ログアウト',
            soundsetting: '音量設定',
            music: '音楽',
            voice: '声',
            bettingrecord: '賭けの記録',
            userinfo: 'ユーザー情報',
            avatar: 'プロフィール',
            nuibonusinfo: 'ジャックポットの説明'
        },
        common: {
            cancel: '取消し',
            confirm: '確定',
            hint: 'ヒント',
            limit: '上限',
            more: 'もっと',
            allroad: '全て',
            mainroad: '大通り',
            statistics: '統計',
            niuniu: 'ニウニウ',
            time: '時間',
            to: '至',
            reload: 'リフレッシュー',
            addall: '全て追加',
            countdown: 'カウントダウン',
            remove: '削除',
            add: '追加',
            customize: 'カスタム',
            topup: '充填',
            withdraw: '出金',
            record: '記録',
            next: '次項',
            prev: '前項',
            topage: 'ジャンプ',
            totalpage: 'トータル{0}ページ',
            commission: '手数料',
            loading: '読込み中',
            printing: '印刷',
            processing: '処理中'
        },
        chips: {
            enteramount: '金額を入力してください'
        },
        hints: {
            logout: 'システムからログアウトしてもよろしいですか?',
            multibettingselecthint:
                '右側からテーブルを追加するよう選択してください',
            multibettingalladdedhint: 'すべて追加されました',
            orientationhint:
                'より良い表示のために<br/>携帯電話を横にしてください',
            commissionconvertionsuccessful: '転換成功'
        },
        roadmap: {
            banker: 'B',
            player: 'P'
        },
        login: {
            trial: 'トライアル',
            login: 'ローグイン',
            usernameplaceholder: 'ID名を入力してください',
            passwordplaceholder: 'パスワードを入力してください',
            forgetpassword: 'パスワードをお忘れですか?',
            quickregistration: 'クイック登録',
            verifyimagetitle:
                '下のスライダーをドラッグしてパズルを完成させてください',
            verifyimagehint: 'スライダーをドラッグしてパズルを埋めてください'
        },
        loby: {
            anchorroom: 'メーインホール',
            classicroom: 'クラシックルーム',
            multibet: '多重掛け',
            goodroadrecomended: '方法の提案',
            changeroom: 'ルームの変更',
            allgames: '全てのゲーム',
            converttobalance: '残高に転換'
        },
        desklist: {
            niuniutable: 'ニウニウ {tablenumber}',
            classic: 'クラシック',
            shuffling: 'カード混ぜ中',
            opening: 'カード分配中',
            rounds: 'ラウンド数',
            entergame: 'ゲームに参加'
        },
        ingame: {
            limitpair: 'バンカー・プレーヤー対抗',
            limittie: '引分',
            limitpot: 'ポット',
            gameid: 'ゲームID',
            switchgameplay: 'ゲーム切替え',
            nocommission: '手数料無し',
            withcommission: '手数料有り',
            selecttable: 'テーブルの選択',
            prediction: '方法を尋ねる',
            tableswitcher: 'テーブルの切替え',
            bet: '賭けてください',
            totalbetamount: '総バッティング金額:',
            totaldepositamount: '総保証金:',
            currentamount: '現在金額:',
            jackpotmessage: '{0} テーブル,ジャックポット {1} 金額おめでとう {2}'
        },
        record: {
            gameidplaceholder: 'ゲーム番号を入力してください',
            search: '検索',
            bettingtime: '掛け時間',
            gameid: 'ゲームID',
            gametype: 'ゲーム形態',
            tablenumber: 'テーブル番号',
            result: '結果',
            howtoplay: '遊び方',
            betamount: '掛け金額',
            payout: '支払い',
            validbet: '有効掛け',
            norecordfound: '記録が見つかりません'
        },
        userinfo: {
            nickname: 'ニックネーム',
            todaybetamount: '今日の賭け金の合計',
            todayvalidamount: '今日の有効な賭け',
            todaywinlos: '今日の勝敗',
            weekbetamount: '今週の賭けの合計額',
            weekvalidamount: '今週の有効な賭け',
            weekcommissionamount: '今週の手数料合計額',
            lastweekbetamount: '先週の賭けの合計額',
            lastweekvalidamount: '先週の有効な賭け',
            lastweekcommissionamount: '先週の手数料合計額',
            changeavatar: 'プロフィール修正'
        },
        editpassword: {
            oldpassword: '既存パスワード',
            oldpasswordplaceholder: '既存パスワードを入力してください',
            newpassword: '新パスワード',
            newpasswordplaceholder: '新パスワードを入力してください',
            newpassworderror: ' アルファベットと数字の組合せでお願いします！',
            confirmpassword: 'パスワード再確認',
            confirmpasswordplaceholder:
                '再確認用の新パスワードを入力してください',
            confirmpassworderror: '新パスワードが一致しません'
        },
        rules: {
            niuniu: rules.ja.niuniu
        },
        niuniu: {
            niuniu: 'ニウニウ',
            niu: 'ニウ',
            no: 'ニウ無し',
            bomCow: 'ニウ爆弾',
            fivebeff: 'ニウ花五本',
            five: '第五小学校',
            flush: 'Flush',
            royalflush: 'Royal Flush',
            straightflush: 'Straight Flush',
            fourkind: 'Four of a kind',
            fullhouse: 'Full House',
            straight: 'Straight',
            threekind: 'Three of a kind'
        },
        prompt: {
            betsucessfull: '掛け成功',
            networkerror: 'ネットが不良です。再度お試しください',
            bettingnotallowed: '現在、賭けは出来ません',
            insufficientbalance: '残高不足',
            idle: '長時間賭けがなかったの、再度ログインの必要があります。有り難うございます！',
            jackpotmaxbet: '賭け限度は 50,000までです'
        },
        nuibonushint: nuibonusrules.ja,
        topup: {
            bankname: '銀行名',
            banknamehint: '銀行名を入力してください',
            banknameerror: '無効な銀行名',
            depositorname: '名前',
            depositornamehint: 'カードの名前を入力してください',
            depositornameerror: '無効な銀行名',
            accountnumber: 'カード番号',
            accountnumberhint: 'カード番号を入力してください',
            accountnumbererror: '無効なカード番号',
            amount: '金額',
            amounthint: '入金額を入力してください',
            amounterror: '無効な金額',
            phonenumber: '電話番号',
            phonenumberhint: '電話番号を入力してください',
            phonenumbererror: '無効な電話番号',
            confirminfohint:
                'カード番号確認 <span class="number-color">{0}</span> を確認してシステムに入力しますか?　再度変更する必要がある場合は、顧客サービスにご連絡ください。',
            rechargesent: '充填情報が正常に送信されました'
        },
        withdraw: {
            hint: '1回の入出金限度額は900万ウォン(韓国ウォンだけ)',
            bankname: '銀行名',
            banknamehint: '銀行名を入力してください',
            banknameerror: '無効な銀行名です',
            currentbalance: '現在の残高',
            membername: '名前',
            membernamehint: '会員の名前を入力してください',
            membernameerror: '無効な会員名',
            accountnumber: '口座番号番号',
            accountnumberhint: '口座番号を入力してください',
            accountnumbererror: '無効な口座番号',
            amount: '金額',
            amounthint: '金額を入力してください',
            amounterror: '無効な金額',
            phonenumber: '電話番号',
            phonenumberhint: '電話番号を入力してください',
            phonenumbererror: '無効な電話番号',
            withdrawsent: '引出し請求は成功しました'
        },
        withdrawhistory: {
            title: '申請リスト',
            amount: '金額',
            name: '名前',
            bankname: '銀行名',
            cardnumber: '口座番号',
            classification: '分類',
            contact: '連絡先',
            state: '状態',
            date: '申請時間',
            remark: '備考',
            processed: '処理済み',
            pending: '保留中',
            rejected: '拒否されました'
        }
    },
    vn: {
        setting: {
            title: 'cài đặt tiêu đề',
            language: 'cài đặt ngôn ngữ',
            chipsetting: 'cài đặt chip',
            goodroadsetting: 'Tốt   lựa chọn',
            gamerules: 'quy tắt trò chơi',
            changepassword: 'sửa đổi mật khẩu',
            customerservice: 'dịch vụ chăm sóc khách hàng',
            signout: 'đăng xuất',
            soundsetting: 'cài đặt âm lượng',
            music: 'âm nhạc',
            voice: 'giọng nói',
            bettingrecord: 'bảng ghi đặt cược',
            userinfo: 'thông tin cá nhân',
            avatar: 'lựa chọn ảnh đại diện',
            nuibonusinfo: 'thông tin về số tiền góp'
        },
        common: {
            cancel: 'hủy bỏ',
            confirm: 'xác nhận',
            hint: 'gợi ý, nhắc nhở',
            limit: 'hạn mức',
            more: 'nhiều hơn',
            allroad: 'all road',
            mainroad: 'main road',
            statistics: 'thống kê',
            niuniu: 'niuniu',
            time: 'thời gian',
            to: 'đến',
            reload: ' tải lại',
            addall: 'thêm toàn bộ',
            countdown: ' đếm ngược ',
            remove: 'loại bỏ',
            add: ' thêm',
            customize: ' tùy chỉnh',
            topup: 'Nạp vào',
            withdraw: 'rút ra',
            record: 'bảng ghi',
            next: 'trang sau',
            prev: 'trang trước',
            topage: 'chuyển đến trang',
            totalpage: 'tổng {0} trang',
            commission: 'hoa hồng',
            loading: 'đang tải',
            printing: 'sự in ấn',
            processing: 'Đang xử lý'
        },
        chips: {
            enteramount: 'số lượng nhập'
        },
        hints: {
            logout: 'xác nhận đăng xuất trò chơi?',
            multibettingselecthint:
                'vui lòng lựa chọn thêm bàn game ở bên phải',
            multibettingalladdedhint: 'đã được thêm toàn bộ',
            orientationhint:
                'để có trải nghiệm tốt nhất <br/>  , vui lòng xoay ngang điện thoại của bạn',
            commissionconvertionsuccessful: 'chuyển đổi thành công'
        },
        roadmap: {
            banker: 'B',
            player: 'P',
            dragon: 'D',
            tiger: 'T',
            tie: 'T',
            pair: 'Pr.'
        },
        login: {
            trial: 'chơi thử',
            login: 'đăng nhập',
            usernameplaceholder: 'Vui lòng nhập tên tài khoản',
            passwordplaceholder: 'Vui lòng nhập mật khẩu',
            forgetpassword: 'quên mật khẩu?',
            quickregistration: 'nhanh chóng đăng ký',
            verifyimagetitle: 'kéo trượt bên dưới  hoàn thành mảnh ghép',
            verifyimagehint: 'kéo trượt lấp đầy mảnh ghép'
        },
        loby: {
            anchorroom: 'phòng phát thanh',
            classicroom: 'phòng cổ điển',
            multibet: 'cược nhiều',
            goodroadrecomended: 'Tốt gợi ý',
            changeroom: 'đổi phòng',
            allgames: 'tất cả trò chơi',
            converttobalance: 'chuyển đổi thành số dư'
        },
        desklist: {
            niuniutable: 'số bàn {tablenumber} game',
            classic: 'cổ điển',
            shuffling: 'đang làm bài',
            opening: 'đang mở bài',
            rounds: 'số ván bài',
            entergame: 'vào game'
        },
        ingame: {
            limitpair: 'cặp nhà cái người chơi',
            limittie: 'hòa',
            limitpot: 'Lottery',
            gameid: 'số ván bài',
            switchgameplay: 'chuyển đổi cách chơi',
            nocommission: 'không hoa hồng',
            withcommission: 'có hoa hồng',
            selecttable: 'chọn bàn',
            prediction: 'tham vấn, dự đoán',
            tableswitcher: 'chuyển đổi bàn game',
            bet: 'mời đặt cược',
            totalbetamount: 'Tổng số tiền đặt cược:',
            totaldepositamount: 'Tổng tiền cọc:',
            currentamount: 'Số dư:',
            jackpotmessage:
                'Chúc mừng đài {0} đã nhận được {1} tiền thưởng bonus {2}'
        },
        record: {
            gameidplaceholder: 'vui lòng nhập số thứ tự trò chơi',
            search: 'tìm kiếm',
            bettingtime: 'thời gian đặt cược',
            gameid: 'số thứ tự trò chơi',
            gametype: 'loại trò chơi',
            tablenumber: 'số bàn',
            result: 'kết quả',
            howtoplay: 'cách chơi',
            betamount: 'số lượng đặt cược',
            payout: 'thanh toán',
            validbet: 'đặt cược có hiệu lực',
            norecordfound: 'không tìm thấy bảng ghi'
        },
        userinfo: {
            nickname: 'biệt hiệu',
            todaybetamount: 'tổng số tiền đặt cược hôm nay',
            todayvalidamount: 'số lượng đặt cược có hiệu lực hôm nay',
            todaywinlost: 'thắng / thua hôm nay',
            weekbetamount: 'tổng số tiền đặt cược tuần này',
            weekvalidamount: 'số lượng đặt cược có hiệu lực tuần này',
            weekcommissionamount: 'số rolling /  hoa hồng tuần này',
            lastweekbetamount: 'tổng số tiền đặt cược tuần trước',
            lastweekvalidamount: 'số lượng đặt cược có hiệu lực tuần trước',
            lastweekcommissionamount: 'số rolling /  hoa hồng tuần trước',
            changeavatar: 'đổi ảnh đại diện'
        },
        editpassword: {
            oldpassword: 'mật khẩu cũ',
            oldpasswordplaceholder: 'vui lòng nhập mật khẩu cũ',
            newpassword: 'mật khẩu mới',
            newpasswordplaceholder: 'vui lòng nhập mật khẩu mới',
            newpassworderror:
                'vui lòng thiết lập mật khẩu gồm tổ hợp chữ và số',
            confirmpassword: 'xác nhận mật khẩu',
            confirmpasswordplaceholder: ' vui lòng nhập mật khẩu lần nữa',
            confirmpassworderror: 'mật khẩu không khớp'
        },
        rules: {
            niuniu: rules['kor'].niuniu
        },
        niuniu: {
            niuniu: 'Niu Niu',
            niu: 'Niu',
            no: 'No Niu',
            bomCow: '4 Card',
            fivebeff: '5 Pictures',
            five: '5 Small',
            flush: 'Flush',
            royalflush: 'Royal Flush',
            straightflush: 'Straight Flush',
            fourkind: 'Four of a kind',
            fullhouse: 'Full House',
            straight: 'Straight',
            threekind: 'Three of a kind'
        },
        prompt: {
            betsucessfull: 'đặt cược thành công',
            networkerror: 'hiện tại internet không khả dụng, vui lòng thử lại',
            bettingnotallowed: 'hiện tại không thể đặt cược',
            insufficientbalance: 'số dư không đủ, đặt cược thất bại',
            idle: 'bạn đã không đặt cược trong thời gian dài, vui lòng đăng nhập hệ thống lại từ đầu',
            jackpotmaxbet: 'bạn nhiều nhất chỉ có thể đặt cược 2000'
        },
        nuibonushint: nuibonusrules['vn'],
        topup: {
            bankname: 'tên ngân hàng',
            banknamehint: 'vui lòng nhập tên ngân hàng',
            banknameerror: 'tên ngân hàng không hiệu lực',
            depositorname: 'họ tên đặt cọc',
            depositornamehint: 'vui lòng đối chiếu họ tên đã nhập',
            depositornameerror: 'họ tên đặt cọc không hiệu lực',
            accountnumber: 'số tài khoản',
            accountnumberhint: 'vui lòng nhập số tài khoản nạp tiền',
            accountnumbererror: 'số tài khoản không hiệu lực',
            amount: 'số lượng',
            amounthint: 'vui lòng nhập số lượng nạp vào',
            amounterror: 'số lượng không hiệu lực',
            phonenumber: 'phương thức liên hệ',
            phonenumberhint: 'vui lòng nhập số điện thoại',
            phonenumbererror: 'số điện thoại không hiệu lực',
            confirminfohint:
                'xác nhận số thẻ <span class="number-color">{0}</span> đăng nhập hệ thống ? nếu cần chỉnh sửa lần nữa, vui lòng liên hệ chăm sóc khách hàng',
            rechargesent: 'thông tin nạp tiền gửi thành công'
        },
        withdraw: {
            hint: 'số tiền 1 lần gửi/rút là 9,000.000 won (chỉ đối với tiền won Hàn)',
            bankname: 'tên ngân hàng',
            banknamehint: 'vui lòng nhập tên ngân hàng',
            banknameerror: 'tên ngân hàng không hiệu lực',
            currentbalance: 'số dư hiện tại',
            membername: 'họ tên hội viên',
            membernamehint: 'vui lòng nhập tên hội viên',
            membernameerror: 'tên hội viên không hiệu lực',
            accountnumber: 'số tài khoản',
            accountnumberhint: 'vui lòng nhập số tài khoản ngân hàng',
            accountnumbererror: 'số tài khoản không hiệu lực',
            amount: 'số lượng',
            amounthint: 'vui lòng nhập số lượng',
            amounterror: 'số lượng không hiệu lực',
            phonenumber: 'phương thức liên hệ',
            phonenumberhint: 'vui lòng nhập số điện thoại',
            phonenumbererror: 'số điện thoại không hiệu lực',
            withdrawsent: 'yêu cầu rút tiền gửi thành công'
        },
        withdrawhistory: {
            title: 'đơn xin',
            amount: 'số lượng',
            name: 'họ tên',
            bankname: 'tên ngân hàng',
            cardnumber: 'số thẻ',
            classification: 'phân loại',
            contact: 'phương thức liên hệ',
            state: 'trạng thái',
            date: 'thời gian',
            remark: 'nhận xét',
            processed: 'đã xử lý',
            pending: 'chờ xử lý',
            rejected: 'bị từ chối'
        }
    }
}

const i18n = createI18n({
    legacy: false,
    locale: getCookieValue(CookiesToken.LANG) || 'kor',
    fallbackLocale: 'zh-CN',
    globalInjection: true,
    warnHtmlInMessage: false,
    warnHtmlMessage: false,
    messages
})

export default i18n
