
import { PropType, defineComponent } from 'vue'

export default defineComponent({
    props: {
        isOn: {
            type: Boolean as PropType<boolean>,
            default: () => false
        },
        onColor: {
            type: String as PropType<string>,
            default: () => '#262626'
        },
        offColor: {
            type: String as PropType<string>,
            default: () => '#9b9b9b'
        }
    }
})
