import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bonus-msg-box-root" }
const _hoisted_2 = { class: "bonus-msg-box" }
const _hoisted_3 = { class: "bonus-msg-box-wrap" }
const _hoisted_4 = { class: "rule-msgbox-title-wrap" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('setting.nuibonusinfo')), 1),
          _createElementVNode("div", {
            class: "close-btn",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"]))
          })
        ]),
        _createElementVNode("div", {
          class: "msgbox-body-wrap",
          innerHTML: _ctx.t('nuibonushint')
        }, null, 8, _hoisted_6)
      ])
    ])
  ]))
}