import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, vModelDynamic as _vModelDynamic, resolveComponent as _resolveComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69b26694"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "security-msg-box-root" }
const _hoisted_2 = { class: "security-msg-box-wrap" }
const _hoisted_3 = { class: "security-msgbox-title-wrap" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "security-body-wrap" }
const _hoisted_6 = { class: "input-field" }
const _hoisted_7 = { class: "label" }
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = {
  key: 0,
  class: "f-error"
}
const _hoisted_10 = { class: "input-field" }
const _hoisted_11 = { class: "label" }
const _hoisted_12 = ["type", "placeholder"]
const _hoisted_13 = {
  key: 0,
  class: "f-error"
}
const _hoisted_14 = { class: "input-field" }
const _hoisted_15 = { class: "label" }
const _hoisted_16 = ["type", "placeholder"]
const _hoisted_17 = {
  key: 0,
  class: "f-error"
}
const _hoisted_18 = { class: "error" }
const _hoisted_19 = { class: "message-body-button-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_eye = _resolveComponent("svg-eye")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('setting.changepassword')), 1),
        _createElementVNode("div", {
          class: "close-btn",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"]))
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t('editpassword.oldpassword')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.oldPassword) = $event)),
            placeholder: _ctx.t('editpassword.oldpasswordplaceholder')
          }, null, 8, _hoisted_8), [
            [_vModelText, _ctx.oldPassword]
          ]),
          _createVNode(_Transition, { name: "fadein-out" }, {
            default: _withCtx(() => [
              (_ctx.oldPasswordError)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.t('editpassword.oldpasswordplaceholder')), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.t('editpassword.newpassword')), 1),
          _withDirectives(_createElementVNode("input", {
            type: _ctx.showPassword ? 'text' : 'password',
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newPassword) = $event)),
            placeholder: _ctx.t('editpassword.newpasswordplaceholder')
          }, null, 8, _hoisted_12), [
            [_vModelDynamic, _ctx.newPassword]
          ]),
          _createVNode(_component_svg_eye, {
            class: "svg",
            "is-on": _ctx.showPassword,
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword))
          }, null, 8, ["is-on"]),
          _createVNode(_Transition, { name: "fadein-out" }, {
            default: _withCtx(() => [
              (_ctx.newPasswordError)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.t('editpassword.newpassworderror')), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.t('editpassword.confirmpassword')), 1),
          _withDirectives(_createElementVNode("input", {
            type: _ctx.showConfirmPassword ? 'text' : 'password',
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.confirmPassword) = $event)),
            placeholder: 
                            _ctx.t('editpassword.confirmpasswordplaceholder')
                        
          }, null, 8, _hoisted_16), [
            [_vModelDynamic, _ctx.confirmPassword]
          ]),
          _createVNode(_component_svg_eye, {
            class: "svg",
            "is-on": _ctx.showConfirmPassword,
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showConfirmPassword = !_ctx.showConfirmPassword))
          }, null, 8, ["is-on"]),
          _createVNode(_Transition, { name: "fadein-out" }, {
            default: _withCtx(() => [
              (_ctx.confirmPasswordError)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.t('editpassword.confirmpassworderror')), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.errorMessage), 1)
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", {
          class: "btn-cancel btn",
          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
        }, _toDisplayString(_ctx.t('common.cancel')), 1),
        _createElementVNode("div", {
          class: "btn-confirm btn",
          onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args)))
        }, _toDisplayString(_ctx.t('common.confirm')), 1)
      ])
    ])
  ]))
}