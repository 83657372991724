
import { useStore } from '@/store'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import { computed, defineComponent, Emitter, inject, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    setup() {
        const store = useStore()
        const { t } = useI18n()
        const emitter = inject('emitter') as Emitter

        const activeTab = ref<number>(2)

        const langCode = ref<string>('')

        const isMobile = computed((): boolean => store.getters['isMobile'])

        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'rule'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const confirm = () => {
            emitter.emit(EventBuss.AGREE_TO_AGREEMENT, true)
            close()
        }

        const cancel = () => {
            emitter.emit(EventBuss.AGREE_TO_AGREEMENT, false)
            close()
        }

        const activeLang = computed((): string => store.getters['lang'])

        onMounted(() => {
            langCode.value = activeLang.value
        })

        return {
            isMobile,
            activeTab,
            activeLang,
            langCode,
            t,
            confirm,
            close,
            cancel
        }
    }
})
