const rules = {
    en: {
        niuniu:
            '<p>Game description:<br><br>' +
            '1. Niu-niu, a famous traditional card game from Guangdong Province, China<br><br>' +
            '2. Game description - The first card issued determines the order of card distribution to players 1, 2, and 3 and the banker (dealer).<br>' +
            "Users can bet on players 1, 2, and 3. If the player's card is higher than the banker's card, <br>" +
            'The player wins.<br><br>2-1. The game uses 52 cards. After distributing 5 cards to the player and banker, three of the 5 cards received are<br>' +
            'The sum of the numbers must be 10, and the winner is determined by a combination of the remaining two cards. <br>' +
            'If the sum of 3 out of 5 cards does not reach 10, the game is decided with the highest card among the 5 cards.<br>' +
            '#Cards J, Q, K are counted as 10 and A is counted as 1 <br><br>3. Order of cards - Niu Niu / Niu 9 / Niu 8 / Niu 7 / Niu 6 / Niu 5 / Niu 4 / Niu3 / Niu2 / Niu1 / <br>' +
            ' (No Made) K / Q / J / 10 / 9 / 8 / 7 / 6 / 5 / 4 / 3 / 2 / A / <br> ' +
            "In order, if the number is the same as the dealer's, the ranking is determined in that order: spades, hearts, clovers, and diamonds. <br><br>" +
            '4. Odds - 1:1 BAT - 1.95 times the odds<br> 1:2 BAT - 2.95 times the odds (3.95 odds if Niuniu wins or loses) This<br>' +
            'It will be deducted.#If both the player and the banker win with Niu 7 or more, 2:1 BAT is established. 1:1 BAT when winning with Niu 6 or less<br>' +
            'Dividend value is applied.#SUPER BAT - Niu 1~ Niu Niu (1x ~ 10x #Refer to the picture table) #Betting amount in case of loss<br>' +
            "An amount of 1 to 10 times (dealer's Niu value) will be deducted. #1:2BAT, in the case of SUPER BAT, personal funds are required in case of defeat<br>" +
            'Betting is not possible if it is lower than the amount.<br><br>' +
            '5. Application rules - Minimum/maximum bets vary depending on the table. Users are limited to betting amounts depending on the table or for each user<br>' +
            'Each individual must follow the limits set. If you would like to change the betting limit, please contact customer service.<br>' +
            'If the results are applied differently from reality, dividends will be paid again based on the video recording.<br><br>' +
            'Probability:</p>'
    },
    'zH-cn': {
        niuniu:
            '<p>游戏描述：<br><br>' +
            '1.牛牛，中国广东省著名的传统纸牌游戏<br><br>' +
            '2. 游戏说明 - 发出的第一张牌决定了向玩家 1、2、3 和庄家（庄家）发牌的顺序。' +
            '用户可以对玩家 1、2 和 3 进行投注。 如果闲家的牌比庄家的牌大,' +
            '闲家获胜。<br><br>2-1。游戏使用 52 张牌，向闲家和庄家分发 5 张牌后，收到的 5 张牌中的 3 张是' +
            '数字之和必须为10，获胜者由剩余两张牌的组合决定。 <br>' +
            '如果 5 张牌中的 3 张之和未达到 10，则以 5 张牌中最大的牌决定游戏。' +
            '#牌 J、Q、K 计为 10，A 计为 1 <br><br>3 牌顺序 - 牛牛 / 牛 9 / 牛 8 / 牛 7 / 牛 6 / 牛 5 / 牛 4。 / Niu3 / Niu2 / Niu1 /' +
            '（未制作）K / Q / J / 10 / 9 / 8 / 7 / 6 / 5 / 4 / 3 / 2 / A / <br> ' +
            '按顺序，如果数字与庄家相同，则按黑桃、红心、三叶草、方块的顺序确定排名。 <br><br>' +
            '4. 赔率 - 1:1 BAT - 1.95 倍赔率<br>1:2 BAT - 2.95 倍赔率（牛牛输赢赔率为 3.95 倍）<br>' +
            '会扣除。#如果闲家和庄家都以牛7或以上获胜，则2:1 BAT成立。 以 Niu 6 或以下获胜时 1:1 BAT<br>' +
            '已应用分红值。#SUPER BAT - Niu 1~ Niu Niu (1x ~ 10x #参考图表) #输时投注金额<br>' +
            '将扣除1至10倍（庄家牛值）的金额。 #1:2BAT，如果是SUPER BAT，则需要个人资金，以防失败<br>' +
            '低于金额则无法投注。<br><br>' +
            '5. 应用规则 - 最小/最大赌注因牌桌而异。 用户的下注金额受到限制，具体取决于牌桌或每个用户<br>' +
            '每个人都必须遵守设定的限制。 如果您想更改投注限额，请联系客服。<br>' +
            '如果结果应用与实际不同，将根据视频记录再次支付红利。<br><br>' +
            '概率：</p>'
    },
    'zH-HK': {
        niuniu:
            '<p>遊戲描述：<br><br>' +
            '1.牛牛，中國廣東省著名的傳統紙牌遊戲<br><br>' +
            '2. 遊戲說明 - 發出的第一張牌決定了向玩家 1、2、3 和莊家（莊家）發牌的順序。<br>' +
            '用戶可以對玩家 1、2 和 3 進行投注。 如果閒家的牌比莊家的牌大，<br>' +
            '閒家獲勝。<br>' +
            '數字總和必須為10，由剩餘兩張牌的組合決定勝負。 <br>' +
            '如果 5 張牌中的 3 張總和未達到 10，則以 5 張牌中最大的牌決定遊戲。<br>' +
            '#牌 J、Q、K 計為 10，A 計 1 <br><br>3 牌順序 - 牛 / 牛 9 / 牛 8 / 牛 7 / 牛 6 / 牛 5 / 牛 4。 / Niu1 / <br>' +
            '（未製作）K / Q / J / 10 / 9 / 8 / 7 / 6 / 5 / 4 / 3 / 2 / A / <br> ' +
            '依序，如果數字與莊家相同，則依黑桃、紅心、三葉草、方塊的順序決定排名。 <br><br>' +
            '4. 賠率 - 1:1 BAT - 1.95 倍賠率<br>1:2 BAT - 2.95 倍賠率（牛牛輸贏賠率為 3.95 倍）<br>' +
            '會扣除。 以 Niu 6 或以下獲勝時 1:1 BAT<br>' +
            '已套用分紅值。<br>' +
            '將扣除1至10倍（莊家牛價值）的金額。 #1:2BAT，如果是SUPER BAT，則需要個人資金，以防失敗<br>' +
            '低於金額則無法投注。<br>' +
            '5. 應用規則 - 最小/最大賭注因牌桌而異。 用戶的下注金額受到限制，取決於牌桌或每個用戶<br>' +
            '每個人都必須遵守設定的限制。 如果您想更改投注限額，請聯絡客服。<br>' +
            '如果結果應用與實際不同，將根據視頻記錄再次支付紅利。<br>' +
            '機率：</p>'
    },
    kor: {
        niuniu:
            '<p>게임설명:<br><br>' +
            '1.중국 광동성에서 유명한 전통카드게임 니우니우(niu-niu)<br><br>' +
            '2.게임설명 - 첫번째 발급된 카드가 플레이어 1번, 2번, 3번과 뱅커(딜러) 의 카드분배 순서를 결정합니다.<br>' +
            '유저는 각 플레이어 1번,2번,3번 에게 베팅할수 있습니다. 플레이어의 카드가 뱅커의 카드보다 높으면 <br>' +
            '플레이어는 승리합니다.<br><br>2-1.게임은 52장의 카드를 사용합니다 5장의 카드를 플레이어와 뱅커에게 배분을 한뒤 받은 5장의 카드중 세장의 카드는<br>' +
            '숫자합이 10단위 여야 하고, 나머지 카드 2장의 조합으로 승부를 정합니다. <br>' +
            '카드 5장중 3장으로 숫자합이 10단위가 되지 못했다면 5장의 카드중 가장 높은 1장의 카드로 승부를 봅니다<br>' +
            '#카드 J , Q , K 는 10으로 A는 1로 계산합니다   <br><br>3.카드의 서열 - 니우니우 / 니우9 / 니우8 / 니우7 / 니우6 / 니우5 / 니우4 / 니우3 / 니우2 / 니우1 / <br>' +
            ' (노메이드) K / Q / J / 10 / 9 / 8 / 7 / 6 / 5 / 4 / 3 / 2 / A / <br> ' +
            '순이며 딜러와 숫자가 같은경우 스페이드, 하트, 클로버, 다이아 순으로 순위를 정합니다.  <br><br>' +
            '4.배당율 - 1:1 BAT - 1.95배 의 배당<br>   1:2 BAT - 2.95배 의 배당 ( 니우니우 승패 시 3.95 배당 ) #패배시 베팅금의 2배 (니우니우 3배) 의 금액이<br>' +
            '차감됩니다.#플레이어와 뱅커 모두 니우7 이상으로 승리시 2:1BAT 이 성립이 됩니다. 니우6 이하로 승리시 1:1BAT<br>' +
            '의 배당금값이 적용됩니다.#SUPER BAT - 니우 1~ 니우니우 ( 1배 ~ 10배 #그림표 참조 ) #패배시 베팅금의<br>' +
            '1~10배 (딜러의 니우 값) 의 금액이 차감됩니다. #1:2BAT , SUPER BAT의 경우 개인의 소지금이 패배시 필요한<br>' +
            '금액보다 낮으면 베팅이 불가합니다.<br><br>' +
            '5.적용룰 - 최소/최대 베팅금은 테이블에 따라 다릅니다. 유저는 테이블에 따른 베팅금제한 혹은 각각의 유저<br>' +
            '개인마다 정해진 제한을 따라야 합니다. 베팅금제한을 변경시키시려면 고객센터로 문의하여 주시기 바랍니다.<br>' +
            '만약 결과가 실제와 틀리게 적용된다면 비디오 녹화분에 의해 다시 배당금이 지급됩니다.<br><br>' +
            '확률:</p>'
    },
    th: {
        niuniu:
            '<p>คำอธิบายเกม:<br><br>' +
            '1. Niu-niu เกมไพ่แบบดั้งเดิมอันโด่งดังจากมณฑลกวางตุ้ง ประเทศจีน<br><br>' +
            '2. คำอธิบายเกม - ไพ่ใบแรกที่ออกจะกำหนดลำดับการแจกไพ่ให้กับผู้เล่น 1, 2 และ 3 และเจ้ามือ (เจ้ามือ)<br>' +
            'ผู้ใช้สามารถเดิมพันผู้เล่น 1, 2 และ 3 ได้ หากไพ่ของผู้เล่นสูงกว่าไพ่ของเจ้ามือ <br>' +
            'ผู้เล่นชนะ<br><br>2-1 เกมใช้ไพ่ 52 ใบ หลังจากแจกไพ่ 5 ใบให้กับผู้เล่นและเจ้ามือแล้ว ไพ่ 3 ใบจาก 5 ใบที่ได้รับคือ<br>' +
            'ผลรวมของตัวเลขจะต้องเป็น 10 และผู้ชนะจะถูกตัดสินโดยการรวมกันของไพ่สองใบที่เหลือ <br>' +
            'หากผลรวมของไพ่ 3 ใน 5 ใบไม่ถึง 10 เกมจะถูกตัดสินด้วยไพ่ที่สูงที่สุดในบรรดาไพ่ 5 ใบ<br>' +
            '#ไพ่ J, Q, K นับเป็น 10 และ A นับเป็น 1 <br><br>3 ลำดับไพ่ - Niu Niu / Niu 9 / Niu 8 / Niu 7 / Niu 6 / Niu 5 / Niu 4 / Niu3 / Niu2 / Niu1 / <br>' +
            ' (ไม่ทำ) K / Q / J / 10 / 9 / 8 / 7 / 6 / 5 / 4 / 3 / 2 / A / <br> ' +
            'ตามลำดับ หากหมายเลขเหมือนกับของเจ้ามือ อันดับจะถูกกำหนดตามลำดับนั้น: โพดำ หัวใจ โคลเวอร์ และเพชร <br><br>' +
            '4. อัตราต่อรอง - 1:1 BAT - 1.95 เท่าของอัตราต่อรอง<br> 1:2 BAT - 2.95 เท่าของอัตราต่อรอง (3.95 เท่าหาก Niuniu ชนะหรือแพ้) นี่<br>' +
            'มันจะถูกหักออก #หากทั้งผู้เล่นและเจ้ามือชนะด้วย Niu 7 หรือมากกว่า BAT 2:1 จะถูกสร้างขึ้น 1:1 BAT เมื่อชนะด้วย Niu 6 หรือน้อยกว่า<br>' +
            'ใช้มูลค่าเงินปันผล#SUPER BAT - Niu 1~ Niu Niu (1x ~ 10x #อ้างอิงจากตารางรูปภาพ) #จำนวนเงินเดิมพันในกรณีขาดทุน<br>' +
            'จำนวน 1 ถึง 10 เท่า (มูลค่านิวของเจ้ามือ) จะถูกหักออก #1:2BAT ในกรณีของ SUPER BAT ต้องใช้เงินทุนส่วนบุคคลในกรณีที่พ่ายแพ้<br>' +
            'ไม่สามารถวางเดิมพันได้หากต่ำกว่าจำนวนเงิน<br><br>' +
            '5. กฎการสมัคร - การเดิมพันขั้นต่ำ/สูงสุดจะแตกต่างกันไปขึ้นอยู่กับตาราง ผู้ใช้จะถูกจำกัดจำนวนเงินเดิมพันขึ้นอยู่กับตารางหรือสำหรับผู้ใช้แต่ละคน<br>' +
            'แต่ละคนจะต้องปฏิบัติตามขีดจำกัดที่กำหนดไว้ หากคุณต้องการเปลี่ยนวงเงินเดิมพัน โปรดติดต่อฝ่ายบริการลูกค้า<br>' +
            'หากผลลัพธ์ถูกนำไปใช้แตกต่างจากความเป็นจริง จะมีการจ่ายเงินปันผลอีกครั้งตามการบันทึกวิดีโอ<br><br>' +
            'ความน่าจะเป็น:</p>'
    },
    ja: {
        niuniu:
            '<p>遊び方: <br><br>' +
            '<ol>' +
            '<li>ニウニウは、中国広東省の有名な伝統カードゲームです。</li>' +
            '<li>ゲームの説明 - 最初に配られたカードによって、プレイヤー 1、2、3 とバンカー (ディーラー)のカードの配る順番が決まります。ユーザーは、プレーヤーの 1、2、3 に賭けます。プレイヤーのカードがバンカーより高い場合、プレイヤーが勝ちます。<br><br>' +
            '<ol>' +
            '<li>2-1．ゲームは52枚のカードを使用します。プレーヤーとバンカーに5枚のカードを配った後、受け取った5枚のカードのうち3枚は数字の合計が10単位（10､20､30）になればそのカードを除き、残りの2枚のカードの組み合わせによって勝負が決まります。もし、5枚のカードのうち3枚の合計が10単位に達しなかった場合、ゲームは5枚のカードのうち最も高いカードによって決まります。#カードのJ、Q、Kは10、Aは1として使います。<br><br></li>' +
            '</ol>' +
            '<li>カードの序列 - ニウニウ / ニウ 9 / ニウ 8 / ニウ 7 / ニウ 6 / ニウ 5 / ニウ 4 / ニウ3 / ニウ2 / ニウ1 / （ノーメード）　K / Q / J / 10 / 9 / 8 / 7 / 6 / 5 / 4 / 3 / 2 / A / の順序で、もしディーラーとプレーヤーが同じ数字の場合、スペード、ハート、クロバー、ダイヤの順番で順位が決まります。</li>' +
            '<li>配当率 - 1:1 BAT - 1.95 倍の配当1:2 BAT - 2.95 倍の配当（ニウニウ勝敗時 3.95 倍）<br>' +
            '＃負けた場合、配当金の2倍（ニウニウ3倍）の金額が差引かれます。<br><br>' +
            '＃もし、プレーヤー・バンカー共に７以上での勝利の場合、2:1 BAT成立します。 6以下での勝利の場合は 1:1 BATの配当金額が適用されます。<br><br>' +
            '＃SUPER BAT - ニウ 1~ ニウニウ (1x ~ 10x #図表参照）<br><br>' +
            '＃負けた場合、賭け金の1倍から10倍（バンカーのニウ値）の金額が差引かれます。<br><br>' +
            '＃1:2BAT、SUPER BATの場合、プレーヤーの残高が負けた時に必要とする金額より低い場合、賭けは出来ません。<br><br> </li>' +
            '<li>応用規則 - 最小/最大賭けはテーブルによって異なります。 ユーザーの賭け金額には制限があります。テーブルまたはユーザーごとに異なります。全ての人が設定された制限を遵守しなければなりません。賭け限度額を変更したい場合は、カスタマーサービスまでご連絡ください。もし結果が実際と異なると申出する場合は、録画に基づいて再度ボーナスが支払われます。<br><br></li>' +
            '</ol>' +
            '確率：<br><br>' +
            'ジャックポットの説明<br>' +
            'ジャックポット -  5枚メイドを成立させた場合は、当否に関わらず、当選したジャックポット種類のペイアウト率に応じてジャックポット金額が支払われます。<br><br>' +
            '倍率<br><br>' +
            '-ロイヤルフラッシュ (50%)<br>' +
            '-同色 (20%)<br>' +
            '-4種類 (12%)<br>' +
            '-マンタンカイ (6%)<br>' +
            '-同じ花(3%)<br>' +
            '-真っ直ぐ (1%)<br>' +
            '-三つのこと (0.5%)<br><br>' +
            'すべてのジャックポット賭けは 2,000 円に固定されており、スーパーに賭けたプレイヤーのみが参加できます。<br>' +
            'ジャックポットは1,000,000ウォンから始まり、全ユーザーのジャックポットベット額に基づいて積み立てられます。<br>' +
            '複数のユーザーが同じエリアに同時に賭けて勝った場合、賭けたプレイヤーは N分の１に分割されます。<br>' +
            'プレイヤーの賭け金は無期限に蓄積され、ボーナスが支払われると、残りの金額も蓄積されます。</p>'
    }
}

export default rules
