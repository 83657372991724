import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-142b741a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message-msg-box-root" }
const _hoisted_2 = { class: "message-msg-box-wrap" }
const _hoisted_3 = { class: "message-msgbox-title-wrap" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "message-body-wrap" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "message-body-button-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('common.hint')), 1),
        _createElementVNode("div", {
          class: "close-btn",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"]))
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", { innerHTML: _ctx.message }, null, 8, _hoisted_6)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", {
          class: "btn-cancel btn",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
        }, _toDisplayString(_ctx.t('common.cancel')), 1),
        _createElementVNode("div", {
          class: "btn-confirm btn",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.callBackFunc && _ctx.callBackFunc(...args)))
        }, _toDisplayString(_ctx.t('common.confirm')), 1)
      ])
    ])
  ]))
}