
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import { computed, defineComponent, Emitter, inject, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    setup() {
        const emitter = inject('emitter') as Emitter
        const activeTab = ref(0)
        const store = useStore()
        const { t } = useI18n()

        const baccaratWays = ref<
            {
                flag: number[]
                name: string
                src: string
            }[]
        >([])
        const dragonTigerWays = ref<{ name: string; src: string }[]>([])
        const selectedGoodWay = ref<number[]>([])

        onMounted(() => {
            selectedGoodWay.value = [...goodWay.value]
            // initialize variables
            baccaratWays.value = [
                {
                    flag: [1],
                    name: 'g1', // long banker
                    src: require('@/assets/imgs/pc/bg_pop_setting-goodroad_01@2x.png')
                },
                {
                    flag: [2],
                    name: 'g3',
                    src: require('@/assets/imgs/pc/bg_pop_setting-goodroad_05@2x.png')
                },
                {
                    flag: [3],
                    name: 'g5',
                    src: require('@/assets/imgs/pc/bg_pop_setting-goodroad_07@2x.png')
                },
                {
                    flag: [4],
                    name: 'g7',
                    src: require('@/assets/imgs/pc/bg_pop_setting-goodroad_10@2x.png')
                },
                {
                    flag: [5, 6],
                    name: 'g9',
                    src: require('@/assets/imgs/pc/bg_pop_setting-goodroad_02@2x.png')
                },
                {
                    flag: [7, 8],
                    name: 'g11',
                    src: require('@/assets/imgs/pc/bg_pop_setting-goodroad_06@2x.png')
                },
                {
                    flag: [9],
                    name: 'g13',
                    src: require('@/assets/imgs/pc/bg_pop_setting-goodroad_08@2x.png')
                },
                {
                    flag: [10],
                    name: 'g15',
                    src: require('@/assets/imgs/pc/bg_pop_setting-goodroad_09@2x.png')
                },
                {
                    flag: [11],
                    name: 'g17',
                    src: require('@/assets/imgs/pc/bg_pop_setting-goodroad_03@2x.png')
                },
                {
                    flag: [12],
                    name: 'g19',
                    src: require('@/assets/imgs/pc/bg_pop_setting-goodroad_04@2x.png')
                }
            ]
        })

        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'good-way-setting'
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const isSelected = (_i: {
            flag: number[]
            name: string
            src: string
        }) => {
            if (!_i) return false

            for (let a = 0; a < _i.flag.length; a++) {
                const c = _i.flag[a]

                if (selectedGoodWay.value.indexOf(c) !== -1) {
                    return true
                }
            }

            return false
        }

        const onSelectTab = (tab: number) => {
            if (activeTab.value != tab) activeTab.value = tab
        }

        const confirm = () => {
            store.commit(AppMutation.SET_GOOD_WAY, selectedGoodWay.value)
            close()
        }

        const onSelectGoodway = (_a: {
            flag: number[]
            name: string
            src: string
        }) => {
            _a.flag.forEach((r, c) => {
                const _index = selectedGoodWay.value.indexOf(r)
                if (_index !== -1) {
                    selectedGoodWay.value.splice(_index, 1)
                } else {
                    if (c === 0) selectedGoodWay.value.push(r)
                }
            })
        }

        const goodWay = computed((): number[] => store.getters['goodWay'])

        return {
            t,
            close,
            confirm,
            onSelectTab,
            onSelectGoodway,
            isSelected,
            activeTab,
            baccaratWays,
            dragonTigerWays
        }
    }
})
