import {
    getBaccaratValue,
    findIndicesForSumMod10,
    getDTFaceValue,
    getColorValue
} from '@/utils/cards'
import { CallbackFunction2 } from 'vue'

export class NuiValue {
    value = ''
    win = false
    points = -1
    flashIndexs: number[] = []
}

export class Nui {
    banker: NuiValue
    player1: NuiValue
    player2: NuiValue
    player3: NuiValue
    /**
     *
     */
    constructor(
        banker: NuiValue,
        player1: NuiValue,
        player2: NuiValue,
        player3: NuiValue
    ) {
        this.banker = banker
        this.player1 = player1
        this.player2 = player2
        this.player3 = player3
    }

    public static Parse(
        nuiStr: string,
        t: CallbackFunction2 | undefined = undefined,
        cards: string[][] = []
    ): Nui {
        // const { t } = useI18n()
        const result = new Nui(
            new NuiValue(),
            new NuiValue(),
            new NuiValue(),
            new NuiValue()
        )

        const strArray = nuiStr.split(',')

        // console.log(strArray)

        let bankerWins = true

        const getStrValue = (__n: number): string => {
            if (__n <= 10) {
                return `${__n}`
            }

            if (__n === 11) return 'J'
            else if (__n == 12) return 'Q'

            return 'K'
        }

        const getNoNiuValue = (
            strV: string,
            __cards: string[][],
            __position: number
        ): { label: string; indices: number[] } => {
            const _nonNiueValueResult: { label: string; indices: number[] } = {
                label: '',
                indices: []
            }
            if (__cards.length === 0) {
                _nonNiueValueResult.label = getStrValue(parseInt(strV))
            } else {
                const __cardsValues: number[] = []

                __cards[__position].forEach((__x) => {
                    __cardsValues.push(getDTFaceValue(__x))
                })

                let __highValue = 0
                let __indices = -1
                let __

                __cardsValues.forEach((__a, __i) => {
                    if (__a > __highValue) {
                        __highValue = __a
                        __indices = __i
                    } else if (__a === __highValue) {
                        const __currentColorValue = getColorValue(
                            __cards[__position][__indices]
                        )
                        const __nowColorValue = getColorValue(
                            __cards[__position][__i]
                        )

                        if (__nowColorValue < __currentColorValue) {
                            __indices = __i
                        }
                    }
                })

                _nonNiueValueResult.label = getStrValue(__highValue)

                const _tempIndices = [0, 1, 2, 3, 4]
                _tempIndices.splice(__indices, 1)
                _nonNiueValueResult.indices = _tempIndices
            }

            return _nonNiueValueResult
        }

        strArray.forEach((str, ctr) => {
            const arStr = str.split('-')
            const _val = parseInt(arStr[0])
            const isWin = _val < 100

            const cardsValue: number[] = []

            // const _label = _val % 10 === 0 ? '牛牛' : `牛${Math.abs(_val)}`

            // 无牛 = point 0
            // 牛 {#Points} = points from 1 to 9
            // 牛牛 = 10
            // 五花牛 = 11
            // 炸弹牛 = 12
            // 五小 =13

            let _label = ''
            const _points = _val < 100 ? _val : _val - 100

            let flashIndexs: number[] = []

            // console.log(_points)
            if (!isNaN(_points)) {
                if (t) {
                    if (_points === 0) {
                        const _tp1 = getNoNiuValue(arStr[1], cards, ctr)
                        _label = _tp1.label
                        flashIndexs = _tp1.indices
                    } else if (_points === 10) _label = t('niuniu.niuniu')
                    else if (_points === 11) _label = t('niuniu.fivebeff')
                    else if (_points == 12) _label = t('niuniu.bomCow')
                    else if (_points === 13) _label = t('niuniu.five')
                    else _label = t('niuniu.niu') + _points
                } else {
                    if (_points === 0) {
                        const _tp2 = getNoNiuValue(arStr[1], cards, ctr)
                        _label = _tp2.label
                        flashIndexs = _tp2.indices
                    } else if (_points === 10) _label = '牛牛'
                    else if (_points === 11) _label = '五花牛'
                    else if (_points === 12) _label = '炸弹牛'
                    else if (_points === 13) _label = '五小'
                    else _label = '牛' + _points
                }
            } else {
                _label = ''
            }

            if (ctr === 0) {
                result.banker.value = _label
                result.banker.win = isWin
                result.banker.points = _points
                if (_points > 0 && cards.length > 0) {
                    cards[0].forEach((x) => {
                        cardsValue.push(getBaccaratValue(x))
                    })
                    result.banker.flashIndexs =
                        findIndicesForSumMod10(cardsValue)
                } else {
                    result.banker.flashIndexs = flashIndexs
                }
            } else if (ctr === 1) {
                result.player1.value = _label
                result.player1.win = isWin
                result.player1.points = _points
                if (_points > 0 && cards.length > 0) {
                    cards[1].forEach((x) => {
                        cardsValue.push(getBaccaratValue(x))
                    })
                    result.player1.flashIndexs =
                        findIndicesForSumMod10(cardsValue)
                } else {
                    result.player1.flashIndexs = flashIndexs
                }
            } else if (ctr === 2) {
                result.player2.value = _label
                result.player2.win = isWin
                result.player2.points = _points
                if (_points > 0 && cards.length > 0) {
                    cards[2].forEach((x) => {
                        cardsValue.push(getBaccaratValue(x))
                    })
                    result.player2.flashIndexs =
                        findIndicesForSumMod10(cardsValue)
                } else {
                    result.player2.flashIndexs = flashIndexs
                }
            } else if (ctr === 3) {
                result.player3.value = _label
                result.player3.win = isWin
                result.player3.points = _points
                if (_points > 0 && cards.length > 0) {
                    cards[3].forEach((x) => {
                        cardsValue.push(getBaccaratValue(x))
                    })
                    result.player3.flashIndexs =
                        findIndicesForSumMod10(cardsValue)
                } else {
                    result.player3.flashIndexs = flashIndexs
                }
            }
        })

        if (result.player1.win || result.player2.win || result.player3.win)
            bankerWins = false

        result.banker.win = bankerWins

        return result
    }

    public static ParseList(
        arrStr: string[],
        colNum = 15,
        t: CallbackFunction2 | undefined = undefined
    ): Nui[] {
        const nuis = new Array<Nui>()

        let finalStrtArray: string[] = []

        if (arrStr.length <= colNum) {
            finalStrtArray = arrStr.slice(0)
        } else {
            finalStrtArray = arrStr.slice(arrStr.length - colNum)
        }

        finalStrtArray.forEach((str) => {
            nuis.push(Nui.Parse(str, t))
        })

        return nuis
    }
}

export class NuiData {
    result: Array<Nui> = new Array<Nui>()
    bankerCount = 0
    player1Count = 0
    player2Count = 0
    player3Count = 0
    player1RawData: Array<number> = []
    player2RawData: Array<number> = []
    player3RawData: Array<number> = []
    roundCount = 0

    constructor(
        arrStr: string[],
        colNum = 15,
        t: CallbackFunction2 | undefined = undefined
    ) {
        const fullResult = new Array<Nui>()
        this.roundCount = arrStr.length

        arrStr.forEach((str) => {
            const nui = Nui.Parse(str, t)
            if (nui.banker.win) {
                this.bankerCount++
            }
            if (nui.player1.win) {
                this.player1Count++
                this.player1RawData.push(10)
            } else {
                this.player1RawData.push(20)
            }
            if (nui.player2.win) {
                this.player2Count++
                this.player2RawData.push(10)
            } else {
                this.player2RawData.push(20)
            }
            if (nui.player3.win) {
                this.player3Count++
                this.player3RawData.push(10)
            } else {
                this.player3RawData.push(20)
            }
            fullResult.push(nui)
        })

        if (fullResult.length <= colNum) {
            this.result = fullResult.slice(0)
        } else {
            this.result = fullResult.slice(fullResult.length - colNum)
        }
    }
}
