import request from '@/utils/request'
import axios, { AxiosPromise } from 'axios'

export const AxiosSource = { Source: axios.CancelToken.source() }
/**
 * Api Call
 * @param params
 * @returns
 */
export function fetch(params: any): AxiosPromise {
    return request({
        method: 'POST',
        params,
        cancelToken: AxiosSource.Source.token
    })
}

export function getImage(): AxiosPromise {
    return request({
        url: `common/public/verifycode/${new Date().getTime()}`,
        responseType: 'blob'
    })
}

export function login(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'user/login/submit',
        params
    })
}

export function demoLogin(): AxiosPromise {
    return request({
        url: 'user/login/guestSubmit',
        method: 'POST'
    })
}

export function getHallData(params: any): AxiosPromise {
    return request({
        url: 'common/hall/getHallData',
        method: 'GET',
        params
    })
}

export function getTableDetail(params: any): AxiosPromise {
    return request({
        method: 'GET',
        url: 'common/gameTable/getDetail',
        params
    })
}

export function getBetRecordList(params: any): AxiosPromise {
    return request({
        method: 'GET',
        url: 'common/hall/getBetRecordList',
        params
    })
}

export function logOut(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'user/login/logout',
        params
    })
}

export function joinTable(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'common/gameTable/changeTable',
        params
    })
}

export function callGunner(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'common/gameTable/callGunner',
        params
    })
}

export function operatStatus(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'common/gameTable/operatStatus',
        params
    })
}

export function flyBet(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'common/gameTable/flyBet',
        params
    })
}

export function giveFeeVerify(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'common/gameTable/giveFeeVerify',
        params
    })
}

export function giveFee(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'common/gameTable/giveFee',
        params
    })
}

export function saveBet(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'common/lottery/saveBet',
        params
    })
}

export function editPassword(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'user/login/editPasswordSubmit',
        params
    })
}

export function pauseRequest(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'common/gameTable/pause',
        params
    })
}

export function openPokeRequest(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'common/gameTable/openPoke',
        params
    })
}

export function getMultiTableDetail(params: any): AxiosPromise {
    return request({
        method: 'GET',
        url: 'common/gameTable/getMutliDetail',
        params
    })
}

export function setCommissionTable(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'system/gameTable/setCommissionStatus',
        params
    })
}

export function fecthUserInfo(params: any): AxiosPromise {
    return request({
        method: 'GET',
        url: 'common/hall/userDetailInfo',
        params
    })
}

export function switchLimit(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'common/hall/switchLimit',
        params
    })
}

export function rechargeInfo(params: any): AxiosPromise {
    return request({
        method: 'GET',
        url: 'system/memberList/rechargeWithdrawInit',
        params
    })
}

export function rechargeOrWithdraw(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'system/memberList/rechargeWithdrawSubmit',
        params
    })
}

export function withdrawHistory(params: any): AxiosPromise {
    return request({
        method: 'GET',
        url: 'system/memberList/rechargeWithdrawList',
        params
    })
}

export function getOneCommission(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'system/memberList/getOneCommission',
        params
    })
}

export function getPrintDetailsFromApi(params: any): AxiosPromise {
    return request({
        method: 'GET',
        params,
        url: 'common/lottery/getPrintDetail'
    })
}

export function printSuccess(params: any): AxiosPromise {
    return request({
        method: 'GET',
        params,
        url: 'common/lottery/printSuccess'
    })
}
