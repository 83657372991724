
import { useStore } from '@/store'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    onMounted,
    ref,
    ResponseData
} from 'vue'
import { AppMutation } from '@/store/types'
import { giveFee } from '@/api'

export default defineComponent({
    setup() {
        const store = useStore()

        const emitter = inject('emitter') as Emitter

        const isMobile = computed((): boolean => store.getters['isMobile'])

        const tipAmount = ref<string>('')

        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'tipbox'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const confirm = () => {
            const queryString = window.location.search
            const urlParams = new URLSearchParams(queryString)
            const tableId = urlParams.get('desk')
            if (tableId) {
                const _params = {
                    memberId: memberid.value,
                    tableId: tableId,
                    feeAmount: tipAmount.value,
                    status: 6,
                    token: token.value
                }

                store.commit(AppMutation.SHOW_LOADING)
                giveFee(_params)
                    .then((response) => {
                        store.commit(AppMutation.HIDE_LOADING)
                        if (response.data.status === 200) {
                            emitter.emit(
                                EventBuss.TOAST_MESSAGE,
                                response.data.data.content
                            )

                            store.commit(
                                AppMutation.SET_COIN,
                                response.data.data.amount
                            )

                            close()
                        } else if (response.data.status === 100) {
                            emitter.emit(
                                EventBuss.TOAST_MESSAGE,
                                response.data.description
                            )
                        }
                    })
                    .catch(() => {
                        store.commit(AppMutation.HIDE_LOADING)
                        close()
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            '当前网络不佳，请重新尝试'
                        )
                    })
            } else {
                close()
            }
        }

        const cancel = () => {
            close()
        }

        const onCapchaKeydown = (event: KeyboardEvent) => {
            if (event.key.toLowerCase() !== 'enter') {
                if (event.key.toLowerCase() === 'e') {
                    event.preventDefault()
                    return
                }

                if (
                    document.getSelection()?.toString() ===
                    tipAmount.value.toString()
                ) {
                    return
                }
            }

            if (event.key.toLowerCase() === 'enter') {
                confirm()
            }
        }

        onMounted(() => {
            //
        })

        const memberid = computed(() => store.getters['memberid'])
        const token = computed(() => store.getters['token'])

        return {
            isMobile,
            tipAmount,
            confirm,
            close,
            cancel,
            onCapchaKeydown
        }
    }
})
