
import { useStore } from '@/store'
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    setup() {
        const store = useStore()
        const { t } = useI18n()

        const isMobile = computed(() => store.getters['isMobile'])

        const deviceOrientation = computed(
            () => store.getters['deviceOrientation']
        )

        return {
            isMobile,
            deviceOrientation,
            t
        }
    }
})
