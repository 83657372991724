<template>
    <svg
        id="Component_40_1"
        data-name="Component 40 – 1"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="25"
        viewBox="0 0 20 25"
    >
        <path
            id="Rectangle_185"
            data-name="Rectangle 185"
            d="M0,0H19a0,0,0,0,1,0,0V19a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V0A0,0,0,0,1,0,0Z"
            transform="translate(0 3)"
            fill="#3a3a3a"
        />
        <g
            id="Rectangle_186"
            data-name="Rectangle 186"
            fill="#3a3a3a"
            stroke="#fff"
            stroke-width="1"
        >
            <path
                d="M3,0H17a3,3,0,0,1,3,3V6a0,0,0,0,1,0,0H3A3,3,0,0,1,0,3V3A3,3,0,0,1,3,0Z"
                stroke="none"
            />
            <path
                d="M3,.5H17A2.5,2.5,0,0,1,19.5,3V5.5a0,0,0,0,1,0,0H3A2.5,2.5,0,0,1,.5,3V3A2.5,2.5,0,0,1,3,.5Z"
                fill="none"
            />
        </g>
        <path
            id="Path_206"
            data-name="Path 206"
            d="M1.8-11.466a.962.962,0,0,1,.3-.72,1.007,1.007,0,0,1,.729-.288.97.97,0,0,1,.711.3.97.97,0,0,1,.3.711.991.991,0,0,1-.3.729.97.97,0,0,1-.711.3.991.991,0,0,1-.729-.3A.991.991,0,0,1,1.8-11.466ZM.576-7.218v-.72a6.272,6.272,0,0,0,2.358-.756h.648v7.056q0,.594.288.747a1.7,1.7,0,0,0,.792.153h.414V0H.576V-.738h.36q.756,0,.963-.243a1.824,1.824,0,0,0,.207-1.071V-7.218Z"
            transform="translate(7 21)"
            fill="#fff"
        />
    </svg>
</template>