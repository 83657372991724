
import { useStore } from '@/store'
import { Currencies } from '@/types/global'
import { computed, defineComponent, onMounted } from 'vue'

export default defineComponent({
    setup() {
        const store = useStore()

        const currency = computed((): Currencies => store.getters['currency'])

        // onMounted(() => {
        //     console.log(currency.value)
        // })

        return {
            currency
        }
    }
})
