
import { useCountdown } from '@/composables/useCountDown'
import { Nui, NuiData } from '@/types/Nui'
import { PropType, TableData, computed, defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    props: {
        activeDesk: {
            required: true,
            type: Number as PropType<number>
        },
        mData: {
            required: true,
            type: Object as PropType<TableData>
        }
    },
    setup(props) {
        const { t } = useI18n()

        const nuiData = ref(new NuiData([], 10, t))
        const onLoadAvatarError = (e: any) => {
            e.currentTarget.src = require(`@/assets/imgs/pc/dealer1@2x.png`)
        }

        const { currentCount, startCountDown, stopCountDown } = useCountdown()

        const tableName = computed(() => {
            const { tableNo } = props.mData

            return t('desklist.niuniutable', { tablenumber: tableNo })
        })

        const timerColor = computed(() => {
            if (currentCount.value === -1) {
                return 'wait'
            } else if (currentCount.value <= 10) {
                return 'red'
            } else {
                return ''
            }
        })

        watch(
            () => props.mData,
            (d) => {
                if (d) {
                    // deal with the timer
                    if (d.expand.daoJiShiBetTime > 0) {
                        startCountDown(d.expand.daoJiShiBetTime)
                    } else {
                        stopCountDown()
                    }

                    if (d.expand.resultList.length > 0) {
                        nuiData.value = new NuiData(
                            d.expand.resultList as Array<string>,
                            8,
                            t
                        )
                    } else {
                        nuiData.value = new NuiData([], 8, t)
                    }
                }
            },
            { immediate: true, deep: true }
        )

        return {
            tableName,
            currentCount,
            timerColor,
            nuiData,
            onLoadAvatarError,
            t
        }
    }
})
