import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b3a16d1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pc-nui-item-root" }
const _hoisted_2 = { class: "item-left" }
const _hoisted_3 = { class: "item-left-title" }
const _hoisted_4 = { class: "item-left-avatar" }
const _hoisted_5 = ["src", "onerror"]
const _hoisted_6 = { class: "item-content" }
const _hoisted_7 = { class: "item-content-all-road-display" }
const _hoisted_8 = { class: "item-content-all-road-display-top" }
const _hoisted_9 = { class: "nui-sumary" }
const _hoisted_10 = { class: "nui-sumary-item round" }
const _hoisted_11 = { class: "nui-sumary-item player" }
const _hoisted_12 = { class: "nui-sumary-item player" }
const _hoisted_13 = { class: "nui-sumary-item player" }
const _hoisted_14 = { class: "nui-player-record" }
const _hoisted_15 = { class: "grid" }
const _hoisted_16 = { class: "nui-map" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.tableName), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("img", {
          src: _ctx.mData.expand.avatar,
          onerror: _ctx.onLoadAvatarError
        }, null, 8, _hoisted_5)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.t('desklist.rounds')) + ": " + _toDisplayString(_ctx.mData.expand.juNo), 1),
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.t('roadmap.player')) + "1: " + _toDisplayString(_ctx.nuiData.player1Count), 1),
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.t('roadmap.player')) + "2: " + _toDisplayString(_ctx.nuiData.player2Count), 1),
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.t('roadmap.player')) + "3: " + _toDisplayString(_ctx.nuiData.player3Count), 1)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["timer", _ctx.timerColor])
          }, _toDisplayString(_ctx.currentCount >= 0
                                ? _ctx.currentCount
                                : _ctx.t('desklist.opening')), 3)
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("table", _hoisted_15, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, _toDisplayString(_ctx.t('roadmap.banker')), 1),
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(11, (i) => {
                  return _createElementVNode("th", {
                    key: i + 'th'
                  })
                }), 64))
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
                return _createElementVNode("tr", {
                  key: i + 'tr'
                }, [
                  _createElementVNode("td", null, _toDisplayString(_ctx.t('roadmap.player')) + _toDisplayString(i), 1),
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(11, (x) => {
                    return _createElementVNode("td", {
                      key: `${i}${x}td`
                    })
                  }), 64))
                ])
              }), 64))
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nuiData.result, (i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "nui-col",
                key: i + 'nui-sum' + _ctx.mData.id
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["nui-row", { win: i.banker.win }])
                }, [
                  _createElementVNode("div", null, _toDisplayString(i.banker.value), 1)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["nui-row", { win: i.player1.win }])
                }, [
                  _createElementVNode("div", null, _toDisplayString(i.player1.value), 1)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["nui-row", { win: i.player2.win }])
                }, [
                  _createElementVNode("div", null, _toDisplayString(i.player2.value), 1)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["nui-row", { win: i.player3.win }])
                }, [
                  _createElementVNode("div", null, _toDisplayString(i.player3.value), 1)
                ], 2)
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}