import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3506b6c5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "chip-value"
}
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "d-custom-chip-root",
    onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clicked && _ctx.clicked(...args)), ["stop"]))
  }, [
    (!_ctx.isEditMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.chipLabel), 1))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          id: `el${_ctx.chipItem && _ctx.chipItem.id}}`,
          class: "editable",
          contenteditable: "true",
          type: "number",
          onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLostFocus && _ctx.onLostFocus(...args))),
          onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleInputEvt && _ctx.handleInputEvt(...args)))
        }, null, 40, _hoisted_2))
  ])), [
    [_directive_click_outside, _ctx.clickOutside]
  ])
}