
import { computed, defineComponent, PropType, ref } from 'vue'
export default defineComponent({
    emits: ['update:modelValue'],
    name: 'drop-down',
    components: {
        // IArrowL
    },
    props: {
        labelName: {
            type: String as PropType<string>
        },
        listData: {
            required: true,
            type: Array as PropType<Array<any>>
        },
        modelValue: {
            type: Number as PropType<number>
        },
        placeHolder: {
            type: String as PropType<string>,
            required: false,
            defaullt: () => ''
        },
        enabled: {
            type: Boolean as PropType<boolean>,
            required: false,
            default: () => true
        }
    },
    setup(props, { emit }) {
        const showList = ref<boolean>(false)

        const toggleList = () => {
            if (props.enabled) showList.value = true
            else showList.value = false
        }

        const handleClickOutside = () => {
            showList.value = false
        }

        const handleListItemClick = (value: number) => {
            emit('update:modelValue', value)
            showList.value = false
        }

        const getLabel = (_index: number) => {
            const item = props.listData[_index]
            if (props.labelName) {
                return item[`${props.labelName}`]
            }

            return item
        }

        const isSelected = (item: number) => {
            return label.value === getLabel(item)
        }

        const label = computed(() => {
            if (!props.listData || props.listData.length === 0) {
                {
                    if (props.placeHolder !== '') return props.placeHolder
                    return '没有数据'
                }
            } else {
                const item = props.listData[props.modelValue!]
                if (item) {
                    if (props.labelName) {
                        return item[`${props.labelName}`]
                    }

                    return item
                }

                if (props.placeHolder !== '') return props.placeHolder
                return '没有数据'
            }
        })

        return {
            showList,
            label,
            getLabel,
            isSelected,
            toggleList,
            // methods
            handleClickOutside,
            handleListItemClick
        }
    }
})
